import * as types from '../constants/ActionTypes';
import { NOISE_ALERTS_COUNT } from '../constants/Misc';
import { sensorHistory } from './HistoryReducers';
const initialState = {
  activity: {
    isVisible: false,
    isFetching: false,
    year: null,
    month: null,
    data: [],
  },
  technician: {
    isVisible: false,
    isFetching: false,
    year: null,
    month: null,
    type: null,
    data: [],
  },
  bits: {},
  details: {},
  g5BitMsgs: [],
  g5BitSampleMsgs: [],
  g5Details: [],
  g5NoiseMsgs: [],
  g5SelectedBitMsg: {},
  g5SelectedNoiseMsg: {},
  g5SelectedBitSampleMsg: {},
  g5NoiseGraphData: [],
  history: {},
  indexMap: {},
  isFetching: false,
  isFetchingActualTransient: false,
  logs: {},
  sensors: [],
  sensorsIcons: [],
}
export const sensors = (state = initialState, action = '') => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        sensors: {
          ...state.sensors,
          g5SelectedBitMsg: {},
          g5SelectedBitSampleMsg: {},
        }

      }
    case types.SELECT_PROJECT:
      return Object.assign({}, state, {
        isFetching: false,
        sensors: [],
        sensorsIcons: [],
        noiseAlertCount: NOISE_ALERTS_COUNT,
        g5SelectedBitSampleMsg: {},
      });

    case types.REQUEST_SENSORS:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          isFetching: true,
          sensors: [],
          sensorsIcons: []
        })
      });
    case types.RECEIVE_SENSORS:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          isFetching: false,
          sensors: action.sensors,
          sensorsIcons: action.sensorsIcons || [],
          indexMap: action.indexMap
        })
      });
    case types.REQUEST_SENSORS_WITH_ACTUAL_PRESSURE_TRANSIENT:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          isFetchingActualTransient: true,
        })
      })
    case types.RECEIVE_SENSORS_WITH_ACTUAL_PRESSURE_TRANSIENT:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          sensors: action.sensors,
          sensorsIcons: action.sensorsIcons || [],
          isFetchingActualTransient: false,
        })
      })
    case types.SORT_SENSORS_LOCALY:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          sensors: action.sensors,
          indexMap: action.indexMap
        })
      });
    case types.RECEIVE_SENSORS_NON_ASSOCIATED:
      const sensorsList = Object.assign([], state.sensors.sensors);
      const indexMap = state.sensors.indexMap;
      let nonAssociatedList = [];
      action.data.forEach(id => {
        nonAssociatedList.push(id.ID);
      });
      sensorsList.forEach(sensor => {
        if (nonAssociatedList.indexOf(sensor.id) > 0) {
          sensor.warningEnum = 3;
        }
      });
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          sensors: sensorsList,
        })
      });
    case types.REQUEST_SENSOR_HISTORY:
    case types.RECEIVE_SENSOR_HISTORY:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          history: sensorHistory(state.sensors.history || {}, action),
        })
      });
    case types.REQUEST_SENSOR_DETAILS:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          details: Object.assign({}, state.sensors.details, {
            fileName: '',
            sampleTime: '',
            deviceID: '',
            noiseAlertDetails: null,
          })
        })
      });
    case types.RECEIVE_SENSOR_DETAILS:
      const fileName = action.details && action.details.FileName;
      const originalFileName = action.details && action.details.originalFileName;
      const sampleTime = action.details && action.details.SampleTime;
      const deviceID = action.details && action.details.DeviceID;

      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          details: Object.assign({}, state.sensors.details, {
            fileName,
            originalFileName,
            sampleTime,
            deviceID,
          })
        })
      });
    case types.REQUEST_SENSOR_NC:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          details: Object.assign({}, state.sensors.details, {
            nc1Array: [],
            nc3Array: [],
            nc3aveArray: [],
            barsData: [],
            nc3Bars: [],
            ncCount: 0,
          })
        })
      });
    case types.RECEIVE_SENSOR_NC:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          details: Object.assign({}, state.sensors.details, {
            nc1Array: action.nc1Array,
            nc2Array: action.nc2Array,
            nc3Array: action.nc3Array,
            nc3aveArray: action.nc3aveArray,
            barsData: action.barsData,
            nc3Bars: action.nc3Bars,
            maxNcValues: action.maxNcValues,
            maxBarsValues: action.maxBarsValues,
            ncCount: action.ncCount,
            maxNc1_2: action.maxNc1_2,
            maxNc3: action.maxNc3,
            deviceTypeEnum: action.deviceTypeValue,
            details: action.details,
            totalRows: action.totalRows
          })
        })
      });
    case types.REQUEST_SENSOR_NOISE_ALERT:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          details: Object.assign({}, state.sensors.details, {
            noiseAlertDetails: {},
          })
        })
      });
    case types.RECEIVE_SENSOR_NOISE_ALERT:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          details: Object.assign({}, state.sensors.details, {
            noiseAlertDetails: action.details[0],
          })
        })
      });
    case types.SET_NOISE_SAMPLES_COUNT:
      const count = parseInt(action.count);
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          noiseAlertCount: count,
        })
      });
    case types.REQUEST_SENSOR_LOGS:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          logs: []
        })
      });
    case types.RECEIVE_SENSOR_LOGS:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          logs: action.logs.data,
          totalLogsCount: action.logs.totalLength
        })
      });
    case types.REQUEST_SENSOR_BITS:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          bits: []
        })
      });
    case types.RECEIVE_SENSOR_BITS:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          bits: action.bits
          //totalBitsCount: action.bits.totalRows
        })
      });
    case types.REQUEST_SENSOR_IMAGES:
      return Object.assign({}, state, {
        images: []
      });
    case types.RECEIVE_SENSOR_IMAGES:
      return Object.assign({}, state, {
        images: action.images
      });

    case types.REQUEST_SENSOR_G5_STATUS:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          g5Details: []
        }
      };
    case types.RECEIVE_SENSOR_G5_STATUS:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          g5Details: action.payload
        }
      };

    case types.REQUEST_SENSOR_G5_BIT:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          g5BitMsgs: []
        }
      };
    case types.RECEIVE_SENSOR_G5_BIT:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          g5BitMsgs: action.payload
        }
      };

    case types.REQUEST_SENSOR_G5_BIT_SAMPLE_MESSAGES:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          g5BitSampleMsgs: []
        }
      };
    case types.RECEIVE_SENSOR_G5_BIT_SAMPLE_MESSAGES:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          g5BitSampleMsgs: action.payload
        }
      };

    case types.SELECT_SENSOR_G5_MESSAGE:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          g5SelectedBitMsg: action.payload
        }
      };

    case types.RECEIVE_SENSOR_SAMPLE_WAVS_PATHS:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          details: {
            ...state.sensors.details,
            audio: action.payload
          }
        }
      }
    // return Object.assign({}, state, {
    //   sensors: Object.assign({}, state.sensors, {
    //     details: Object.assign({}, state.sensors.details, {
    //       audio: action.payload
    //     })
    //   })
    // });

    case types.REQUEST_SENSOR_G5_NOISE:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          g5NoiseMsgs: []
        }
      }
    case types.RECEIVE_SENSOR_G5_NOISE:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          g5NoiseMsgs: action.payload
        }
      }

    case types.SELECT_SENSOR_G5_NOISE_MESSAGE:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          g5SelectedNoiseMsg: action.payload
        }
      };

    case types.SELECT_SENSOR_G5_BIT_SAMPLE_MESSAGE:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          g5SelectedBitSampleMsg: action.payload
        }
      };

    case types.RECEIVE_SENSOR_G5_BIT_SAMPLE_AUDIO:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          g5SelectedBitSampleMsg: {
            ...state.sensors.g5SelectedBitSampleMsg,
            audio: action.payload
          }
        }
      };

    case types.SET_G5_NOISE_GRAPH:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          g5NoiseGraphData: action.payload
        }
      }

    case types.REQUEST_SENSORS_ACTIVITY_DATA:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          activity: {
            ...state.sensors.activity,
            isFetching: true,
            year: action.payload.year,
            month: action.payload.month,
          }
        }
      };
    case types.REQUEST_SENSORS_TECHNICIAN_DATA:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          technician: {
            ...state.sensors.technician,
            isFetching: true,
            year: action.payload.year,
            month: action.payload.month,
            type: action.payload.type,
          }
        }
      };

    case types.RECEIVE_SENSORS_ACTIVITY_DATA:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          activity: {
            ...state.sensors.activity,
            isFetching: false,
            data: action.payload
          }
        }
      };
    case types.RECEIVE_SENSORS_TECHNICIAN_DATA:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          technician: {
            ...state.sensors.technician,
            isFetching: false,
            data: action.payload
          }
        }
      };

    case types.SENSORS_ACTIVITY_DATA_SET_VISIBILITY: {
      return {
        ...state,
        sensors: {
          ...state.sensors,
          activity: {
            ...state.sensors.activity,
            isVisible: action.payload
          }
        }
      };
    }
    case types.SENSORS_TECHNICIAN_DATA_SET_VISIBILITY: {
      return {
        ...state,
        sensors: {
          ...state.sensors,
          technician: {
            ...state.sensors.technician,
            isVisible: action.payload
          }
        }
      };
    }
    // case types.SENSORS_ADMIN_REPORTS_DATA_SET_VISIBILITY: {
    //   return {
    //     ...state,
    //     sensors: {
    //       ...state.sensors,
    //       technician: {
    //         ...state.sensors.technician,
    //         isVisible: action.payload
    //       }
    //     }
    //   };
    // }
    default:
      return state;
  }
}
