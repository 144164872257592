import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setZone } from '../../actions/GlobalsActions';
import { ZONE } from '../../constants/SettingsKeys';

import './UserSettings.scss';

function TimezoneSelect(props, context) {
  const { lang, local, dispatch, user, projectTimezone } = props;
  const { t } = context;

  // Build the options array using the translation function.
  // The "project" option is included only if projectTimezone is non-null.
  const options = [
    {
      ID: 'local',
      Name: t('local')
    },
    {
      ID: 'gmt',
      Name: t('gmt')
    },
  ];

  if (projectTimezone) {
    options.push({
      ID: 'project',
      Name: t('project')
    });
  }

  const onChange = (zone) => {
    dispatch(setZone(zone));
    localStorage.setItem(ZONE, zone);
  };

  return (
    <div className="lang-select">
      <select
        onChange={e => onChange(e.target.value)}
        value={local}
        lang={lang || 'en'}
      >
        {options.map(option => (
          <option key={option.ID} value={option.ID}>
            {option.Name}
          </option>
        ))}
      </select>
    </div>
  );
}

TimezoneSelect.contextTypes = {
  t: PropTypes.func.isRequired
};

// We still connect to Redux for dispatch and any other props needed.
export default connect(state => ({
  timezone: state.local.timezone,
}))(TimezoneSelect);
