import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column } from 'fixed-data-table-2';

import TableMixin from '../../components/TableMixin/TableMixin';
import TextCell from '../../components/TextCell/TextCell';
import SortableHeaderCell from '../../components/SortableHeaderCell/SortableHeaderCell';
import CAddNoiseAlert from '../../containers/CAlertsSettings/CAddNoiseAlert';
import CSelectCell from '../../containers/CSelectCell/CSelectCell';
import CTableActions from '../../containers/CTableActions/CTableActions';
import { DateType } from '../../containers/UIhelper/UIDateFormater';
import CNoiseAlertsSettings from '../../containers/CAlertsSettings/CNoiseAlertsSettings';
import useProjectTimezone from '../../hooks/useProjectTimeZone';

require('../AlertsTable/AlertsTable.scss');

function AlertTableNoise(props, context) {
  const {
    items,
    indexMap,
    selectedFeature,
    selectedProjectId,
    localSortProp,
    pHeight,
    pWidth,
    timeZone,
    rowClickEvent,
    addingManualNoiseAlertMode,
    t,
  } = props;
  const projectTimezone = useProjectTimezone();

  const [data, setData] = React.useState([]);
  const [goToRow, setGoToRow] = React.useState(null);

  React.useEffect(() => {
    setData(items);
  }, [items]);
  React.useEffect(() => {
    const rowIndex = props.indexMap[selectedFeature.ID];
    setGoToRow(rowIndex);
  }, [selectedFeature]);

  function getRowClassName(rowIndex) {
    const rowItem = items[rowIndex];
    if (rowItem?.ID === selectedFeature.ID) {
      return 'active-row';
    } else {
      return '';
    }
  }

  const totalItemsStyle = {
    float: 'right',
    padding: '2px 8px',
  };

  return (
    <div>
      {props.user.isAQS && (
        <CAddNoiseAlert
          isOpen={addingManualNoiseAlertMode}
          showAddAlertForm={props.addNewManualNoiseAlert}
          notify={props.notify}
        />
      )}

      <Table
        maxHeight={pHeight - 93}
        width={pWidth}
        rowsCount={items.length}
        rowHeight={30}
        headerHeight={30}
        onRowClick={rowClickEvent}
        rowClassNameGetter={getRowClassName}
        scrollToRow={goToRow}
      >
        {/* PRIORITY */}
        <Column
          columnKey='NoisePriority'
          width={30}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field='NoisePriority' />}
        />

        <Column
          columnKey='ID'
          width={70}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field='ID' />}
        />

        {props.user.isAQS && (
          <Column
            columnKey='CreateDate'
            width={95}
            flexGrow={1}
            header={
              <SortableHeaderCell
                sortTable={localSortProp}
                tableName='AlertsTableNoise'
              />
            }
            cell={
              <TextCell
                data={items}
                field='CreateDate'
                dateType={DateType.DATE}
                additional={timeZone}
                projectTimezone={projectTimezone}
              />
            }
          />
        )}

        <Column
          columnKey='InsertDate'
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={
            <TextCell
              data={items}
              field='InsertDate'
              dateType={DateType.DATE}
              additional={timeZone}
              projectTimezone={projectTimezone}
            />
          }
        />
        <Column
          columnKey='AlertState'
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={
            <CSelectCell
              data={items}
              field='AlertState'
              options={props.optionList.options.AlertStateNoise}
              dummyPropForUpdate={localSortProp}
            />
          }
        />
        <Column
          columnKey='SensorID'
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field='SensorID' />}
        />
        <Column
          columnKey='Comment'
          headerClassName='comment'
          cellClassName='comment'
          width={120}
          flexGrow={3}
          header={t('comment')}
          cell={<TextCell data={items} field='Comment' />}
        />
      </Table>

      {/* {false && items && items.length > 0 && ( */}
      {items && items.length > 0 && (
        <CTableActions type='noiseAlerts' notify={props.notify} /> //check if it doesn't cause bugs. used to be type alerts
      )}
      {items && items.length > 0 && (
        <span style={totalItemsStyle}>
          {t('selected_selectedindex_total_total', {
            selectedIndex:
              indexMap[selectedFeature && selectedFeature.ID] + 1 || 0,
            total: items.length,
          })}
        </span>
      )}

      {props.user.isAQS && (
        <button
          className='btn-success create-item'
          onClick={() => {
            props.addNewManualNoiseAlert(true);
          }}
          type='button'
        >
          <i title={context.t('add')} />
        </button>
      )}
      {selectedFeature.ID && (
        <button
          className='btn-success noise-to-alert'
          onClick={() => {
            props.createAlertFromNoiseAlert(selectedFeature.ID);
          }}
          type='button'
        >
          <i title={context.t('create_alert')} />
        </button>
      )}
    </div>
  );
}

AlertTableNoise.contextTypes = {
  t: PropTypes.func.isRequired,
};

const alertTableNoise = TableMixin(AlertTableNoise, CNoiseAlertsSettings);
export default alertTableNoise;
