import React from 'react';
import PropTypes from 'prop-types';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';
import SensorPressureGraph  from '../../../components/SensorsTabs/Pressure/SensorPressureGraph';

const TransientAlertInfo = (props) => {

  const { selectedSample, selectSampleFunction, uiInfo, timeZone, projectTimezone } = props;

  const closePanel = () => {
      selectSampleFunction({});
  }

  const plotData = [
    {
      y: selectedSample.transientValues,
      mode: 'lines+points',
      type: 'scatter'
    }
  ];

  const plotBoxData = [
    {
      y: selectedSample.transientValues,
      type: 'box'
    }
  ];

  const uiObj = {
    ...uiInfo,
    pageWidth: uiInfo.pageWidth / 2
  }

  return (
    <div className='sample-info'>
      <i className="icon-close" onClick={closePanel} />
      {/* <h2 style={{ marginLeft: '20px' }}>{this.context.t('Log Information. Device: {deviceId}', {deviceId: selectedSensorLog.DeviceID})}</h2> */}
      <div className="sample-graphs">
        <SensorPressureGraph
          title={UIDateFormater(selectedSample.Time, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone, projectTimezone)}
          plotData={plotBoxData}
          uiInfo={uiObj}
          legend={false}
          allowChangeUnits
        />
        <SensorPressureGraph
          title={UIDateFormater(selectedSample.Time, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone, projectTimezone)}
          plotData={plotData}
          uiInfo={uiObj}
          legend={false}
          allowChangeUnits
        />
      </div>
    </div>
  );
}

TransientAlertInfo.contextTypes = {
    t: PropTypes.func.isRequired
}

export default TransientAlertInfo;
