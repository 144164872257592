import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import * as alertsActions from '../../actions/AlertsActions';
import { getAddressByLatLong } from '../../actions/GlobalsActions';
import AddAlertForm from '../../components/AlertsSettings/AddAlertForm';

const _ = require('lodash');

const getInitialValues = (options) => {
  let initialValues = null;
  if (options && !_.isEmpty(options)) {
    const dateNow = new Date();
    const dateNowString = dateNow.toISOString().split('T')[0];
    const defultABC = options.ABC.find(item => item.label === 'A');
    const defultAlertState = options.AlertState.find(item => item.value === 6);
    const defultAlertType = options.AlertType.find(item => item.label === 'Leak');
    initialValues = {
      DetectionDateTime: dateNowString,
      fixDate: dateNowString,
      ABC: defultABC.value,
      AlertType: defultAlertType.value,
      AlertState: defultAlertState.value,
      Coordinate: '',
      Probability: 100
    };
  }
  return initialValues;
};

const mapStateToProps = (state, ownProps) => {
  const user = state.leaksList.user;
  const optionList = state.leaksList.optionList.options;
  const { WorkAreas = [], ContractorID = [], PCARepairCodesEnum = [], AlertState = [], AlertType = [], ABC = [] } = optionList;
  _.remove(ABC, opt => opt.label === 'D');

  const selectedProjectId = state.leaksList.selectedProject;
  let projectCouples = [];
  if (
    state.leaksList.leaksByProject[selectedProjectId] &&
    state.leaksList.leaksByProject[selectedProjectId].couples
  ) {
    projectCouples = state.leaksList.leaksByProject[selectedProjectId].couples.couples;
  }
  const couplesIdsData = projectCouples.map(couple => ({
    label: `${couple.id}:  ${couple.Address1} / ${couple.Address2}`,
    value: couple.id
  }));
  const initialValues = getInitialValues(optionList);
  return {
    user,
    options: { WorkAreas, ContractorID, PCARepairCodesEnum, AlertState, AlertType, ABC },
    couplesIdsData,
    selectedProjectId,
    initialValues,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    doClose: () => {
      ownProps.showAddAlertForm(false);
    },
    onSubmit: (values, dispatch, props) => {
      const requiredFields = ['ABC', 'DetectionDateTime', 'AlertType', 'AlertState', 'Coordinate'];
      requiredFields.forEach(fieldKey => {
        if (values[fieldKey] == null || values[fieldKey] === '') {
          throw new SubmissionError({
            [fieldKey]: 'Require',
            _error: `${fieldKey} is required`
          });
        }
      });
      if (!values.Coordinate || values.Coordinate.length === 0) {
        throw new SubmissionError({ Coordinate: 'Require', _error: 'No Coordinate' });
      } else {
        dispatch(alertsActions.createNewAlert(values));
      }
    },
    getAddressByLatLong: () => {
      dispatch(getAddressByLatLong('add-alert', 'StreetAddress'));
    },
    onCreateNewValue: (valueKey, value) => {
      dispatch(alertsActions.addLeakOption(valueKey, value));
    },
    onFetchRecommendedCouple: () => dispatch(alertsActions.fetchRecommendedCouple())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAlertForm);
