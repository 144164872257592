import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ThreeCircles } from 'react-loader-spinner';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import AppModal from './AppModal';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import { makeStyles } from '@mui/styles';
import { getIQ100BReport, getIQ100BReportProjectUsers } from '../../actions/MobileDevicesActions';

import { jsPDF } from "jspdf";
import 'jspdf-autotable';

import ExcelJS from 'exceljs';
import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater';
import { prettyRound } from '../../containers/UIhelper/UIhelper';

function generateTabelBodyForPdf(data, timeZone) {
  const result = [];
  data.forEach((row) => {
    const res = Object.entries(row).map(cell =>
      `${cell[0]}`.toLocaleLowerCase().includes('date') ||
      `${cell[0]}`.toLocaleLowerCase().includes('time')
        ? UIDateFormater(cell[1], DateType.DATE_AND_TIME_WITH_SECONDS, timeZone)
        : `${cell[0]}`.toLocaleLowerCase().includes('intensity') ||
        `${cell[0]}`.toLocaleLowerCase().includes('rate')
          ? prettyRound(cell[1])
          : cell[1]
    );
    result.push(res);
  });
  return result;
}

const xlsColumns = [
  { title: "User name", width: { wch: 20 } },
  { title: "Task name", width: { wch: 20 } },
  { title: "Date", width: { wch: 20 } },
  { title: "Start Time", width: { wch: 23 } },
  { title: "End Time", width: { wch: 23 } },
  { title: "Count samples", width: { wch: 13 } },
  { title: "Rate (S/H)", width: { wch: 10 } },
  { title: "Min Intensity", width: { wch: 13 } },
  { title: "Max Intensity", width: { wch: 13 } },
  { title: "Average Quality", width: { wch: 22 } },
  { title: "Suspected", width: { wch: 10 } },
  { title: "Detected", width: { wch: 10 } },
];

const animatedComponents = makeAnimated();

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '600px',
  },
  cell: {
    borderLeft: '1px solid #eee',
  },
});

const ReportFilter = ({
                        selectedProject,
                        dispatch,
                        isVisible,
                        context,
                        users,
                        isGenereated,
                        onGeneratedReport
                      }) => {
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [selected, setSelected] = useState('monthly');
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().substring(0, 7));
  const [selectedMonthFrom, setSelectedMonthFrom] = useState(new Date().toISOString().substring(0, 7));
  const [selectedMonthTo, setSelectedMonthTo] = useState(new Date().toISOString().substring(0, 7));

  const actionSelected = useCallback((val) => {
    setSelected(val);
  }, []);

  const selectMonth = useCallback((e) => {
    setSelectedMonth(e.target.value);
  }, []);

  const selectMonthFrom = useCallback((e) => {
    setSelectedMonthFrom(e.target.value);
  }, []);

  const selectMonthTo = useCallback((e) => {
    setSelectedMonthTo(e.target.value);
  }, []);

  const checkboxOptionsItem = useCallback((opt) => {
    const onClick = () => {
      actionSelected(opt);
    };
    return (
      <div key={opt} className='checkbox-iq100b-mobile'>
        <input type="checkbox" onChange={onClick} checked={selected === opt} value={opt} />
        <label onClick={onClick} style={selected !== opt ? { backgroundColor: '#fefefe', color: 'gray' } : undefined}>{opt}</label><br />
      </div>
    );
  }, [selected]);

  const actionUserList = useCallback((users) => {
    setSelectedUserList(users);
  }, []);

  const onClickGeneratedReport = useCallback(() => {
    onGeneratedReport({
      filter: selected,
      users: selectedUserList,
      dates: selected === 'monthly' ? [`${selectedMonth}-01`] : [selectedMonthFrom, selectedMonthTo]
    });
  }, [selected, selectedUserList, selectedMonth, selectedMonthFrom, selectedMonthTo, onGeneratedReport]);

  const types = useCallback(() => {
    const type = {
      daily: (
        <div className='checkbox-iq100b-mobile-type-view-daily'>
          <div>
            <label>{context.t('from')}:&nbsp;</label>
            <input type="date" min="2016-01" onChange={selectMonthFrom} value={selectedMonthFrom} />
          </div>
          <div>
            <label>{context.t('to')}:&nbsp;</label>
            <input type="date" min="2016-01" onChange={selectMonthTo} value={selectedMonthTo} />
          </div>
        </div>
      ),
      monthly: (
        <div className='checkbox-iq100b-mobile-type-view-monthly'>
          <input type="month" min="2016-01" onChange={selectMonth} value={selectedMonth} />
        </div>
      )
    };
    return (
      <div className='checkbox-iq100b-mobile-type-view'>
        {type[selected]}
      </div>
    );
  }, [selected, selectedMonthFrom, selectedMonthTo, selectedMonth, context.t]);

  return (
    <Fragment>
      {['monthly', 'daily'].map(checkboxOptionsItem)}
      {types()}
      <UserList
        users={users}
        actionUserList={actionUserList}
        context={context}
        selectedProject={selectedProject}
        dispatch={dispatch}
        isVisible={isVisible}
      />
      <div className='checkbox-iq100b-mobile-button-view'>
        <Button disabled={isGenereated} variant='contained' color='primary' onClick={onClickGeneratedReport}>
          {context.t('generate_report')}
        </Button>
      </div>
    </Fragment>
  );
};

ReportFilter.propTypes = {
  selectedProject: PropTypes.object,
  dispatch: PropTypes.func,
  isVisible: PropTypes.bool,
  context: PropTypes.object,
  users: PropTypes.array,
  isGenereated: PropTypes.bool,
  onGeneratedReport: PropTypes.func,
};

const UserList = ({ users, actionUserList, context, selectedProject, dispatch, isVisible }) => {
  const [options, setOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {
    if (!options.length && users && selectedProject && isVisible) {
      (async () => {
        try {
          const response = await dispatch(getIQ100BReportProjectUsers({
            body: { projectId: selectedProject.ID }
          }));
          if (response.status)
            setOptions(response.data);
          else setOptions([]);
        } catch (error) {
          setOptions([]);
          console.error('Error fetching project users: ', error);
        }
      })();
    }
  }, [users, options, selectedProject, isVisible, dispatch]);

  const onChange = useCallback(() => {
    setSelectAll(s => {
      if (!s)
        actionUserList(options);
      else
        actionUserList([]);
      return !s;
    });
  }, [options, actionUserList]);

  return (
    <div className='checkbox-iq100b-mobile-user-list'>
      <label>{context.t('users_list')}</label>
      {!options.length ? (
        <ThreeCircles height="30" width="30" color={'#6090B1'} />
      ) : (
        <Fragment>
          <div className='checkbox-iq100b-mobile-selected-row-type'>
            <input type="checkbox" onChange={onChange} checked={selectAll} value={selectAll} />
            <label onClick={onChange}>&nbsp;{context.t('select_all_users')}</label>
          </div>
          <Select
            isDisabled={selectAll}
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            placeholder={selectAll ? 'Selected All Users List' : 'select'}
            onChange={actionUserList}
            options={options}
          />
        </Fragment>
      )}
    </div>
  );
};

UserList.propTypes = {
  users: PropTypes.array,
  actionUserList: PropTypes.func,
  context: PropTypes.object,
  selectedProject: PropTypes.object,
  dispatch: PropTypes.func,
  isVisible: PropTypes.bool,
};

// IQ100BReportTable Component
function IQ100BReportTable({ data, timeZone }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table stickyHeader size='small'>
        <TableHead>
          <TableRow>
            {xlsColumns.map((col, index) => (
              <TableCell key={`header_${index}`} style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }} className={classes.cell}>
                {col.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow hover key={`${row.date}_${index}`}>
              <TableCell className={classes.cell}>{row.userName}</TableCell>
              <TableCell className={classes.cell}>{row.taskName}</TableCell>
              <TableCell className={classes.cell} style={{ minWidth: '170px' }}>
                {UIDateFormater(row.date, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone)}
              </TableCell>
              <TableCell className={classes.cell} style={{ minWidth: '170px' }}>
                {UIDateFormater(row.startTime, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone)}
              </TableCell>
              <TableCell className={classes.cell} style={{ minWidth: '170px' }}>
                {UIDateFormater(row.endTime, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone)}
              </TableCell>
              <TableCell className={classes.cell}>{row.countSamples}</TableCell>
              <TableCell className={classes.cell}>{prettyRound(row.rate)}</TableCell>
              <TableCell className={classes.cell}>{prettyRound(row.minIntensity)}</TableCell>
              <TableCell className={classes.cell}>{prettyRound(row.maxIntensity)}</TableCell>
              <TableCell className={classes.cell}>{row.averageQuality}</TableCell>
              <TableCell className={classes.cell}>{row.suspected}</TableCell>
              <TableCell className={classes.cell}>{row.detected}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

IQ100BReportTable.propTypes = {
  data: PropTypes.array,
  timeZone: PropTypes.string,
};

function IQ100BReportsModal(props, context) {
  const timeZone = useSelector(state => state.local.timeZone);
  const [isLoading, setIsLoading] = useState(false);
  const [exportFileName, setExportFileName] = useState('');
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const onClearReport = useCallback(() => {
    setIsLoading(false);
    setData([]);
  }, []);

  const handleExportExcel = async () => {
    if (!data.length) {
      alert('No data to export!');
      return;
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('IQ100B Report');

    worksheet.columns = xlsColumns.map((col, index) => ({
      header: col.title,
      key: `col${index}`,
      width: col.width.wch,
    }));

    data.forEach((item) => {
      worksheet.addRow([
        `${item.userName}`,
        `${item.taskName}`,
        UIDateFormater(item.date, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone),
        UIDateFormater(item.startTime, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone),
        UIDateFormater(item.endTime, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone),
        `${item.countSamples}`,
        prettyRound(+`${item.rate}`),
        prettyRound(+`${item.minIntensity}`),
        prettyRound(+`${item.maxIntensity}`),
        `${item.averageQuality}`,
        `${item.suspected}`,
        `${item.detected}`
      ]);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = exportFileName;
    link.click();
  };

  async function exportPdf() {
    const tableBody = generateTabelBodyForPdf(data, timeZone);
    const tableHeader = xlsColumns.map(col => col.title);

    const doc = new jsPDF({ orientation: 'landscape' });
    const horizontalPadding = 10;
    doc.setFontSize(16);
    doc.text(`IQ100B Report`, horizontalPadding, 10);
    doc.setFontSize(12);
    doc.text(`generate for ${exportFileName.split('IQ100B Report - ')[1]}`, horizontalPadding, 25);

    doc.autoTable({
      styles: { valign: 'center', fontSize: 5 },
      startX: 0,
      startY: 35,
      columns: tableHeader,
      body: tableBody,
      minCellWidth: 5,
      cellPadding: 2,
      didParseCell: (data) => {
        if (data.section === 'body') {
          data.cell.cellPadding = 5;
          switch (data.cell.raw) {
            case 'V':
              data.cell.styles.font = 'courier';
              data.cell.styles.fontStyle = 'bolditalic';
              data.cell.styles.textColor = 'green';
              break;
            case 'X':
              data.cell.styles.font = 'courier';
              data.cell.styles.fontStyle = 'bolditalic';
              data.cell.styles.textColor = 'red';
              break;
            default:
              break;
          }
        }
      }
    });

    doc.save(exportFileName);
  }

  const onGeneratedReport = useCallback(({ filter, users, dates }) => {
    const usersIds = users.map(user => user.value);
    if (!usersIds.length) return alert('Please Choose User First!');
    setData([]);
    (async () => {
      setIsLoading(true);
      try {
        const response = await dispatch(getIQ100BReport({
          body: {
            type: filter,
            usersIds,
            date: [dates[0], dates[1] ? dates[1] : null],
            projectId: props.selectedProject.ID
          }
        }));
        if (response.status) {
          setExportFileName(`IQ100B Report - ${dates[1] ? `${dates[0]} - ${dates[1]}` : dates[0]}.xlsx`);
          setData(response.data);
        } else {
          alert('This Report Is Empty!');
          setData([]);
        }
      } catch (error) {
        console.error('Error generating report: ', error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dispatch, props.selectedProject.ID]);

  return (
    <AppModal
      closeIcon
      fullWidth
      maxWidth='xl'
      open={props.isVisible}
      title={context.t('iq_100b_mobile')}
      onClose={props.close}
      content={(
        <div style={{ height: '75vh', overflow: 'hidden' }}>
          {!props.isFetching && props.selectedProject && (
            <div style={{ display: 'flex' }}>
              <div>
                <Typography component='p' variant='p'>{context.t('create_report')}</Typography>
                <ReportFilter
                  selectedProject={props.selectedProject}
                  dispatch={dispatch}
                  isVisible={props.isVisible}
                  context={context}
                  users={props.users}
                  isGenereated={isLoading}
                  onGeneratedReport={onGeneratedReport}
                />
                <div className='iq100b-export-buttons'>
                  <Button
                    disabled={isLoading}
                    style={{ width: 'max-content' }}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SaveIcon />}
                    onClick={exportPdf}
                  >
                    {context.t('save_as_pdf')}
                  </Button>
                  <Button
                    disabled={isLoading || !data.length}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SaveIcon />}
                    onClick={handleExportExcel}
                  >
                    {context.t('save_as_excel')}
                  </Button>
                </div>
              </div>
              <div style={{
                display: 'inline-flex',
                flex: 1,
                margin: 10,
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden'
              }}>
                {!isLoading && data.length ? (
                  <IQ100BReportTable timeZone={timeZone} data={data} />
                ) : isLoading && !data.length ? (
                  <ThreeCircles height="30" width="30" color={'#6090B1'} />
                ) : null}
              </div>
            </div>
          )}
        </div>
      )}
    />
  );
}

IQ100BReportsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

IQ100BReportsModal.propTypes = {
  close: PropTypes.func,
  isFetching: PropTypes.bool,
  isVisible: PropTypes.bool,
  selectedProject: PropTypes.object,
  users: PropTypes.array,
};

export default React.memo(IQ100BReportsModal);
