/* eslint-disable no-unused-vars */
/* eslint-disable react/no-multi-comp */
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import { Table, Column, Cell } from 'fixed-data-table-2';
import * as actionsProjects from '../../actions/ProjectsActions';
import { LAST_PROJECT } from '../../constants/SettingsKeys';
import Select from "react-select";

require('./../Picker/Picker.scss');
require('../TableActions/TableActions.scss');

const classNames = require('classnames');

class ProjectCell extends React.Component {

  constructor(props) {
    super(props);
    this.hashCode = this.hashCode.bind(this);
    this.intToRGB = this.intToRGB.bind(this);
    this.getContentByField = this.getContentByField.bind(this);
  }

  hashCode(str) { // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  intToRGB(i) {
    var c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  }

  getContentByField(project, field) {
    const value = project[field];
    switch (field) {
      case 'ShortName':
        return (
          <h3>
            {value}
          </h3>
        );
      case 'CustomerName':
      // case 'Sensors':
      // case 'Leaks':
      default:
        //const circleColor = '#' + this.intToRGB(this.hashCode(value));
        //const customerFirstCap = value.substring(0, 1).toUpperCase();
        return (
          <Cell className="project-details-cell">{value}</Cell>
        );
      //  <div >
      //    <div
      //      className="project-customer"
      //      data-tip={value}
      //      style={{ background: circleColor }}>{customerFirstCap}</div>
      //    <ReactTooltip place="top" effect="solid" />
      //  </div >
    }
  }

  render() {
    const { data, field, rowIndex } = this.props;
    const project = data[rowIndex];

    // const circleColor = '#' + this.intToRGB(this.hashCode(project.CustomerName));
    // const customerFirstCap = project.CustomerName.substring(0, 1).toUpperCase();
    return this.getContentByField(project, field);
  }
}

class ChooseProjectModal extends React.Component {

  static get propTypes() {
    return {
      projects: PropTypes.array,
    };
  }

  constructor(props, context) {
    super(props);

    this.state = {
      open: false,
      filteredProjects: props.projects || [],
      sortField: null,
      sortDir: null,
      filterBy: { value: 'ShortName', label: context.t('filter_by_project'), placeholder: 'Filter projects' },
      filterPlaceholder: 'Filter projects',
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.sortProjects = this.sortProjects.bind(this);
  }

  closeModal() {
    this.setState({ open: false, filteredProjects: this.props.projects });
  }

  openModal() {
    this.setState({ open: true });
  }

  handleClick(Proxy, rowIndex) {
    this.props.chooseProject(this.state.filteredProjects[rowIndex]);
    this.closeModal();
  }

  onFilterChange(e) {
    if (!e.target.value) {
      this.setState({
        filteredProjects: this.props.projects,
      });
    } else {
      var filterBy = e.target.value.toLowerCase();
      var size = this.props.projects.length;
      const filterType = this.state.filterBy.value;
      var filteredIndexes = this.props.projects.filter((project) => project[filterType].toLowerCase().indexOf(filterBy) !== -1);
      // var filteredIndexes = [];
      // for (var index = 0; index < size; index++) {
      //   var project = this.props.projects[index];
      //   if (project.ShortName.toLowerCase().indexOf(filterBy) !== -1) {
      //     filteredIndexes.push(project);
      //   }
      // }

      this.setState({
        filteredProjects: filteredIndexes,
      });
    }

  }

  sortProjects(event, fieldKey) {
    let dir;
    if (this.state.sortField == fieldKey) {
      dir = this.state.sortDir == 'desc' ? 'asc' : 'desc';
    } else {
      dir = 'desc';
    }

    const sortedList = this.state.filteredProjects.sort((a, b) => {
      const first = (dir === 'asc') ? a : b;
      const second = (dir === 'asc') ? b : a;
      if (first[fieldKey] < second[fieldKey]) {
        return -1;
      } else if (first[fieldKey] > second[fieldKey]) {
        return 1;
      } else {
        return 0;
      }
    });
    this.setState({
      filteredProjects: sortedList,
      sortField: fieldKey,
      sortDir: dir
    });
  }

  renderHeader(key, title) {
    let sortDir = 'asc';
    let active = false;
    if (this.state.sortField === key) {
      sortDir = this.state.sortDir || 'asc';
      active = true;
    }
    const cellClasses = classNames(
      'sortable',
      'sort-' + sortDir,
      { 'sort-active': active }
      // columnKeyClass
    );
    return (
      <Cell onClick={() => this.sortProjects(this, key)} className={cellClasses}>
        {title}
      </Cell>
    );
  }

  render() {
    const { open, filteredProjects } = this.state;
    // const { projects, selectedFeature, chooseProject } = this.props;

    return (
      <div className='project-modal-con'>
        <div className='table-actions'>
          <button onClick={this.openModal} className={"btn btn-link projectPickerBtn"} title={this.context.t('open_projects')} />
        </div>
        <AppModal
          open={open}
          onClose={this.closeModal}
          title={this.context.t('select_project')}
          content={(
            <Table
              className="projects-table"
              maxHeight={400}
              width={598}
              rowsCount={filteredProjects.length}
              rowHeight={60}
              headerHeight={30}
              onRowClick={this.handleClick}
              {...this.props}
            >
              <Column
                columnKey="CustomerName"
                width={80}
                flexGrow={1}
                // header={"Customer"}
                header={() => this.renderHeader('CustomerName', this.context.t("customer"))}
                cell={<ProjectCell data={filteredProjects} field="CustomerName" />} />
              <Column
                columnKey="ShortName"
                width={270} flexGrow={1}
                header={() => this.renderHeader('ShortName', this.context.t("project"))}
                cell={<ProjectCell data={filteredProjects} field="ShortName" />} />
            {/*  <Column*/}
            {/*    columnKey="Sensors"*/}
            {/*    width={60}*/}
            {/*    header={() => this.renderHeader('Sensors', this.context.t("sensors"))}*/}
            {/*    cell={<ProjectCell data={filteredProjects} field="Sensors" />} />*/}
            {/*  <Column*/}
            {/*    columnKey="Leaks"*/}
            {/*    width={60}*/}
            {/*    header={() => this.renderHeader('Leaks', this.context.t("leaks"))}*/}
            {/*    cell={<ProjectCell data={filteredProjects} field="Leaks" />} />*/}

            </Table>
          )}
          actions={(
            <div className='project-modal-btn-box'>
              <Select
                clearable={false}
                backspaceRemoves={false}
                deleteRemoves={false}
                className='filter-projects-field'
                value={this.state.filterBy}
                // placeholder={this.context.t('filter_by')}
                onChange={(selectedOption) => {
                  this.setState({
                    filterBy: selectedOption,
                    filterPlaceholder: selectedOption.placeholder,
                    filteredProjects: this.props.projects
                  });
                  this.refs.inputRef.value = '';
                }}
                options={[
                  { value: 'ShortName', label: this.context.t('filter_by_project'), placeholder: 'Filter projects' },
                  { value: 'CustomerName', label: this.context.t('filter_by_customer'), placeholder: 'Filter customers' },
                ]}
              />

              <input type="text"
                onChange={this.onFilterChange}
                className="form-control projectsFilterInput"
                placeholder={this.state.filterPlaceholder}
                ref="inputRef"
                autoFocus
              />

              <button
                className='btn btn-primary'
                onClick={this.closeModal}>
                {this.context.t('close')}
              </button>
            </div>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const projects = Object.assign([], state.leaksList.projectsList.items);

  return Object.assign({}, {
    projects
  });
};

const mapDispatchToProps = (dispatch, ownProps) => (
  {
    chooseProject: (project) => {
      dispatch(actionsProjects.selectProject(project.ID));
      localStorage.setItem(LAST_PROJECT, project.ID);
    }
  }
);

ChooseProjectModal.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseProjectModal);
