import * as types from '../constants/ActionTypes';
import { assign } from 'lodash';
import { combineReducers } from 'redux';

import { history } from './AlertsDetailsReducers';
import { leak } from './AlertsReducers';
import { sensors } from './SensorsReducers';
import { couples } from './CouplesReducers';
import { assessments } from './AssessmentsReducers';
import { log } from './DevicesReducers';
import { files } from './FilesReducers';
import { optionList } from './GlobalsReducers';
import { selectedProject, projectsList } from './ProjectsReducers';
import { samples } from './SamplesReducers';
import { user } from './LoginReducers';
import { reports } from './ReportsReducers';
import { pressure } from './PressureReducer';
import { dmas } from './dmas';
import { inspect } from './inspect';

const initialState = {
  project: false,
  leaks: [],
};

// @TODO IN FUTURE: change the arrays to objects like in the interferences
// This not only a leaks function
function leaks(
  state = {
    isFetching: false,
    didInvalidate: false,
    sort: { init: true },
    iconFeatures: {},
    items: [],
    history: {},
    leaksIds: [],
    leakGrowth: {
      id: null,
      data: null,
      regressionData: null,
      isFetching: false,
    },
    leakGrowthData: {},
    leakCouple: {},
    samples: {},
    coupleSamples: {},
    sensors: sensors(),
    sops: { isFetching: false, sops: [], sopsIcons: [], indexMap: {} },
    // ~~ INTERFERENCES ~~ //
    interferences: {
      isFetching: false,
      interferences: [],
      interferencesIcons: {},
    },
    couples: { isFetching: false, couples: [] },
    assessments: {},
    noiseSamples: {
      isFetching: false,
      noiseSamples: [],
      noiseSamplesIcons: [],
    },
    iQuariusPublishedSamples: {
      isFetching: false,
      iQuariusSamples: [],
      iQuariusSamplesIcons: [],
    },
    waterMeterAlerts: { isFetching: false, meterAlerts: [], meterIcons: [] },
    couplePath: { isFetching: false, path: [] },
    linkedSensorsPaths: { isFetching: false, paths: [] },
    couplesCoveragePaths: { isFetching: false, paths: [] },
    // details: {},
    indexMap: {},
    log: {},
    files: {},
    images: []
  },
  action
) {
  switch (action.type) {
    case types.RECEIVE_SUSPICIOUS_ALERTS_IDS: {
      if (action.mode !== 'alerts') {
        return state;
      } else {
        const alerts = [...state.items];
        const indexMap = state.indexMap;
        action.alertsIds.forEach((data) => {
          const alert = alerts[indexMap[data.ID]];
          if (alert) {
            alert.Burst = 2;
          }
        });
        return Object.assign({}, state, {
          items: alerts,
        });
      }
    }

    case types.SELECT_PROJECT: {
      return Object.assign({}, state, {
        didInvalidate: true,
        items: [],
        couples: { isFetching: false, couples: [] },
        assessments: {},
        history: {},
        leakGrowth: {
          id: null,
          data: null,
          regressionData: null,
          isFetching: false,
        },
        leakGrowthData: {},
        leakCouple: {},
        sensors: sensors(state.sensors, action),
        sops: { isFetching: false, sops: [], sopsIcons: [], indexMap: {} },
        noiseSamples: {
          isFetching: false,
          noiseSamples: [],
          noiseSamplesIcons: [],
        },
        iQuariusPublishedSamples: {
          isFetching: false,
          iQuariusSamples: [],
          iQuariusSamplesIcons: [],
        },
        waterMeterAlerts: {
          isFetching: false,
          meterAlerts: [],
          meterIcons: [],
        },
        couplePath: { isFetching: false, path: [] },
        linkedSensorsPaths: { isFetching: false, paths: [] },
        couplesCoveragePaths: { isFetching: false, paths: [] },
        details: {},
        // log: {},
      });
    }
    case types.INVALIDATE_PROJECT:
      return Object.assign({}, state, {
        didInvalidate: true,
      });

    case '@@router/LOCATION_CHANGE':
    case types.REQUEST_SENSORS:
    case types.RECEIVE_SENSORS:
    case types.RECEIVE_SENSORS_NON_ASSOCIATED:
    case types.REQUEST_SENSORS_WITH_ACTUAL_PRESSURE_TRANSIENT:
    case types.RECEIVE_SENSORS_WITH_ACTUAL_PRESSURE_TRANSIENT:
    case types.REQUEST_SENSOR_HISTORY:
    case types.RECEIVE_SENSOR_HISTORY:
    case types.REQUEST_SENSOR_DETAILS:
    case types.RECEIVE_SENSOR_DETAILS:
    case types.REQUEST_SENSOR_LOGS:
    case types.RECEIVE_SENSOR_LOGS:
    case types.REQUEST_SENSOR_BITS:
    case types.RECEIVE_SENSOR_BITS:
    case types.REQUEST_SENSOR_NC:
    case types.RECEIVE_SENSOR_NC:
    case types.REQUEST_SENSOR_NOISE_ALERT:
    case types.RECEIVE_SENSOR_NOISE_ALERT:
    case types.SET_NOISE_SAMPLES_COUNT:
    case types.REQUEST_SENSOR_IMAGES:
    case types.RECEIVE_SENSOR_IMAGES:
    case types.REQUEST_SENSOR_G5_STATUS:
    case types.RECEIVE_SENSOR_G5_STATUS:
    case types.REQUEST_SENSOR_G5_BIT:
    case types.RECEIVE_SENSOR_G5_BIT:
    case types.REQUEST_SENSOR_G5_BIT_SAMPLE_MESSAGES:
    case types.RECEIVE_SENSOR_G5_BIT_SAMPLE_MESSAGES:
    case types.REQUEST_SENSOR_G5_NOISE:
    case types.RECEIVE_SENSOR_G5_NOISE:
    case types.SELECT_SENSOR_G5_MESSAGE:
    case types.SELECT_SENSOR_G5_NOISE_MESSAGE:
    case types.SELECT_SENSOR_G5_BIT_SAMPLE_MESSAGE:
    case types.RECEIVE_SENSOR_G5_BIT_SAMPLE_AUDIO:
    case types.SET_G5_NOISE_GRAPH:
    case types.RECEIVE_SENSOR_SAMPLE_WAVS_PATHS:
    case types.REQUEST_SENSORS_ACTIVITY_DATA:
    case types.RECEIVE_SENSORS_ACTIVITY_DATA:
    case types.REQUEST_SENSORS_TECHNICIAN_DATA:
    case types.RECEIVE_SENSORS_TECHNICIAN_DATA:
    case types.SENSORS_ACTIVITY_DATA_SET_VISIBILITY:
    case types.SENSORS_TECHNICIAN_DATA_SET_VISIBILITY:
    // case types.IQ100B_MOBILE_REPORTS_DATA_SET_VISIBILITY:
    case types.SORT_SENSORS_LOCALY: {
      return sensors(state, action);
    }
    case types.RECEIVE_SOPS:
      return Object.assign({}, state, {
        sops: {
          isFetching: false,
          sops: action.sops,
          sopsIcons: action.sopsIcons,
          indexMap: action.indexMap,
        },
      });
    case types.REQUEST_SOPS:
      return Object.assign({}, state, {
        sops: { isFetching: true, sops: [], sopsIcons: [], indexMap: {} },
      });

    // ~~ INTERFERNCES ~~ //
    case types.REQUEST_INTERFERENCES:
      return Object.assign({}, state, {
        interferences: {
          isFetching: true,
          interferences: [],
          interferencesIcons: [],
        },
      });
    case types.RECEIVE_INTERFERENCES:
      return Object.assign({}, state, {
        interferences: {
          isFetching: false,
          interferences: action.interferences,
          interferencesIcons: action.interferencesIcons,
          indexMap: action.indexMap,
        },
      });

    //case types.REQUEST_COUPLES:
    //    return Object.assign({}, state, {
    //        couples: { isFetching: true, couples: [] }
    //    });
    //case types.RECEIVE_COUPLES:
    //    return Object.assign({}, state, {
    //        couples: { isFetching: false, couples: action.couples }
    //    });
    case types.RECEIVE_NOISE_SAMPLES:
      return Object.assign({}, state, {
        noiseSamples: {
          isFetching: false,
          noiseSamples: action.noiseSamples,
          noiseSamplesIcons: action.noiseSamplesIcons,
          indexMap: action.indexMap,
        },
      });
    case types.REQUEST_NOISE_SAMPLES:
      return Object.assign({}, state, {
        noiseSamples: {
          isFetching: true,
          noiseSamples: [],
          noiseSamplesIcons: [],
        },
      });
    case types.RECEIVE_IQUARIUS_PUBLISHED_SAMPLES:
      return Object.assign({}, state, {
        iQuariusPublishedSamples: {
          isFetching: false,
          iQuariusSamples: action.iQuariusSamples,
          iQuariusSamplesIcons: action.iQuariusSamplesIcons,
          indexMap: action.indexMap,
        },
      });
    case types.REQUEST_IQUARIUS_PUBLISHED_SAMPLES:
      return Object.assign({}, state, {
        iQuariusPublishedSamples: {
          isFetching: true,
          iQuariusSamples: [],
          iQuariusSamplesIcons: [],
        },
      });
    case types.REQUEST_WATER_METER_ALERTS:
      return Object.assign({}, state, {
        waterMeterAlerts: {
          isFetching: true,
          meterAlerts: [],
          meterIcons: [],
          indexMap: {},
        },
      });
    case types.RECEIVE_WATER_METER_ALERTS:
      return Object.assign({}, state, {
        waterMeterAlerts: {
          isFetching: false,
          meterAlerts: action.meterAlerts,
          meterIcons: action.meterIcons,
          indexMap: action.indexMap,
        },
      });
    case types.REQUEST_SENSOR_LINED_COUPLES:
      return Object.assign({}, state, {
        linkedSensorsPaths: { isFetching: true, paths: [] },
      });
    case types.RECEIVE_SENSOR_LINED_COUPLES:
      return Object.assign({}, state, {
        linkedSensorsPaths: { isFetching: false, paths: action.data },
      });
    case types.REQUEST_COUPLES_COVERAGE_PATHS:
      return Object.assign({}, state, {
        couplesCoveragePaths: { isFetching: true, paths: [] },
      });
    case types.RECEIVE_COUPLES_COVERAGE_PATHS:
      return Object.assign({}, state, {
        couplesCoveragePaths: { isFetching: false, paths: action.data },
      });
    case types.REQUEST_LEAKS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        items: [],
        // leakGrowthData: {},
        iconFeatures: {},
      });
    case types.RECEIVE_LEAKS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        iconFeatures: action.iconFeatures || {},
        items: action.leaks,
        lastUpdated: action.receivedAt,
        indexMap: action.indexMap,
      });

    // case types.RECEIVE_SINGLE_LEAK:
    //   console.log('receive single leak', action);
    //   const newState =  Object.assign({}, state, {
    //     isFetching: false,
    //     didInvalidate: false,
    //     iconFeatures: action.iconFeatures || {},
    //     items: action.leak,
    //     lastUpdated: action.receivedAt,
    //     indexMap: action.indexMap,
    //   });
    //   console.log('newState', newState);
    //   return newState;
    case types.RECEIVE_LEAKS_IDS:
      return Object.assign({}, state, {
        leaksIds: action.leaksIds,
      });
    case types.UPDATE_LEAK_DATA:
      return Object.assign({}, state, {
        items: leak(state.items, action),
      });
    case types.UPDATE_STATUS:
      return Object.assign({}, state, {
        items: leak(state.items, action),
      });
    case types.RECEIVE_LEAK_HISTORY:
    case types.REQUEST_LEAK_HISTORY:
      return Object.assign({}, state, {
        history: history(state.history, action),
      });

    case types.RECEIVE_LEAK_GROWTH:
      return Object.assign({}, state, {
        leakGrowth: {
          id: action.leakId,
          data: action.data,
          regressionData: action.regressionData,
          isFetching: false,
        },
      });
    // case types.RECEIVE_ALERTS_GROWING_DATA: {
    //   const alerts = [...state.items];
    //   const alertsIndexMap = state.indexMap;
    //   action.data.forEach((item) => {
    //     if (
    //       alerts[alertsIndexMap[item.ID]] &&
    //       alerts[alertsIndexMap[item.ID]].Burst != 1
    //     ) {
    //       if (item.r2 > 0.5) {
    //         const degrees = Math.atan(item.growingVal) * (180 / Math.PI);
    //         // console.log(`ID: ${item.ID} : angel degrees ${degrees}`);
    //         if (degrees > 65) {
    //           alerts[alertsIndexMap[item.ID]].Burst = 4;
    //         } else if (degrees > 30) {
    //           alerts[alertsIndexMap[item.ID]].Burst = 3;
    //         } else if (degrees <= -30) {
    //           alerts[alertsIndexMap[item.ID]].Burst = 0.5;
    //         }
    //       }
    //     }
    //     if (alerts[alertsIndexMap[item.ID]]) {
    //       alerts[alertsIndexMap[item.ID]].growingValue = item.growingVal;
    //     }
    //   });
    //
    //   return {
    //     ...state,
    //     items: alerts,
    //   };
    // }
    case types.RECEIVE_ALERT_GROWTH_DATA: {
      return { ...state, leakGrowthData: action.payload };
    }
    case types.REQUEST_LEAK_GROWTH:
      return Object.assign({}, state, {
        leakGrowth: {
          id: null,
          data: null,
          regressionData: null,
          isFetching: true,
        },
      });

    case types.REQUEST_LEAK_IMAGES:
      return Object.assign({}, state, {
        images: [],
      });
    case types.RECEIVE_LEAK_IMAGES:
      return Object.assign({}, state, {
        images: action.images,
      });
    case types.RECEIVE_LEAK_FILES:
    case types.REQUEST_LEAK_FILES:
    case types.UPLOAD_FILE_REQ:
    case types.UPLOAD_FILE_RES:
      return Object.assign({}, state, {
        files: files(state.files, action),
      });
    case types.RECEIVE_DEVICE_LOG:
    case types.REQUEST_DEVICE_LOG:
      return Object.assign({}, state, {
        log: log(state.log, action),
      });
    case types.RECEIVE_LEAK_COUPLES:
    case types.REQUEST_LEAK_COUPLES:
      return Object.assign({}, state, {
        leakCouple: couples(state.leakCouple, action),
      });
    case types.SORT_COUPLES_LOCALY:
      return couples(state, action);
    case types.RECEIVE_SAMPLES:
    case types.REQUEST_SAMPLES:
      return Object.assign({}, state, {
        samples: samples(state.samples, action),
      });

    // case types.RECEIVE_LEAK_DETAILS:
    // case types.REQUEST_LEAK_DETAILS:
      // return Object.assign({}, state, {
      //   details: details(state.details, action),
      // });

    case types.REQUEST_COUPLE_SAMPLES:
    case types.RECEIVE_COUPLE_SAMPLES:
      return Object.assign({}, state, {
        coupleSamples: couples(state.coupleSamples, action),
      });

    case types.SELECT_SENSOR_SAMPLE:
      return Object.assign({}, state, {
        sensors: Object.assign({}, state.sensors, {
          details: Object.assign({}, state.sensors.details, {
            barsData: [
              action.selectedSensorSample?.Nc3f1 || 0,
              action.selectedSensorSample?.Nc3f2 || 0,
              action.selectedSensorSample?.Nc3f3 || 0,
            ],
          }),
        }),
      });

    default:
      return state;
  }
}

function leaksByProject(
  state = {
    selectedFeature: '',
    selectedSensorLog: {},
    selectedSensorBit: {},
    selectedFeatureType: '',
    displayAssessments: false,
    displayAssessmentsOnlyRealLeaks: true,
    relatedItem: {
      item: '',
      type: '',
    },
    reports: reports({ isFetching: false, report: null }, null),
    iq100bMobileRepors: {
      activity: {
        isVisible: false
      }
    }
  },
  action
) {
  switch (action.type) {
    case types.REFRESH_STATE:
      return Object.assign({}, state, { time: action.time });
    case types.UPDATE_LEAK_RES:
      return Object.assign({}, state, {
        [action.project]: Object.assign({}, state[action.project], {
          isFetching: false,
        }),
        notify: 'Data saved successfully.',
      });
    case types.UPDATE_LEAK_REQ:
      return Object.assign({}, state, {
        [action.project]: Object.assign({}, state[action.project], {
          isFetching: true,
        }),
      });
    case types.UPDATE_SINGLE_LEAK_REQ:
      return Object.assign({}, state, {
        [action.project]: Object.assign({}, state[action.project], {
          isFetching: true,
        }),
      });
    case types.CLEAR_NOTIFICATION:
      return Object.assign({}, state, {
        notify: '',
      });
    case types.SELECT_PROJECT:
      return Object.assign(
        {},
        state,
        { [action.project]: leaks(state[action.project], action) },
        {
          selectedFeature: '',
          selectedFeatureType: '',
          relatedItem: {
            item: '',
            type: '',
          },
        }
      );

    case types.INVALIDATE_PROJECT:
    case types.RECEIVE_LEAKS:
    case types.RECEIVE_LEAKS_IDS:
    case types.REQUEST_LEAKS:
    case types.RECEIVE_LEAK_HISTORY:
    case types.REQUEST_LEAK_HISTORY:
    case types.RECEIVE_LEAK_GROWTH:
    case types.REQUEST_LEAK_GROWTH:
    case types.RECEIVE_ALERTS_GROWING_DATA:
    case types.RECEIVE_ALERT_GROWTH_DATA:
    case types.REQUEST_SENSOR_HISTORY:
    case types.RECEIVE_SENSOR_HISTORY:
    case types.REQUEST_SENSOR_DETAILS:
    case types.RECEIVE_SENSOR_DETAILS:
    case types.REQUEST_SENSOR_LOGS:
    case types.RECEIVE_SENSOR_LOGS:
    case types.REQUEST_SENSOR_BITS:
    case types.RECEIVE_SENSOR_BITS:
    case types.REQUEST_SENSOR_NC:
    case types.RECEIVE_SENSOR_NC:
    case types.REQUEST_SENSOR_NOISE_ALERT:
    case types.RECEIVE_SENSOR_NOISE_ALERT:
    case types.SET_NOISE_SAMPLES_COUNT:
    case types.SORT_SENSORS_LOCALY:
    case types.SORT_COUPLES_LOCALY:
    case types.RECEIVE_DEVICE_LOG:
    case types.REQUEST_DEVICE_LOG:
    // case types.REQUEST_LEAK_DETAILS:
    // case types.RECEIVE_LEAK_DETAILS:
    case types.RECEIVE_LEAK_COUPLES:
    case types.REQUEST_LEAK_COUPLES:
    case types.RECEIVE_SAMPLES:
    case types.REQUEST_SAMPLES:
    case types.RECEIVE_SENSORS:
    case types.RECEIVE_SENSORS_NON_ASSOCIATED:
    case types.REQUEST_SENSORS_WITH_ACTUAL_PRESSURE_TRANSIENT:
    case types.RECEIVE_SENSORS_WITH_ACTUAL_PRESSURE_TRANSIENT:
    case types.REQUEST_SENSORS:
    case types.RECEIVE_SOPS:
    case types.REQUEST_SOPS:
    case types.RECEIVE_INTERFERENCES: // ~~ INTERFERENCES ~~ //
    case types.REQUEST_INTERFERENCES:
    case types.RECEIVE_NOISE_SAMPLES:
    case types.REQUEST_NOISE_SAMPLES:
    case types.SET_NOISE_SAMPLE_COLOR_SOURCE_ATTR:
    case types.REQUEST_IQUARIUS_PUBLISHED_SAMPLES:
    case types.RECEIVE_IQUARIUS_PUBLISHED_SAMPLES:
    case types.REQUEST_WATER_METER_ALERTS:
    case types.RECEIVE_WATER_METER_ALERTS:
    case types.RECEIVE_SENSOR_SAMPLE_WAVS_PATHS:
    case types.REQUEST_SENSOR_LINED_COUPLES:
    case types.RECEIVE_SENSOR_LINED_COUPLES:
    case types.REQUEST_COUPLES_COVERAGE_PATHS:
    case types.RECEIVE_COUPLES_COVERAGE_PATHS:
    case types.REQUEST_COUPLE_SAMPLES:
    case types.RECEIVE_COUPLE_SAMPLES:
    case types.RECEIVE_LEAK_IMAGES:
    case types.REQUEST_LEAK_IMAGES:
    case types.REQUEST_SENSOR_IMAGES:
    case types.RECEIVE_SENSOR_IMAGES:
    case types.REQUEST_SENSOR_G5_STATUS:
    case types.RECEIVE_SENSOR_G5_STATUS:
    case types.REQUEST_SENSOR_G5_BIT:
    case types.RECEIVE_SENSOR_G5_BIT:
    case types.REQUEST_SENSOR_G5_BIT_SAMPLE_MESSAGES:
    case types.RECEIVE_SENSOR_G5_BIT_SAMPLE_MESSAGES:
    case types.REQUEST_SENSOR_G5_NOISE:
    case types.RECEIVE_SENSOR_G5_NOISE:
    case types.SELECT_SENSOR_G5_MESSAGE:
    case types.SELECT_SENSOR_G5_NOISE_MESSAGE:
    case types.SELECT_SENSOR_G5_BIT_SAMPLE_MESSAGE:
    case types.RECEIVE_SENSOR_G5_BIT_SAMPLE_AUDIO:
    case types.SET_G5_NOISE_GRAPH:
    case types.REQUEST_SENSORS_ACTIVITY_DATA:
    case types.RECEIVE_SENSORS_ACTIVITY_DATA:
    case types.REQUEST_SENSORS_TECHNICIAN_DATA:
    case types.RECEIVE_SENSORS_TECHNICIAN_DATA:
    case types.SENSORS_ACTIVITY_DATA_SET_VISIBILITY:
    case types.SENSORS_TECHNICIAN_DATA_SET_VISIBILITY:
    // case types.IQ100B_MOBILE_REPORTS_DATA_SET_VISIBILITY:
    case types.RECEIVE_LEAK_FILES:
    case types.REQUEST_LEAK_FILES:
    case types.UPLOAD_FILE_REQ:
    case types.UPLOAD_FILE_RES:
    case types.RECEIVE_SUSPICIOUS_ALERTS_IDS:
    case types.SET_MOBILE_NOISE_SAMPLE_COLOR_SOURCE_ATTR:
      return Object.assign({}, state, {
        [action.project]: leaks(state[action.project], action),
      });
    case types.SELECT_SENSOR_LOG:
      return Object.assign({}, state, {
        selectedSensorLog: action.selectedSensorLog,
      });
    case types.SELECT_SENSOR_BIT:
      return Object.assign({}, state, {
        selectedSensorBit: action.selectedSensorBit,
      });
    case types.UPDATE_LEAK_DATA:
    case types.UPDATE_STATUS:
      return Object.assign({}, state, {
        [action.selectedProject]: leaks(state[action.selectedProject], action),
      });
    case types.SELECT_LEAK:
      return Object.assign({}, state, {
        selectedFeature: action.selectedFeature,
        selectedFeatureType: action.featureType,
        leak: action.leak,
      });
    case types.ADD_SINGLE_FEATURE: {
      // TODO: Have to test. not connect in production yet.
      let newList;
      let icons;
      let newIndexMap;
      switch (action.featureType) {
        case 'alert':
          newList = Object.assign([], state[action.project].items);
          icons = Object.assign([], state[action.project].iconFeatures);
          newIndexMap = Object.assign([], state[action.project].indexMap);

          newList.push(action.feature);
          icons.push(action.icon);
          newIndexMap[action.feature.id] = newList.length - 1;
          return Object.assign({}, state, {
            [action.project]: Object.assign({}, state[action.project], {
              items: newList,
              iconFeatures: icons || {},
              indexMap: newIndexMap,
            }),
          });
        case 'sensor':
          newList = Object.assign([], state[action.project].sensors.sensors);
          icons = Object.assign([], state[action.project].sensors.iconFeatures);
          newIndexMap = Object.assign(
            [],
            state[action.project].sensors.indexMap
          );

          newList.push(action.feature);
          icons.push(action.icon);
          newIndexMap[action.feature.id] = newList.length - 1;
          return Object.assign({}, state, {
            [action.project]: Object.assign({}, state[action.project], {
              sensors: Object.assign({}, state[action.project].sensors, {
                sensors: newList,
                iconFeatures: icons,
                indexMap: newIndexMap,
              }),
            }),
          });

        default:
          return state;
      }
    }
    // case types.SELECT_SAMPLE:
    //     return Object.assign({}, state, {
    //         selectedSample: action.selectedSample
    //     });

    case types.REQUEST_SAMPLE_RESOURCES:
      return Object.assign({}, state, {
        selectedSample: Object.assign({}, state.selectedSample, {
          tempData: {},
        }),
      });
    case types.RECEIVE_SAMPLE_RESOURCES:
      return Object.assign({}, state, {
        selectedSample: Object.assign({}, state.selectedSample, {
          tempData: {
            id: action.selectedSample,
            res: action.resources,
          },
        }),
      });

    case types.SELECT_SENSOR_SAMPLE:
      return Object.assign({}, state, {
        selectedSensorSample: action.selectedSensorSample,
        [action.project]: leaks(state[action.project], action),
      });
    case types.REQUEST_COUPLES:
    case types.RECEIVE_COUPLES:
    case types.RECEIVE_COUPLES_RM:
      if (state[action.project]) {
        return Object.assign({}, state, {
          [action.project]: couples(state[action.project], action),
        });
      } else {
        return state;
      }

    case types.SELECT_COUPLE_ALERT:
      return Object.assign({}, state, {
        selectedAssessment: action.alert,
      });
    case types.REQUEST_COUPLES_ALERTS:
    case types.RECEIVE_COUPLES_ALERTS:
    case types.REQUEST_COUPLE_SAMPLES_FOR_ALERT:
    case types.RECEIVE_COUPLE_SAMPLES_FOR_ALERT:
      if (state[action.project]) {
        return Object.assign({}, state, {
          [action.project]: assessments(state[action.project], action),
        });
      } else {
        return state;
      }

    //return couples(state, action);
    case types.REQUEST_COPULE_PATH:
    case types.RECEIVE_COPULE_PATH:
      if (state[action.project]) {
        return Object.assign({}, state, {
          [action.project]: couples(state[action.project], action),
        });
      }
      break;
    case types.REQUEST_REPORT:
    case types.RECEIVE_REPORT:
      return Object.assign({}, state, {
        reports: reports(state, action),
      });
    case types.CHANGE_ASSESSMENTS_MODE:
      return Object.assign({}, state, {
        displayAssessments: action.display,
        selectedAssessment: '',
      });
    case types.ASSESSMENT_SET_SHOW_ONLY_REAL:
      return Object.assign({}, state, {
        displayAssessmentsOnlyRealLeaks: action.show,
        selectedAssessment: '',
      });
    case types.IQ100B_MOBILE_REPORTS_DATA_SET_VISIBILITY: {
      return {
        ...state,
        iq100bMobileRepors: {
          // ...state.sensors,
          activity: {
            // ...state.sensors.activity,
            isVisible: action.payload
          }
        }
      };
    }
    default:
      return state;
  }
}

function filters(
  state = {
    filters: [],
    defFilters: [],
    sort: false,
    sensorsFilters: [],
    sensorsSort: { field: 'id', dir: 'asc' },
    sensorsDefFilters: [],
    interferencesFilters: [],
    interferencesSort: {
      field: 'ID',
      dir: 'asc',
    },
    noiseAlerts: {
      defFilters: { AlertState: [1, 2, 3, 4, 10] },
      filters: [{ field: 'AlertState', value: [1, 2, 3, 4, 10] }],
    },
    // valveAlerts: {
    //   defFilters: { AlertState: [1, 2, 3, 4] },
    //   filters: [{ field: 'alert_state', value: [1, 2, 3, 4] }],
    // },
    sops: {
      sort: false,
      defFilters: [],
      filters: [],
    },
    couples: {
      sort: false,
      filters: [],
      defFilters: [],
    },
    projects: {
      sort: false,
    },
    users: {
      sort: false,
    },
    customers: {
      sort: false,
    },
  },
  action
) {
  switch (action.type) {
    case types.SET_FILTERS:
      return Object.assign({}, state, {
        filters: action.filters,
      });
    case types.SET_DEF_FILTERS:
      return Object.assign({}, state, {
        defFilters: action.filters,
      });
    // case types.SET_VALVE_ALERTS_FILTERS:
    //   return {
    //     ...state,
    //     valveAlerts: {
    //       ...state.valveAlerts,
    //       filters: action.filters,
    //     },
    //   };
    case types.SET_NOISE_ALERTS_FILTERS:
      return {
        ...state,
        noiseAlerts: {
          ...state.noiseAlerts,
          filters: action.filters,
        },
      };
    case types.SET_NOISE_ALERTS_DEF_FILTERS:
      return {
        ...state,
        noiseAlerts: {
          ...state.noiseAlerts,
          defFilters: action.filters,
        },
      };
    case types.SORT_LEAKS:
      return Object.assign({}, state, {
        sort: { field: action.field, dir: action.dir },
      });
    // case types.SORT_MOBILE_LEAKS:
    //   return Object.assign({}, state, {
    //     sort: { field: action.field, dir: action.dir },
    //   });
    case types.SORT_SOPS:
      return Object.assign({}, state, {
        sops: Object.assign({}, state.sops, {
          sort: { field: action.field, dir: action.dir },
        }),
      });
    case types.SET_SOPS_FILTERS:
      return Object.assign({}, state, {
        sops: Object.assign({}, state.sops, {
          filters: action.filters,
        }),
      });
    case types.SET_SOPS_DEF_FILTERS:
      return Object.assign({}, state, {
        sops: Object.assign({}, state.sops, {
          defFilters: action.filters,
        }),
      });
    case types.SET_SENSORS_FILTERS:
      return Object.assign({}, state, {
        sensorsFilters: action.filters,
      });
    case types.SET_SENSORS_DEF_FILTERS:
      return Object.assign({}, state, {
        sensorsDefFilters: action.filters,
      });
    case types.SORT_SENSORS:
      return Object.assign({}, state, {
        sensorsSort: { field: action.field, dir: action.dir },
      });
    case types.SORT_SENSORS_LOCALY:
      return Object.assign({}, state, {
        sensorsSort: { field: action.field, dir: action.dir },
      });
    case types.SORT_COUPLES:
    case types.SORT_COUPLES_LOCALY:
      return Object.assign({}, state, {
        couples: Object.assign({}, state.couples, {
          sort: { field: action.field, dir: action.dir },
        }),
      });

    case types.SET_COUPLES_FILTERS:
      return Object.assign({}, state, {
        couples: Object.assign({}, state.couples, {
          filters: action.filters,
        }),
      });
    case types.SET_COUPLES_DEF_FILTERS:
      return Object.assign({}, state, {
        couples: Object.assign({}, state.couples, {
          defFilters: action.filters,
        }),
      });
    case types.SORT_SAMPLES:
      return Object.assign({}, state, {
        samplesSort: { field: action.field, dir: action.dir },
      });
    case types.SORT_COUPLE_SAMPLES:
      return Object.assign({}, state, {
        coupleSamplesSort: { field: action.field, dir: action.dir },
      });
    case types.SET_USERS_DEF_FILTERS:
      return Object.assign({}, state, {
        users: Object.assign({}, state.couples, {
          sort: action.filters,
        }),
      });
    case types.SORT_PROJECTS_LOCALY:
      return Object.assign({}, state, {
        projects: Object.assign({}, state.projects, {
          sort: {
            field: action.field,
            dir: action.dir,
          },
        }),
      });
    case types.SORT_CUSTOMERS_LOCALY:
      return Object.assign({}, state, {
        customers: Object.assign({}, state.customers, {
          sort: {
            field: action.field,
            dir: action.dir,
          },
        }),
      });
    case types.SORT_INTERFERENCES_LOCALY:
      return {
        ...state,
        interferencesSort: {
          field: action.field,
          dir: action.dir,
        },
      };
    case types.SORT_MOBILE_TASKS_LOCALLY:
      return {
        ...state,
        mobileTasksSort: {
          field: action.field,
          dir: action.dir,
        },
      };
    case types.SET_INTERFERENCE_SETTINGS:
      return Object.assign({}, state, {
        interferencesFilters: action.filters,
        interferencesSort: {
          dir: action.sortParams.dir,
          field: action.sortParams.field,
        },
      });
    // console.log("IN: case types.SET_INTERFERENCE_SETTINGS:");
    // console.table(action);
    // return state;

    default:
      return state;
  }
}

const customers = (
  state = {
    items: [],
    indexMap: {},
    optionsList: [],
    dmas: [],
  },
  action
) => {
  switch (action.type) {
    case types.RECEIVE_CUSTOMERS:
      return Object.assign({}, state, {
        items: action.items,
        indexMap: action.indexMap,
        optionsList: action.optionsList,
      });
    case types.SORT_CUSTOMERS_LOCALY:
      return Object.assign({}, state, {
        items: action.customers,
        indexMap: action.indexMap,
      });
    default:
      return state;
  }
};

//function getRelatedCoupleByAlertId(alertID, alerts, alertsIndex) {
//  const alert = alerts[alertsIndex[alertID]]
//    const coupleID = (alert == null) ? '' : alert.CoupleID;
//    return coupleID;
//}

const leaksList = combineReducers({
  leaksByProject,
  selectedProject,
  projectsList,
  optionList,
  filters,
  customers,
  user,
  pressure,
  dmas,
  inspect,
});

export default leaksList;
