import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppModal from '../../Modals/AppModal';
import { Field, reduxForm } from 'redux-form';
import { ENV, ENVIRONMENTS } from '../../../constants/EndPoints';
import ImagesTab from '../../AlertsTabs/ImagesTab/ImagesTab';
import Input from '../../Input/Input';
import AddressField from '../../Custom/AddressField';
import CommentField from '../../CommentField/CommentField';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

import '../../SampleInfo/SampleInfo.scss';
import useProjectTimeZone from '../../../hooks/useProjectTimeZone';

function SensorTab(props, context) {
  const {
    selectedFeature,
    error,
    handleSubmit,
    pristine,
    submitting,
    user,
    CSensorTab,
    timeZone,
    fileDetails,
    downloadFile,
    images,
    onEdit,
    onCopy,
    getAddressByLatLong,
    initialValues,
  } = props;

  const [textDir, setTextDir] = useState("ltr");
  const [galleryOpen, setGalleryOpen] = useState(false);

  const changeTextDir = (dir) => {
    setTextDir(dir);
  };

  const openGallery = () => {
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };
  const projectTimeZone = useProjectTimeZone();
  const audioFile = fileDetails ? fileDetails.fileName : false;
  const audioSampleTime = fileDetails ? fileDetails.sampleTime : false;
  const originalFileName =
    fileDetails && fileDetails.originalFileName ? fileDetails.originalFileName : false;

  const labelsClass = 'col-xs-4 control-label';
  const fieldsClass = 'col-xs-8 control-input';
  const divStyle = { float: 'left', width: '100%' };

  return (
    <div>
      <form className="form-horizontal sticky-actions" onSubmit={handleSubmit}>
        <CommentField
          fieldName="MaintenanceComment"
          featureID={selectedFeature}
          placeholder={context.t('sensor_comment')}
          onEdit={onEdit}
          onCopy={onCopy}
          disabled={!user.editMode}
        />

        <div style={{ display: 'flex' }}>
          <div style={divStyle}>
            <AddressField
              className="form-group"
              user={user}
              fieldName="StreetAddress"
              onGetAddress={getAddressByLatLong}
            />
            <div className="form-group">
              <label className={labelsClass}>
                {context.t('asset_id')}:
              </label>
              <div className={fieldsClass}>
                <Field
                  name="AssetID"
                  component={Input}
                  type="text"
                  disabled
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group">
              <label className={labelsClass}>
                {context.t('first_installation_date')}:
              </label>
              <div className={fieldsClass}>
                <Field
                  name="SensorInstallationDate"
                  component={Input}
                  type="date"
                  className="form-control"
                  disabled={!user.editMode}
                />
              </div>
            </div>
            <div className="form-group">
              <label className={labelsClass}>
                {context.t('last_installation_date')}:
              </label>
              <div className={fieldsClass}>
                <Field
                  name="DeviceInstallationDate"
                  component={Input}
                  type="date"
                  className="form-control"
                  disabled={!user.editMode}
                />
              </div>
            </div>
            <div className="form-group">
              <label className={labelsClass}>
                {context.t('last_communication')}:
              </label>
              <div className={fieldsClass}>
                <Field
                  name="LastCommunicationTime"
                  component={Input}
                  type="date"
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            {user.advancedMapIndications && initialValues && initialValues.IsPressure && (
              <div className="form-group">
                <label className={labelsClass}>
                  {context.t('prs_calib_multiply')}:
                </label>
                <div className={fieldsClass}>
                  <Field
                    name="pressure_calib_coeff"
                    component={Input}
                    type="number"
                    className="form-control"
                  />
                </div>
              </div>
            )}
          </div>

          <div style={divStyle}>
            <div className="form-group">
              <label className={labelsClass}>
                {context.t('sample_time_3')}:
              </label>
              <div className={fieldsClass}>
                <Field
                  name="SampleTime1Millis"
                  component={Input}
                  className="form-control"
                  type="time"
                  timeZone={timeZone}
                  disabled
                />
              </div>
            </div>
            <div className="form-group">
              <label className={labelsClass}>
                {context.t('sample_time_2')}:
              </label>
              <div className={fieldsClass}>
                <Field
                  name="SampleTime2Millis"
                  component={Input}
                  className="form-control"
                  type="time"
                  timeZone={timeZone}
                  disabled
                />
              </div>
            </div>
            <div className="form-group">
              <label className={labelsClass}>
                {context.t('sample_time_1')}:
              </label>
              <div className={fieldsClass}>
                <Field
                  name="SampleTime3Millis"
                  component={Input}
                  className="form-control"
                  type="time"
                  timeZone={timeZone}
                  disabled
                />
              </div>
            </div>
            <div className="form-group">
              <label className={labelsClass}>
                {context.t('config_duration')}:
              </label>
              <div className={fieldsClass}>
                <Field
                  name="ConfigRecordingTimeStr"
                  component={Input}
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </div>
            <div className="form-group">
              <label className={labelsClass}>
                {context.t('radio_frequency')}:
              </label>
              <div className={fieldsClass}>
                <Field
                  name="RadioFrequency"
                  component={Input}
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </div>
            {user.advancedMapIndications && initialValues && initialValues.IsPressure && (
              <div className="form-group">
                <label className={labelsClass}>
                  {context.t('prs_calib_shift')}:
                </label>
                <div className={fieldsClass}>
                  <Field
                    name="pressure_calib_coeff_y_axis"
                    component={Input}
                    type="number"
                    className="form-control"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="form-actions text-right">
          <button
            className="btn btn-success btn-submit"
            disabled={pristine || submitting}
            type="submit"
          >
            {context.t('save')}
          </button>
          {JSON.parse(process.env.REACT_APP_FILES) && (
            <i
              className="btn btn-success btn-order icon-gallery"
              disabled={images == null || images.length === 0}
              onClick={openGallery}
            />
          )}
          <div className="form-feedback text-danger">{error}</div>
          {audioFile && audioSampleTime && (
            <a
              key={audioFile}
              className="btn-wav-download"
              onClick={() => downloadFile(audioFile, 'wav')}
              href="javascript:void(0);"
            >
              {context.t('download_sensor_audio')}
              <span style={{ marginLeft: '10px' }}>
                [
                {UIDateFormater(audioSampleTime, DateType.DATE_AND_TIME, timeZone, projectTimeZone)}
                ]
              </span>
            </a>
          )}
          {originalFileName && (
            <a
              key={originalFileName}
              className="btn-wav-download"
              onClick={() => downloadFile(originalFileName, 'wav')}
              href="javascript:void(0);"
            >
              {context.t('original_audio')}
            </a>
          )}
        </div>
      </form>

      {JSON.parse(process.env.REACT_APP_FILES) && (
        <AppModal
          closeIcon
          open={galleryOpen}
          onClose={closeGallery}
          content={<ImagesTab images={images} />}
        />
      )}
    </div>
  );
}

SensorTab.contextTypes = {
  t: PropTypes.func.isRequired,
};

const sensorTabForm = reduxForm({
  form: 'sensor', // a unique name for this form
  enableReinitialize: true,
})(SensorTab);

export default sensorTabForm;
