import React from 'react';
import PropTypes from 'prop-types';

import CTableActions from '../../../containers/CTableActions/CTableActions';
import UIDateFormater from '../../../containers/UIhelper/UIDateFormater';
import { DateType } from '../../../containers/UIhelper/UIDateFormater';
import useProjectTimezone from '../../../hooks/useProjectTimeZone';
import './HistoryTab.scss';
import { useSelector } from 'react-redux';

function HistoryTab(props, context) {
  const { history, uiInfo, local, alertTypesOpitons, alertStatesOpitons, notify } = props;

  const projectTimezone = useProjectTimezone();


  const historyItemRender = (item, key) => {
    let changeNote;
    let userRole;

    // Change notice
    switch (item.ChangesType) {
      case "Comment":
        changeNote = (
          <span>
            <span className="value comment-value">
              {context.t('commented')} {item.Value}
            </span>
          </span>
        );
        break;

      case "Creation": {
        let creationComment;
        if (item.Value !== 'Manual') {
          let parsedValue;
          if (typeof item.Value === 'string') {
            try {
              parsedValue = JSON.parse(item.Value);
            } catch (e) {
              console.error("Failed to parse JSON:", e);
            }
          } else if (typeof item.Value === 'object') {
            parsedValue = item.Value;
          }
          const createdStatus = parsedValue == null ? "" : alertStatesOpitons.find(option => option.value == parsedValue.alertStatus);
          const createdType = parsedValue == null ? "" : alertTypesOpitons.find(option => option.value == parsedValue.alertType);

          creationComment = `Alerts Details: Status: ${createdStatus.DisplayName}, Type: ${createdType.DisplayName}`;
        }
        changeNote = (
          <span>
            <span className="value comment-value">
              {context.t('created_new_alert')}. {creationComment}
            </span>
          </span>
        );
        break;
      }
      case "Type": {
        const changedType = alertTypesOpitons == null ? "" : alertTypesOpitons.find(option => option.value == item.Value);
        changeNote = (
          <span>
            {context.t('changed')} <span className="type">{item?.ChangesType}</span> {context.t('to')} <span className="value">{changedType?.DisplayName}</span>
          </span>
        );
        break;
      }
      case "State": {
        const changedState = alertStatesOpitons == null ? "" : alertStatesOpitons.find(option => option.value == item.Value);
        changeNote = (
          <span>
            {context.t('changed')} <span className="type">{context.t('status')}</span> {context.t('to')} <span className="value">{changedState?.DisplayName}</span>
          </span>
        );
        break;
      }
      case "Mobile":
        changeNote = (
          <span>
            {context.t('update_from_aqs_mobile_details', { details: item.Value })}
          </span>
        );
        break;

      default:
        changeNote = (
          <span>
            {context.t('changed')} <span className="type">{item.ChangesType}</span> {context.t('to')} <span className="value">{item.Value}</span>
          </span>
        );
        break;
    }

    // User role
    if (item.isSupervisor) {
      userRole = (
        <span className="user-role user-super" title={context.t('supervisor_user')}>
          {context.t('s')}
        </span>
      );
    } else {
      userRole = (
        <span className="user-role user-normal" title={context.t('user')}>
          {context.t('u')}
        </span>
      );
    }

    return (
      <li key={key} className="history-item">
        {userRole}
        <span className="name">{item.UserName}</span>
        {changeNote}
        <span className="time">
          {UIDateFormater(item.TimeOfChange, DateType.DATE_AND_TIME, local, projectTimezone)}
        </span>
      </li>
    );
  };

  const pHeight = 0.436 * uiInfo.pageHeight;

  return (
    <div
      className="history-tab"
      style={{
        overflow: 'auto',
        maxHeight: `${pHeight}px`
      }}
    >
      <ul>
        {history.map(historyItemRender)}
      </ul>

      <CTableActions
        type="history"
        notify={notify}
      />
    </div>
  );
}

HistoryTab.contextTypes = {
  t: PropTypes.func.isRequired
};

export default HistoryTab;
