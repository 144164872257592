import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import { Table, Column, Cell } from 'fixed-data-table-2';
import UIDateFormater, {
  DateType,
} from '../../containers/UIhelper/UIDateFormater';
import ReactPaginate from 'react-paginate';
import { getProjectConfigurationsLogs } from '../../actions/DeviceConfigurationAction';
import { useDispatch } from 'react-redux';
import { SENSOR_LOG_COUNT_FOR_PAGE } from '../../constants/Misc';
import useProjectTimezone from '../../hooks/useProjectTimeZone';

const ConfigurationLogsModal = (props, context) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [uiInfo, setUiInfo] = React.useState({
    height: 0,
    width: 0,
  });
  const projectTimezone = useProjectTimezone();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const screen = window.screen;
    const uiInfo = {
      height: screen.height,
      width: screen.width,
    };
    setUiInfo(uiInfo);
  }, []);

  React.useEffect(() => {
    if (props.open) {
      dispatch(getProjectConfigurationsLogs(currentPage - 1));
    }
    return () => {
      if (!props.open) {
        setCurrentPage(1);
      }
    };
  }, [props.open, currentPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <AppModal
      closeIcon
      fullWidth
      maxWidth={false}
      open={props.open}
      onClose={() => {
        setCurrentPage(1);
        props.closeLogsModal();
      }}
      title={context.t('device_configuration_messages_logs')}
      content={
        <>
          <Table
            keyboardScrollEnabled
            keyboardPageEnabled
            maxHeight={uiInfo.height * 0.6}
            width={uiInfo.width * 0.9}
            rowsCount={props.data.length}
            rowHeight={100}
            headerHeight={30}
          >
            <Column
              columnKey='#'
              width={5}
              align='center'
              header={<Cell>#</Cell>}
              flexGrow={0.3}
              cell={(col) => <Cell>{col.rowIndex + 1}</Cell>}
            />

            <Column
              columnKey='Timestamp'
              width={30}
              header={<Cell>{context.t('time')}</Cell>}
              flexGrow={1}
              cell={(col) => {
                const val = props.data[col.rowIndex]['Timestamp'];
                const time = UIDateFormater(
                  val,
                  DateType.DATE_AND_TIME,
                  props.timeZone,
                  projectTimezone
                );
                return <Cell>{time}</Cell>;
              }}
            />

            <Column
              columnKey='DeviceID'
              width={30}
              header={<Cell>{context.t('device_id')}</Cell>}
              flexGrow={1}
              cell={(col) => (
                <Cell>{props.data[col.rowIndex]['DeviceID']}</Cell>
              )}
            />

            <Column
              columnKey='MessageSource'
              width={30}
              header={<Cell>{context.t('source')}</Cell>}
              flexGrow={1}
              cell={(col) => (
                <Cell>{props.data[col.rowIndex]['MessageSource']}</Cell>
              )}
            />

            <Column
              columnKey='MessageCodeText'
              width={30}
              header={<Cell>{context.t('code')}</Cell>}
              flexGrow={1}
              cell={(col) => (
                <Cell>{props.data[col.rowIndex]['MessageCodeText']}</Cell>
              )}
            />

            <Column
              columnKey='MessagePayload'
              width={30}
              header={<Cell>{context.t('payload')}</Cell>}
              flexGrow={4}
              align='left'
              cell={(col) => (
                <Cell title={props.data[col.rowIndex]['MessagePayload']}>
                  {props.data[col.rowIndex]['MessagePayload']}
                </Cell>
              )}
            />

            <Column
              columnKey='UserName'
              width={30}
              header={<Cell>{context.t('user')}</Cell>}
              flexGrow={1}
              align='left'
              cell={(col) => (
                <Cell title={props.data[col.rowIndex]['UserName']}>
                  {props.data[col.rowIndex]['UserName']}
                </Cell>
              )}
            />

            {/* <Column columnKey='Timestamp' headerLabel={context.t('Time')} renderFunc={(value) => UIDateFormater(value, DateType.DATE_AND_TIME, props.timeZone)} />
            <Column columnKey='DeviceID' headerLabel={context.t('Device ID')} />
            <Column columnKey='MessageSource' headerLabel={context.t('Source')} />
            <Column columnKey='MessageCodeText' headerLabel={context.t('Code')} />
            <Column columnKey='MessagePayload' headerLabel={context.t('Payload')} /> */}
          </Table>
          {/*<Pagination*/}
          {/*  prevPageText={context.t('prev')}*/}
          {/*  nextPageText={context.t('next')}*/}
          {/*  firstPageText={context.t('first')}*/}
          {/*  lastPageText={context.t('last')}*/}
          {/*  activePage={currentPage}*/}
          {/*  itemsCountPerPage={250}*/}
          {/*  totalItemsCount={props?.data[0]?.Total || 0}*/}
          {/*  pageRangeDisplayed={5}*/}
          {/*  onChange={handlePageClick}*/}
          {/*/>*/}
          <ReactPaginate
            previousLabel={context.t('prev')}
            nextLabel={context.t('next')}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(props?.data[0]?.Total || 0 / 250)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            onPageActive={currentPage}
          />
        </>
      }
    />
  );
};

ConfigurationLogsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ConfigurationLogsModal;
