import PropTypes from 'prop-types';
import React from 'react';

import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import Plot from 'react-plotly.js';

import { graphsBackgroundColor } from '../../constants/Misc';

/**
 *
 * @param {string} name trace name
 * @param {'normal' | 'bold'} type line width
 * @param {number} color line color
 * @returns trace option
 */
function createInitialTrace(name, type, color) {
  const markerSettings = type === 'normal'
    ? {
      size: 7,
      symbol: 'circle-open', // empty circle for dotted line
      color: 'rgba(255, 255, 255, 100)',
      line: { width: 1, color }
    }
    : {
      size: 7,
      symbol: 'circle', // filled circle for clean line
      color: 'rgba(255, 255, 255, 100)',
      line: { width: 1, color }
    };

  const lineWidth = type === 'normal' ? 1 : 2;
  const lineMode = type === 'normal' ? 'lines' : 'lines+markers';

  return {
    name,
    type: 'scatter',
    mode: lineMode,
    marker: markerSettings,
    x: [],
    y: [],
    line: { width: lineWidth, color },
    // Format: (date, value) followed by trace name.
    hovertemplate: '(%{x}, %{y}) ' + name + '<extra></extra>',
    hoverlabel: { bgcolor: color }
  };
}

function getShortDateString(dateMillis, timeZone) {
  const finalDateMillis = (timeZone !== 'local') ? dateMillis : (dateMillis - (new Date().getTimezoneOffset() * 60 * 1000));
  const date = new Date(finalDateMillis).toISOString();
  const dateSectionsArr = date.split('T')[0].split('-');
  const month = dateSectionsArr[1];
  const day = dateSectionsArr[2];
  return (`${day}/${month}`);
}

function SensorsNoiseGraph(props, context) {
  const [displayValue, setDisplayValue] = React.useState('all');

  const handleChange = (event) => {
    setDisplayValue(event.target.value);
  };

  const config = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true,
    modeBarButtonsToRemove: [
      'drawrect',
      'zoom2d',
      'zoomIn2d',
      'zoomOut2d',
      'resetScale2d',
      'select2d',
      'lasso2d',
      'toggleSpikelines',
      'resetViewMapbox',
      'toImage',
    ]
  };

  const layout = {
    paper_bgcolor: graphsBackgroundColor,
    showlegend: false,
    autosize: false,
    width: props.small ? 270 : 360,
    height: props.small ? 200 : 250,
    title: {
      text: props.title,
      font: { size: props.titleSize || 17 },
      x: 0.02,
      y: 0.97,
    },
    margin: {
      l: 50,
      r: 10,
      b: 40,
      t: 30,
    },
    hovermode: 'closest',
    yaxis: {
      rangemode: 'tozero',
      title: { text: props.titleY, font: { color: '#8d8d8d' } }
    },
    xaxis: {
      autorange: 'reversed',
      title: {
        text: props.titleX,
        font: { color: '#8d8d8d' },
      },
      tickfont: {
        size: 10
      },
      tickvals: [],
      ticktext: [],
    },
  };

  let data = [];

  if (props.historyNoiseData != null && props.historyNoiseData.length > 0) {
    const sensor1Nc1 = createInitialTrace('Sensor1 NC1', 'bold', 'blue');
    const sensor2Nc1 = createInitialTrace('Sensor2 NC1', 'bold', 'green');
    const sensor1Nc2 = createInitialTrace('Sensor1 NC2', 'normal', 'blue');
    const sensor2Nc2 = createInitialTrace('Sensor2 NC2', 'normal', 'green');

    const noiseData = props.historyNoiseData;

    noiseData.forEach((item, index) => {
      const xValue = index;
      const date = getShortDateString(Number(item[0]), props.timeZone);
      const s1Nc1 = item[1];
      const s2Nc1 = item[2];
      const s1Nc2 = item[3];
      const s2Nc2 = item[4];

      sensor1Nc1.y.push(s1Nc1);
      sensor2Nc1.y.push(s2Nc1);
      sensor1Nc2.y.push(s1Nc2);
      sensor2Nc2.y.push(s2Nc2);

      sensor1Nc1.x.push(xValue);
      sensor2Nc1.x.push(xValue);
      sensor1Nc2.x.push(xValue);
      sensor2Nc2.x.push(xValue);

      layout.xaxis.tickvals.push(xValue);
      layout.xaxis.ticktext.push(date);
    });

    switch (displayValue) {
      default:
      case 'all':
        data = [
          sensor1Nc2,
          sensor2Nc2,
          sensor1Nc1,
          sensor2Nc1,
        ];
        break;

      case 's1':
        data = [
          sensor1Nc2,
          sensor1Nc1,
        ];
        break;

      case 's2':
        data = [
          sensor2Nc1,
          sensor2Nc2,
        ];
        break;
    }
  }

  console.log('\x1b[33mNOISE GRAPH\x1b[0m', data);


  return (
    <div>
      <Plot
        config={config}
        layout={layout}
        data={data}
      />
      <div style={{
        margin: '0px auto',
        width: 'fit-content'
      }}>
        <FormControlLabel control={<Radio color="primary" onChange={handleChange} value="all" checked={displayValue === 'all'} />} label={context.t('all')} />
        <FormControlLabel control={<Radio color="primary" onChange={handleChange} value="s1" checked={displayValue === 's1'} />} label={context.t('sensor_1')} />
        <FormControlLabel control={<Radio color="primary" onChange={handleChange} value="s2" checked={displayValue === 's2'} />} label={context.t('sensor_2')} />
      </div>
    </div>
  );
}

SensorsNoiseGraph.contextTypes = {
  t: PropTypes.func.isRequired
};

SensorsNoiseGraph.propTypes = {
  historyNoiseData: PropTypes.array,
  small: PropTypes.bool,
  timeZone: PropTypes.string,
  title: PropTypes.string,
  titleSize: PropTypes.number,
  titleX: PropTypes.string,
  titleY: PropTypes.string,
};

export default SensorsNoiseGraph;
