/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ENV, ENVIRONMENTS } from '../../../constants/EndPoints';

import { Field, reduxForm } from 'redux-form';
import AppModal from '../../Modals/AppModal';

import ImagesTab from '../../AlertsTabs/ImagesTab/ImagesTab';
import Input from '../../Input/Input';
import AddressField from '../../Custom/AddressField';
import Select from '../../Select/Select';
import WorkOrderModal from '../../WorkOrderModal/WorkOrderModal';
import CommentField from '../../CommentField/CommentField';
import UIDateFormater from '../../../containers/UIhelper/UIDateFormater';
import { DateType } from '../../../containers/UIhelper/UIDateFormater';
import useProjectTimezone from '../../../hooks/useProjectTimeZone';
require('./DetailsTab.scss');

const X_Y_DIGITS = 4;

const DetailsTab = (props, context) => {

  const {
    selectedFeature,
    error,
    handleSubmit,
    pristine,
    reset,
    submitting,
    user,
    timeZone,
    onCreateNewValue,
    distanceFactor,
    serviceProviderOptions,
    workAreaOptions,
    repairOptions,
    openPCA,
    isFixedAlert,
    images,
    distributionListsOptions
  } = props;
  const initialValues = props.initialValues ? props.initialValues : {}; // Prevent 'undefined' errors.

  const [galleryOpen, setGalleryOpen] = useState(false);

  const projectTimeZone = useProjectTimezone();


  const onOpenPCAclick = () => {
    // if (props.isFixedAlert) {
    props.openPCA(props.selectedFeature);
    // }
  }

  const openActualLeakLocation = () => {
    props.openActualLeakLocation();
  }

  const openGallery = () => {
    const { images } = props;
    if (images != null && images.length > 0)
      setGalleryOpen(true);
  }

  return (
    <div className="form-horizontal sticky-actions">
      <form onSubmit={handleSubmit}>
        <CommentField
          fieldName="Comment"
          featureID={selectedFeature}
          placeholder={context.t('alert_comment')}
          onEdit={props.onEdit}
          onCopy={props.onCopy}
          disabled={!user.editMode}
        />
        <AddressField className="form-group" user={props.user} fieldName="StreetAddress" onGetAddress={props.getAddressByLatLong} />
        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('couple_id')}</label>
          <div className="col-xs-8 control-input">
            <Field name="CoupleID" component={Input} type="number" className="form-control" disabled />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('pipe_length2')}</label>
          <div className="col-xs-8 control-input">
            <Field
              className="form-control"
              component={Input}
              type="number"
              name="PathLengthM"
              distanceFactor={distanceFactor}
              disabled
            />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('repair_date')}</label>
          {/*@TODO handle date format of default value */}
          <div className="col-xs-8 control-input">
            <Field name="fixDate" component={Input} type="date" className="form-control" timeZone={timeZone} projectTimeZone={projectTimeZone} disabled={!user.editMode} />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('repair_details')}</label>
          <div className="col-xs-8 control-input">
            <Field name="RepairCode"
              disabled={!user.editMode}
              component={(props) =>
              (<Select {...props}
                key={"RepairCode"}
                clearable={false}
                placeholderTitle={context.t('select')}
                options={props.repairOptions}
              />)
              }
            />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('work_area')}</label>
          <div className="col-xs-8 control-input">
            <Field name="WorkArea"
              creatable
              disabled={!user.editMode}
              component={(props) =>
              (<Select {...props}
                key={"WorkArea"}
                placeholderTitle={context.t('select')}
                options={props.workAreaOptions}
                onCreateNewValue={onCreateNewValue}
              />)
              }
            />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('service_provider')}</label>
          <div className="col-xs-8 control-input">
            <Field name="ContractorID"
              creatable
              disabled={!user.editMode}
              component={(props) =>
              (<Select {...props}
                key={"ContractorID"}
                placeholderTitle={context.t('select')}
                options={serviceProviderOptions}
                onCreateNewValue={onCreateNewValue}
              />)
              }
            />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('sap_number')}</label>
          <div className="col-xs-8">
            <Field name="SAPNumber" component={Input} type="text" className="form-control" disabled={!user.editMode} />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('asset_id')}</label>
          <div className="col-xs-8">
            <Field name="KavMaim" component={Input} type="number" className="form-control" disabled={!user.editMode} />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('closure_date')}</label>
          <div className="col-xs-8 control-input">
            {/*<Field name="ClosureDate" component={Input} type="date" className="form-control" disabled={!user.editMode} />*/}
            <span className="control-readonly">{UIDateFormater(initialValues.ClosureDateTime, DateType.DATE, timeZone, projectTimeZone) || '-'}</span>
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('intensity')}</label>
          <div className="col-xs-8 control-input">
            <span className="control-readonly">{initialValues.Intensity || '1'}</span>
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('distance')}</label>
          <div className="col-xs-8 control-input">
            <span className="control-readonly">{(initialValues.Distance * distanceFactor).toFixed() || '-'}</span>
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('days_detected')}</label>
          <div className="col-xs-8 control-input">
            <span className="control-readonly">{initialValues.DaysDetected || '-'}</span>
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('x_y')}</label>
          <div className="col-xs-8 control-input">
            <span className="control-readonly">{(initialValues.X && initialValues.X.toFixed(X_Y_DIGITS)) || '-'}  ,  {(initialValues.Y && initialValues.Y.toFixed(X_Y_DIGITS)) || '-'}</span>
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('longitude_latitude')}</label>
          <div className="col-xs-8 control-input">
            <span className="control-readonly">{initialValues.Longitude || '-'}  ,  {initialValues.Latitude || '-'}</span>
          </div>
        </div>

        <div className="form-actions">
          {JSON.parse(process.env.REACT_APP_FILES) &&
            <i
              className="btn btn-success btn-order icon-gallery"
              disabled={images == null || images.length == 0}
              onClick={openGallery} />
          }

          <WorkOrderModal
            alertId={selectedFeature}
            sendNotification={props.sendNotification}
            distributionLists={distributionListsOptions}
            getMapCapture={props.getMapCapture}
          />
          <a
            className="btn btn-success btn-info"
            onClick={onOpenPCAclick}
          >
            {context.t('pipe_condition_details')}
          </a>

          <a
            className="btn btn-warning btn-order"
            onClick={openActualLeakLocation} >
            {context.t('set_actual_leak_position')}
          </a>

          <button
            className="btn btn-success btn-submit"
            disabled={pristine || submitting}
            type="submit">{context.t('save')}</button>
          <div className="form-feedback text-danger">
            {error}
          </div>
        </div>
      </form>

      {JSON.parse(process.env.REACT_APP_FILES) &&
        <AppModal
          closeIcon
          open={galleryOpen}
          onClose={() => setGalleryOpen(false)}
          content={
            <ImagesTab
              images={images}
            />
          }
        />
      }
    </div>
  );

}

const validate = (values) => {
  const errors = {};
  if (values.KavMaim > 2147483647) {
    errors.KavMaim = "Pipe ID cannot be longer than 9 digits";
  }
  return errors;
};

DetailsTab.contextTypes = {
  t: PropTypes.func.isRequired
};

// Decorate the form component
export default reduxForm({
  form: 'details', // a unique name for this form
  enableReinitialize: true,
  validate
})(DetailsTab);
