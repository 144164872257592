export const es = {
  Sensor_id: 'ID del sensor',
  X: '',
  a: 'A',
  ownership: 'Propiedad',
  account: 'Cuenta',
  active: 'Activo',
  add: 'Agregar',
  alert_classification: 'Clasificación de alerta',
  add_new_noise_alert: 'Agregar nueva alerta de ruido',
  add_new_task: 'Agregar nueva tarea',
  add_sensor: 'Agregar sensor',
  address: 'Dirección',
  adhoc_correlation: 'Correlación ad hoc',
  advance_alerts: 'Alertas avanzadas',
  advance_pipes: 'Tuberías avanzadas',
  advanced: 'Avanzado',
  agc_config: 'Configuración AGC',
  agreement: 'Acuerdo',
  alert: 'Alerta',
  alert_alertId: 'Alerta {alertId}',
  alert_comment: 'Comentario de alerta',
  alert_id: 'ID de alerta',
  a_valid_segment: 'Un segmento válido',
  above_under: 'Encima / Debajo',
  above: 'Encima',
  action: 'Acción',
  address_: 'Dirección: ',
  addresses_address1_address2: 'Direcciones: {address1}, {address2}',
  alert_date: 'Fecha de alerta: ',
  alert_id_filter: 'Filtro de ID de alerta',
  alert_id_id: 'ID de alerta {id}',
  alert_id_: 'ID de alerta: ',
  alert_id_id_: 'ID de alerta: {id}',
  id: 'ID',
  id: 'ID',
  id: 'ID',
  id: 'ID',
  id: 'ID',
  id: 'ID',
  id: 'ID',
  id: 'ID',
  alert_status: 'Estado de alerta',
  alert_type: 'Tipo de alerta',
  alert_alertid: 'Alerta {alertId}',
  alerts_options: 'Opciones de alertas',
  alerts: 'Alertas',
  allow_false_positive: 'Permitir falso positivo',
  allow_prod2_run: 'Permitir ejecución de Prod2',
  allowprod2run: 'PermitirEjecuciónProd2',
  analyzer: 'Analizador',
  apply_changes: 'Aplicar cambios',
  are_you_sure_you_want_to_remove_this_device: '¿Estás seguro de que deseas eliminar este dispositivo?',
  are_you_sure_you_want_to_uninstall_the_device_and_remove_this_sensor: '¿Estás seguro de que deseas desinstalar el dispositivo y eliminar este sensor?',
  are_you_sure_you_want_to_uninstall_the_device: '¿Estás seguro de que deseas desinstalar el dispositivo?',
  are_you_sure_you_want_to_remove_this_sensor: '¿Estás seguro de que deseas eliminar este sensor?',
  are_you_sure: '¿Estás seguro?',
  this_action_will_rerun_override: 'Esta acción sobrescribirá las muestras anteriores',
  confirmation_success: 'Aquí hay una confirmación suave de que tu acción fue exitosa',
  confirmation_error: 'Tu acción no fue exitosa',
  assessment_id_id: 'ID de evaluación: {id}',
  assetid_asset: 'ID de activo: {asset}',
  attenuation: 'Atenuación',
  recording_time_1: 'Hora de la primera grabación',
  recording_time_2: 'Hora de la segunda grabación',
  recording_time_3: 'Hora de la tercera grabación',
  authorization: 'Autorización',
  auto: 'Auto',
  autoclosedate: 'Fecha de cierre automático',
  azbest_cement: 'fibrocemento',
  alert_id_param: 'ID de alerta: {id}',
  alert_state: 'Estado de alerta',
  alert_statuses: 'Estados de alerta',
  all_boundaries_selected: 'Todos los límites seleccionados',
  analyze: 'analizar',
  application_services: 'Servicios de aplicación',
  archived: 'Archivado',
  are_you_sure_you_want_to_delete_this_type: '¿Estás seguro de que deseas eliminar este {type}?',
  asc: 'ASC',
  assembly: 'Ensamblaje',
  blank_space: ' ',
  asset_id: 'ID de activo',
  asset_id_param: 'ID de activo: {asset}',
  exported_to_google_sheet_successfully: 'Exportado a Google Sheet con éxito',
  open_google_sheet: 'Abrir Google Sheet',
  export_to_excel: 'Exportar a Excel',
  export_alerts_history: 'Exportar historial de alertas',
  export_noise_alerts_history: 'Exportar historial de alertas de ruido',
  sensors_maintenance: 'Mantenimiento de sensores',
  sensors_activity_report: 'Informe de actividad de sensores',
  sensors_technician_report: 'Informe de sensor - Técnico',
  sensors_admin_report: 'Informe de sensor - Administración',
  there_is_no_data_available: 'No hay datos disponibles',
  no_data_available: 'No hay datos disponibles',
  based_on_last_weeks_data: 'Basado en los datos de las últimas semanas',
  network_response_not_ok: 'La respuesta de la red no fue correcta',
  technician_report: 'Informe de técnicos',
  report_type: 'Tipo de informe',
  select_customer: 'Seleccionar cliente',
  rerun_historical_data: 'Reejecutar datos históricos',
  select_time_frame: 'Seleccionar período',
  time_source: 'Fuente de tiempo',
  valve_leak: 'Fuga de válvula',
  is_ultra: 'Ultra',
  rerun: 'Reejecutar',
  please_select: 'Por favor seleccione',
  export_shp_file: 'Exportar archivo SHP',
  export_alerts_to_excel: 'Exportar alertas a Excel',
  export_all_transients_to_excel: 'Exportar todos los transitorios a Excel',
  hello_username: 'Hola {userName}',
  about: 'Acerca de',
  help: 'Ayuda',
  logout: 'Cerrar sesión',
  licneces: 'Licencias',
  local: 'Local',
  gmt: 'GMT',
  last_correlation: 'Última correlación',
  last_spectrogram: 'Último espectrograma',
  radio_quality: 'Radio [dt: {dt}, calidad: {quality}]',
  please_enter_your_new_password_below: 'Por favor ingrese su nueva contraseña a continuación',
  enter_new_password_again: 'Ingrese la nueva contraseña nuevamente',
  meter: 'm',
  fit: 'pi',
  not_sensitive: 'No sensible',
  is_emi: 'Es EMI',
  is_saturation: 'Está en saturación',
  is_delayed_wakeup: 'Es despertar retrasado',
  iq_100b_reports: 'Informes IQ100B',
  is_white_noise: 'Es ruido blanco',
  is_overlapping: 'Es superposición',
  audio: 'Audio',
  device_installation_date: 'Fecha de instalación del dispositivo {deviceInstallationDate}',
  device_generation: 'Generación de dispositivos',
  audio_clean: 'Audio limpio',
  sync_method: 'Método de sincronización',
  average: 'promedio',
  avg_max_correlation: 'Correlación máxima promedio',
  avg_max_spectrogram: 'Espectrograma máximo promedio',
  bit_information: 'Información BIT',
  bit_type: 'Tipo BIT',
  back: 'Atrás',
  bad_status: 'Estado malo',
  battery_voltage_post: 'Voltaje de la batería después',
  battery_voltage_pre: 'Voltaje de la batería antes',
  battery: 'Batería',
  bounding: 'Delimitación',
  csq: 'CSQ',
  cannot_find_your_search: 'No se puede encontrar tu búsqueda',
  cast_iron: 'Hierro fundido',
  change_statename_of_leak_id_from_prevstate_to_nextstate: 'Cambiar {stateName} de la fuga {id} de {prevState} a {nextState}',
  change_statename_of_alert_type_from_prevstate_to_nextstate: 'Cambiar {stateName} de {alertType} {id} de {prevState} a {nextState}',
  circle: 'Círculo',
  city: 'Ciudad',
  click_to_set_current_map_position_as_project_center: 'Haga clic para establecer la posición actual del mapa como centro del proyecto',
  comm_ratio: 'Relación de comunicación',
  comment: 'Comentario',
  comment_date: 'Fecha del comentario',
  communication_problem: 'Problema de comunicación',
  compression_enabled: 'Compresión habilitada',
  compression: 'Compresión',
  b: 'B',
  back_to_home_view: 'Volver a la vista de inicio',
  back_to_qalertsq: 'Volver a alertas',
  backfill: 'Relleno',
  backfill_comment: 'Comentario de relleno',
  bedding_comment: 'Comentario de cama',
  bedding_type:  'Tipo de lecho',
  brass: 'Latón',
  config_record_duration: 'Configurar el tiempo de grabación',
  config_status: 'Estado de configuración',
  consumption_time_: 'Hora de consumo: ',
  consumtion: 'Consumo',
  consumtion_: 'Consumo: ',
  contact_email: 'Correo electrónico de contacto',
  contact_person: 'Persona de contacto',
  contact_phone_1: 'Teléfono de contacto 1',
  contact_phone_2: 'Teléfono de contacto 2',
  correlation_audio: 'Audio de correlación',
  correlation_radio: 'Radio de correlación',
  couple_coverage: 'Cobertura de pareja',
  couple_deleted_successfuly: 'Pareja eliminada con éxito',
  couple_id_: 'ID de pareja',
  couple_id_coupleid_material_count_alerts: 'ID de pareja {CoupleID}, Material: {material}, {Count} alertas',
  couple_id_id: 'ID de pareja: {id}',
  couple_path: 'Ruta de pareja:',
  couple_coupleid: 'Pareja {coupleId}',
  couple_id_id_: 'Pareja {id}',
  couples_days_back_for_only_updated: 'Días de pareja atrás solo para actualizados',
  create_couple: 'Crear pareja',
  create_task: 'Crear tarea',
  create_new_customer: 'Crear nuevo cliente',
  create_new_project: 'Crear nuevo proyecto',
  create_new_sop: 'Crear nuevo SOP',
  create_new_user: 'Crear nuevo usuario',
  create_new_area_of_interest: 'Crear nueva área de interés',
  creation_time: 'Hora de creación',
  customer_id_: 'ID de cliente: ',
  customer_name: 'Nombre del cliente',
  customer_customer: 'Cliente: {customer}',
  customer_name_: 'Cliente: {name}',
  customers: 'Clientes',
  cancel: 'Cancelar',
  yes: 'Sí',
  changed: 'Cambiado',
  choose_location_for_new_installation_point: 'Elija la ubicación para el nuevo punto de instalación',
  claims: 'Reclamaciones',
  clarity: 'Claridad:',
  english: 'Inglés',
  hebrew: 'Hebreo',
  layer_manager: 'Administrador de capas',
  satellite_view: 'Vista satélite',
  show_alerts: 'Mostrar alertas',
  minimal_noise_intensity: 'Intensidad mínima de ruido',
  filtered_noise_intensity: 'Intensidad de ruido filtrado',
  show_mobile_samples: 'Mostrar muestras móviles',
  show_mobile_alerts: 'Mostrar alertas móviles',
  show_sensors: 'Mostrar sensores',
  show_installation_points: 'Mostrar puntos de instalación',
  show_areas_of_interest: 'Mostrar áreas de interés',
  pipes_by_material: 'Tuberías por material',
  pipes_by_diameter: 'Tuberías por diámetro',
  pipes_by_pressure: 'Tuberías por presión',
  pipes_by_placement_year: 'Tuberías por año de colocación',
  couples_coverage: 'Cobertura de parejas',
  show_couples_path: 'Mostrar ruta de parejas',
  project_boundaries: 'Límites del proyecto',
  show_water_meter_layer: 'Mostrar capa de medidor de agua',
  project_coverage: 'Cobertura del proyecto',
  email: 'Correo electrónico',
  password: 'Contraseña',
  forgot_password: 'Olvidé mi contraseña',
  login: 'Iniciar sesión',
  manual_reset_at: 'Restablecimiento manual en {lastManualResetDate}',
  manual_reset_count: 'Conteo de restablecimiento manual {manualResetCount}',
  auto_reset_at: 'Restablecimiento automático en {lastAutoResetDate}',
  auto_reset_count: 'Conteo de restablecimiento automático {autoResetCount}',
  sensors_activity: 'Actividad de sensores',
  select_month: 'Seleccionar mes',
  boundary: 'Límite',
  include_archived_sensors: 'Incluir sensores archivados',
  all: 'Todos',
  sensor_1: 'Sensor 1',
  sensor_2: 'Sensor 2',
  device: 'Dispositivo',
  pressure: 'Presión',
  g5_status: 'Estado G5',
  classification: 'Clasificación',
  clear_selection: 'Borrar selección',
  click_on_map_to_set_coordinate: 'Haga clic en el mapa para establecer coordenadas',
  complete_alert_details_and_save: 'Por favor, rellene los detalles de la alerta y guarde',
  click_to_download: 'Haga clic para descargar',
  close: 'Cerrada',
  close_noise_alert: 'Cerrar alerta de ruido',
  close_valve_alert: 'Cerrar alerta de válvula',
  closure_date: 'Fecha de cierre:',
  code: 'Código',
  commands: 'Comandos',
  commented: 'Comentó: ',
  comments: 'Comentarios',
  communications_service: 'Servicio de comunicaciones',
  compaction: 'Compactación',
  compliance_with_laws: 'Cumplimiento de leyes',
  concrete: 'Hormigón',
  config: 'Configuración',
  config_duration: 'Duración de configuración',
  configuration: 'Configuración',
  configure: 'Configurar',
  consent_to: 'Consentimiento a',
  consent_to_use_of_data: 'Consentimiento para el uso de datos',
  consumption: 'Consumo:',
  consumption_time: 'Tiempo de consumo:',
  contact_details: 'Detalles de contacto',
  content: 'Contenido',
  contrast: 'Contraste',
  control_buttons: 'Botones de control',
  coordinate: 'Coordenadas',
  copper: 'Cobre',
  copyright_2021_aquarius_spectrumbrbr_all_rights_re: 'Copyright © 2021 Aquarius Spectrum Todos los derechos reservados. Aquarius Spectrum, Greenwork Yakum, Edificio A 1er Piso, Yakum, 6097200 Israel Teléfono: +972-74-7136644 Fax: +972-4-6709014',
  corr_interval: 'Intervalo de correlación',
  corr_num_samples: 'Número de muestras de correlación',
  correlation: 'Correlación',
  corrosion_type: 'Tipo de corrosión',
  cost: 'Costo:',
  count: 'Contar',
  couple_and_id: 'Pareja {id}:',
  couple_assessment_report: 'Informe de evaluación de pareja',
  couple_id: 'ID de pareja',
  couple_id2: 'Pareja {coupleId}:',
  couple_length: 'Longitud de pareja',
  couple_comment: 'Comentario de pareja',
  couples: 'Parejas',
  couples_options: 'Opciones de parejas',
  crack_diameter_inches: 'Diámetro de grieta (pulgadas)',
  create: 'Crear',
  create_a_new_noise_alert: 'Crear una nueva alerta de ruido',
  create_a_new_valve_alert: 'Crear una nueva alerta de válvula',
  create_date: 'Fecha de creación',
  create_new_installation_point: 'Crear nuevo punto de instalación',
  created_new_alert: 'Nueva alerta creada',
  current_device_id: 'ID de dispositivo actual',
  current_status: 'Estado actual',
  customer: 'Cliente',
  customer_account: 'Cuenta del cliente',
  customer_customerParam: 'Cliente: {customer}',
  flow_param: 'Flujo: {flow}',
  from_date_date: 'Desde la fecha: {date}',
  amplitude: 'Amplitud',
  file: 'Archivo',
  min_rms: 'RMS mínimo',
  catm_flags: 'catm_flags',
  hw_v: 'HW V',
  classify: 'clasificar',
  min: 'mín',
  nc_3: 'nc_3',
  threshold: 'umbral',
  contact_name: 'Nombre de contacto',
  hi: 'hola',
  assambly: 'ensamblaje',
  g5_intensity: 'Intensidad G5',
  comm_messages: 'Mensajes de comunicación',
  installation_point: 'Punto de instalación',
  bit_samples: 'Muestras de bit',
  bit_messages: 'Mensajes de bit',
  bit: 'Bit',
  gain: 'Ganancia',
  download: 'Descargar',
  seconds: 'Segundos',
  freq: 'Frecuencia',
  noise: 'Ruido',
  low: 'Bajo',
  radio_clean: 'Radio limpio',
  power: 'Poder',
  radio_frequency_data: 'Datos de frecuencia de radio',
  noise_intensity_min_band_power: 'Potencia de banda de intensidad de ruido mínima',
  audio_data_size: 'Tamaño de datos de audio',
  request_time: 'Tiempo de solicitud',
  response_time: 'Tiempo de respuesta',
  selected_fm_channel: 'Canal FM seleccionado',
  tp_avg: 'TP promedio',
  tp_rms: 'TP RMS',
  std_agc: 'AGC estándar',
  std_agc_2: 'AGC estándar 2',
  tp_rms_2: 'TP RMS 2',
  original: 'Original',
  audio_gain: 'Ganancia de audio',
  imsi: 'IMSI',
  batt: 'Batería',
  acc_tech: 'Tecnología ACC',
  tech: 'Tech',
  nbiot_flags: 'nbiot_flags',
  nbiot_tx_tout: 'nbiot_tx_tout',
  nbiot_rx_tout: 'nbiot_rx_tout',
  nbiot_tx_power: 'nbiot_tx_power',
  nbiot_tx_gain: 'nbiot_tx_gain',
  nbiot_packet: 'nbiot_packet',
  catm_packet: 'catm_packet',
  catm_rx_tout: 'catm_rx_tout',
  catm_tx_tout: 'catm_tx_tout',
  rsrq_db: 'RSRQ [db]',
  rsrp_db: 'RSRP [db]',
  hw_rev: 'HW Rev.',
  nb: 'NB',
  unknown: 'Desconocido',
  cat_m: 'Cat-M',
  fw_rev: 'HF Rev.',
  fw_v: 'FW V.',
  rst: 'RST',
  orig_2: 'Orig 2',
  technology: 'Tecnología',
  cell_id: 'ID de celda',
  med: 'Med',
  high: 'Alto',
  customer_id: 'ID del cliente:',
  customer_indemnification: 'Indemnización del cliente',
  dmas_layer: 'Capa DMA',
  dates: 'Fechas',
  days_correlation_incative: 'Días de correlación inactiva',
  days_inactive: 'Días inactivos',
  delete_failed: 'Eliminación fallida',
  device_id_: 'ID del dispositivo: ',
  device_id_device: 'ID del dispositivo: {device}',
  device_installation: 'Instalación del dispositivo',
  device_already_installed_in_project_project: 'El dispositivo ya está instalado en el proyecto {project}',
  diameter_placement_year: 'Diámetro / Año de colocación',
  dist_m: 'Dist[m]',
  do_not_take_0_samples_in_average: 'No tomar 0 muestras en promedio',
  done: 'Hecho',
  download_audio: 'Descargar audio',
  download_log_file: 'Descargar archivo de registro',
  draw_mode: 'Modo de dibujo',
  dual_signal: 'Señal dual',
  d: 'D',
  dashboard: 'Pantalla Principal',
  date: 'Fecha',
  day: 'Día',
  days_detected: 'Días detectados:',
  delete: 'Eliminar',
  delete_type_id: 'Eliminar {type} {id}',
  deletion_of_customer_data: 'Supresión de datos del cliente',
  deletion_of_data: 'Supresión de datos',
  desc: 'DESC',
  design_operating_press: 'Presión de operación de diseño',
  details: 'Detalles',
  detected: 'Detectado',
  device_configuration_messages_logs: 'Registros de mensajes de configuración del dispositivo',
  device_id: 'ID del dispositivo',
  device_id_val: 'Dispositivo {id}',
  mobile_alert_id_val: 'Alerta móvil {id}',
  frequency_hz: 'Frecuencia: {freq} [Hz]',
  duration_sec: 'Duración: [seg]',
  access_point: 'Punto de acceso',
  fill_addresses: 'Llenar direcciones',
  task_samples: 'Muestras de tarea',
  samples_count: 'Conteo de muestras',
  creation_date_default: 'Fecha de creación (predeterminada)',
  tasks: 'Tareas',
  tasks_options: 'Opciones de tareas',
  tasks_filters: 'Filtros de tareas',
  alerts_filters: 'Filtros de alertas',
  creation_date: 'Fecha de creación',
  survey: 'Encuesta',
  devices: 'Dispositivos',
  clean: 'Limpio',
  raw: 'Bruto',
  low_intensity: 'Baja intensidad',
  user_name: 'Nombre de usuario',
  duration: 'Duración',
  intensity_raw: 'Intensidad [crudo]',
  intensity_db: 'Intensidad:[dB]',
  intensity: 'Intensidad',
  battery_level: 'Nivel de batería',
  terms_and_conditions: 'Términos y condiciones',
  battery_post: 'Batería después',
  fw_version: 'Versión FW',
  hw_version: 'Versión HW',
  rsrq: 'RSRQ',
  rsrp: 'RSRP',
  rssi: 'RSSI',
  snr: 'SNR',
  noise_gain: 'Ganancia de ruido',
  noise_min_rms: 'RMS mínimo de ruido',
  normalize_noise: 'Normalizar ruido',
  by_clicking_login_you_agree_to_our: 'Al hacer clic en Iniciar sesión, aceptas nuestros',
  fm_radio_channel: 'Canal de radio FM',
  and_our: 'y nuestros',
  get_audio_config: 'Obtener configuración de audio',
  get_time_zone: 'Obtener zona horaria',
  get_lte_mode: 'Obtener modo LTE',
  get_server: 'Obtener servidor',
  get_fm_channel: 'Obtener canal FM',
  start_fm_scan: 'Iniciar escaneo FM',
  get_apn: 'Obtener APN',
  get_valve_leak: 'Obtener fuga de válvula',
  get_agc_conf: 'Obtener configuración AGC',
  get_fallback_server: 'Obtener servidor de respaldo',
  get_capability: 'Obtener capacidad',
  get_lte_configuration: 'Obtener configuración LTE',
  bit_request: 'Solicitud BIT',
  activate_toolkit: 'Activar kit de herramientas',
  you_are_about_to_take_a_significant_action: 'Estás a punto de tomar una acción significativa',
  sure_you_want_to_deactivate_sensors: '¿Seguro que quieres desactivar los sensores?',
  privecy_policy: 'Política de privacidad',
  confirm_updating_devices_server: 'Confirmar actualización del servidor de dispositivos',
  confirm_updating_devices_as_version: 'Confirmar actualización de dispositivos a la versión {version}',
  get_scheduler: 'Obtener programador',
  discard: 'Descartar',
  edit: 'Editar',
  deactivate: 'Desactivar',
  no_leak: 'Sin fuga',
  suspected_fast_developing_leak: 'Fuga en desarrollo rápido sospechada',
  suspected_leak: 'Fuga sospechada',
  uncertain: 'Incierto',
  undefined: 'Indefinido',
  hydrant: 'Hidrante',
  couple_intensity: 'Couple Intensity',
  sample_intensity: 'Intensidad de muestra',
  weighted_intensity: 'Intensidad ponderada',
  low_rm: 'RM bajo: [{rm}]',
  prob: 'Prob',
  show_in_5g: 'Mostrar en 5G',
  draw_sensors_selection_area: 'Dibujar área de selección de sensores',
  sensors_selected: '{num} Sensores seleccionados',
  last_sample: 'Última muestra:',
  couple_length_units: 'Longitud de pareja: {coupleLength}[{units}]',
  diameter_or_placement_year: 'Diámetro / Año de colocación',
  disclaimer: 'Descargo de responsabilidad',
  distance: 'Distancia:',
  distance_between_sensors: 'Distancia entre sensores:',
  distance_calculation: 'Cálculo de distancia:',
  distance_from_sensor1: 'Distancia desde Sensor1:',
  distances: 'Distancias',
  diameter_inch: 'Diámetro en pulgadas',
  distribution_list: 'Lista de distribución:',
  download_failed_file_not_found: 'Descarga fallida - archivo no encontrado',
  download_sensor_audio: 'Descargar audio del sensor',
  ductile_iron: 'Fundición dúctil',
  end_user: 'USUARIO FINAL',
  enter_an_mfa_code_to_complete_signin: 'Ingrese un código MFA para completar el inicio de sesión.',
  expand_row: 'desarrollar la línea',
  export2: 'Exportar',
  export_the_view_to_PDF: 'Exportar la vista a PDF',
  epsg: 'EPSG',
  edit_distributorname_projects: 'Editar proyectos de {distributorName}:',
  end_frequency: 'Frecuencia final',
  end_frequency_hz: 'Frecuencia final (Hz)',
  end_frequncy: 'Frecuencia final',
  end_record_time: 'Hora de finalización de la grabación',
  end_time: 'Hora de finalización',
  estimated_address: 'Dirección estimada',
  event_cause: 'Causa del evento',
  event_date: 'Fecha del evento',
  event_information: 'Información del evento',
  event_max_value: 'Valor máximo del evento',
  event_min_value: 'Valor mínimo del evento',
  event_nature: 'Naturaleza del evento',
  exclude_sample_1_by_4_flag: 'Excluir muestra 1 por 4 bandera',
  exclude_sample_1_by_4_number: 'Excluir muestra 1 por 4 número',
  exclude_sample_1_by_4_radius: 'Excluir muestra 1 por 4 radio',
  export_all_projects_to_excel: 'Exportar todos los proyectos a Excel',
  iq100b_reports: 'Informes IQ100B',
  export_the_view_to_pdf: 'Exportar la vista a PDF',
  export: 'Exportar',
  ftpend_send_time: 'Hora de finalización de envío FTP',
  file_size: 'Tamaño del archivo',
  fading_alert: 'Alerta desvaneciendo',
  false_path: 'Camino falso',
  fast_development: 'Desarrollo rápido',
  file_name: 'Nombre del archivo',
  filtered_avg: 'Promedio filtrado',
  filtered_intensity_avg: 'Promedio de intensidad filtrada',
  filtered_noise: 'Ruido filtrado',
  filters_: 'Filtros:',
  firmware_version: 'Versión de firmware',
  first_name: 'Nombre',
  fixed_leaks: 'Fugas fijas',
  flow_flow: 'Flujo: {flow}',
  fmax: 'Fmax',
  fragmented_file: 'Archivo fragmentado',
  frequency_histogram: 'Histograma de frecuencia',
  failed: 'Fallido',
  fees: 'Tarifas',
  files: 'Archivos',
  filter: 'Filtro',
  filters: 'Filtros',
  first_installation_date: 'Fecha de primera instalación',
  fm2_receiver: 'Receptor FM2',
  fm_receiver: 'Receptor FM',
  fmin: 'fmin',
  frequency: 'Frecuencia',
  from: 'De:',
  from_to: 'De - A',
  frost_depth: 'Profundidad de escarcha',
  fs: 'fs',
  go: 'IR',
  gps_ratio: 'Proporción de GPS',
  generate: 'Generar',
  good_status: 'Buen estado',
  fast_growing_alert: 'Alerta de crecimiento rápido',
  slow_growing_alert: 'Alerta de crecimiento lento',
  guidelines_: 'Directrices:',
  hardware_version: 'Versión de hardware',
  high_severity_more_than_4_leaks: 'Alta gravedad (más de 4 fugas)',
  high_frq: 'Alta frecuencia',
  mid_frq: 'Frecuencia media',
  low_frq: 'Baja frecuencia',
  inactive: 'Inactivo',
  index_band_to_send: 'Índice de banda a enviar',
  index_: 'Índice: ',
  infrastructure: 'Infraestructura',
  install_date: 'Fecha de instalación',
  installation_point_comment: 'Comentario del punto de instalación',
  intensity_not_filtered: 'Intensidad (no filtrada)',
  intensity_: 'Intensidad: ',
  intensity_intensity: 'Intensidad: {intensity}',
  area_of_interest_comment: 'Comentario del área de interés',
  area_of_interest_id_id: 'Área de interés {id}',
  area_of_interest_type: 'Tipo de área de interés',
  area_of_interest: 'Área de interés',
  areas_of_interest: 'Áreas de interés',
  edit_area_of_interest: 'Editar área de interés',
  is_csq_ok: 'CSQ OK',
  is_display_city_column: '¿Mostrar columna de ciudad?',
  is_elecrolysis: '¿Es electrólisis?',
  is_modem_closed_ok: '¿Está bien cerrado el módem?',
  is_send_file_ok: '¿Está bien enviado el archivo?',
  is_ok: '¿Está bien?',
  log: 'LOG',
  last_event_date: 'Fecha del último evento',
  last_name: 'Apellido',
  last_run_days: 'Última ejecución (días)',
  last_sample_: 'Última muestra: ',
  last_uninstall_reason: 'Última razón de desinstalación',
  lasteventfrequency: 'Frecuencia del último evento',
  leak_per_units: 'Fuga por {units}',
  leakgrowth: 'Crecimiento de fuga',
  leakperkm: 'Fuga por km',
  leaks_infrastructure: 'Fugas / Infraestructura',
  leaks_by_diameter: 'Fugas por diámetro',
  leaks_by_materials: 'Fugas por materiales',
  leaks_by_years: 'Fugas por años',
  leaks: 'Fugas',
  legend: 'Leyenda',
  linked_couples: 'Parejas vinculadas',
  list_name: 'Nombre de la lista',
  log_information_device_deviceid: 'Información de registro. Dispositivo: {deviceId}',
  low_severity_1_leak: 'Baja gravedad (1 fuga)',
  low_severity_with_fast_development_alert: 'Baja gravedad con alerta de desarrollo rápido',
  mail: 'Correo',
  main: 'Principal',
  manage_distributor: 'Gestionar distribuidor',
  manual: 'Manual',
  map_alerts: 'Alertas de mapa',
  map_pipes_diameter_type: 'Diámetro de tuberías en el mapa',
  material_diameter_placement_year: 'Material y diámetro / Año de colocación',
  material_placement_year: 'Material / Año de colocación',
  material_name: 'Nombre del material',
  medium_severity_2_4_leaks: 'Gravedad media (2 - 4 fugas)',
  meteric_feet_epsg_only: 'Métrico / Pies solo EPSG',
  min_nc1_days: 'NC1 min días',
  min_noise: 'Ruido mínimo',
  min_rms_nc1: 'RMS min NC1',
  minimal_intensity: 'Intensidad mínima',
  minimal_noise_sampling_taken_by_the_sensor_prior_to_audio_recording: 'Muestreo de ruido mínimo tomado por el sensor antes de la grabación de audio',
  modem_atenna_signal_strength: 'Fuerza de señal de antena del módem',
  modem_usage: 'Uso del módem',
  more_samples: 'Más muestras',
  most_problematic_pipes_based_on_leaks_count: 'Las tuberías más problemáticas según el recuento de fugas',
  most_problematic_pipes_based_on_leaks_per_units: 'Tuberías más problemáticas según las fugas por {units}',
  multi_diameter: 'Multidiámetro',
  multi_material: 'Multimaterial',
  nc3_std_mean_ratio: 'Relación media Std NC3',
  nc3_proj_mean: 'Promedio del proyecto NC3',
  name_: 'Nombre: ',
  new_customer: 'Nuevo cliente',
  new_list_name: 'Nuevo nombre de lista',
  new_project: 'Nuevo proyecto',
  no_leaks: 'Sin fugas',
  nois_eaudio_duration_ms: 'Duración de audio de ruido (ms)',
  noise_alert_const_prob_thr: 'Umbral de probabilidad constante de alerta de ruido',
  noise_alert_step_prob_thr: 'Umbral de probabilidad de paso de alerta de ruido',
  noise_audio_fs: 'FS de audio de ruido',
  noise_audio_gain: 'Ganancia de audio de ruido',
  noise_intensity_gain: 'Ganancia de intensidad de ruido',
  noise_ok_threshold: 'Umbral de ruido OK',
  noise_sample: 'Muestra de ruido',
  noise_samples: 'Muestras de ruido',
  noise_sync: 'Sincronización de ruido',
  noise_level_: 'Nivel de ruido',
  noise_sampling_taken_by_the_sensor_during_audio_recording: 'Muestreo de ruido tomado por el sensor durante la grabación de audio',
  noise_value_val: 'Valor de ruido: {val}',
  non_associated: 'No asociado',
  not_active: 'Inactivo',
  not_installed: 'No instalado',
  num_coefficients: 'Número de coeficientes',
  num_frequency_bands: 'Número de bandas de frecuencia',
  numbadburstsamples_thr: 'Umbral de muestras de ráfagas defectuosas',
  number_of_transients: 'Número de transitorios',
  open_assessment: 'Abrir evaluación',
  open_new_leak_on_0_sample: 'Abrir nueva fuga en 0 muestra',
  open_projects: 'Proyectos abiertos',
  open_reports: 'Abrir informes AQS',
  open: 'Aperta',
  overground_acc_gps: 'ACC sobre superficie GPS',
  overground_acc_rf: 'ACC sobre superficie RF ',
  overground_hyd_gps: 'HYD sobre superficie GPS',
  overground_hyd_rf: 'HYD sobre superficie RF',
  phone: 'Teléfono',
  pipe_accuracy: 'Precisión de la tubería',
  pipe_assessment_report: 'Informe de evaluación de tuberías',
  pipe_assessment: 'Evaluación de tuberías',
  pipe_assessments_report_projectname_epsg: 'Informe de evaluaciones de tuberías. {projectName} [EPSG {epsg}]',
  pipe_assessments: 'Evaluaciones de tuberías',
  pipe_condition_analyzer: 'Analizador de condición de tuberías',
  pipe_length_: 'Longitud de la tubería',
  pipe_: 'Tubería: ',
  pipes_by_assessment: 'Tuberías por evaluación',
  pipes_by_presure: 'Tuberías por presión',
  pipes_high_severity_analysis: 'Análisis de alta gravedad de tuberías:',
  pipes_by_placement_year_: 'Tuberías por año de colocación',
  pipes_by_diameter_: 'Tuberías por diámetro',
  pipes_by_materials: 'Tuberías por materiales',
  pipes_by_presure_: 'Tuberías por presión',
  pipes_severity_score: 'Puntuación de gravedad de tuberías',
  pipes: 'Tuberías',
  placement_year: 'Año de colocación',
  polyethelen: 'Polietileno',
  polygon: 'Polígono',
  poor_condition_pipe: 'Tubería en mal estado',
  position_: 'Posición: ',
  possible_pumps: 'Posibles bombas',
  pump: 'Bomba',
  prv: 'PRV',
  washout: 'Desague',
  watering: 'Riego',
  fountain: 'Fuente',
  possible_ufr: 'Posible UFR',
  pressure_value_coefficient: 'Coeficiente de valor de presión',
  pressure_y_value_coefficient: 'Coeficiente de valor Y de presión',
  print: 'Imprimir',
  prob_irrig: 'Prob Irrig',
  prob4_burst: 'Prob4 ráfaga',
  prob4_consumption: 'Prob4 consumo',
  processing: 'Procesamiento',
  profiler: 'Perfilador',
  proj_int_factor: 'Factor de intensidad del proyecto',
  proj_int_len_factor: 'Factor de duración de la intensidad del proyecto',
  project_name: 'Nombre del proyecto',
  project_name_: 'Proyecto: {name}',
  projects_coverage: 'Cobertura de proyectos',
  projects: 'Proyectos',
  quiality: 'Calidad',
  g5_devices: 'Dispositivos G5',
  g5_devices_table: 'tabla de dispositivos g5',
  g5_logs_table: 'tabla de registros g5',
  general: 'Generalidades',
  general_description: 'Descripción general',
  general_information: 'Información general',
  general_provisions: 'Disposiciones generales',
  generate_report: 'Generar informe',
  generation: 'Generación',
  get_project_defaults: 'Obtener valores predeterminados del proyecto',
  grant_of_rights: 'Concesión de derechos',
  graph: 'Gráfico',
  ground_type: 'Tipo de suelo',
  guidelines: 'Directrices:',
  hidden_div_for_generate_plotly_image: 'DIV OCULTO PARA GENERAR IMAGEN PLOTLY',
  historical_information: 'Información histórica',
  history: 'Historia',
  id_default: 'ID (predeterminado)',
  images: 'Imágenes',
  img: 'img',
  in_these_tos: 'en estos TCG',
  include_map: 'Incluir mapa',
  indemnification: 'Indemnización',
  indemnity: 'Indemnidad',
  index: 'Índice',
  install: 'Instalar',
  install_device: 'Instalar dispositivo',
  installation_point_id: 'ID del punto de instalación {id}',
  installation_point_id_2: 'ID del punto de instalación',
  installation_points: 'Puntos de instalación',
  instance_distances: 'distancias de instancia',
  instance_dts: 'dTs de instancia',
  intellectual_property_rights: 'Derechos de propiedad intelectual',
  intensities: 'Intensidades',
  diameter: 'Diámetro',
  intensity_param: 'Intensidad: {intensity}',
  add_alert: 'Agregar alerta',
  create_alert: 'Crear alerta',
  audio_alert_3: 'Alerta de audio 3',
  audio_base_1: 'Base de audio 1',
  audio_base_2: 'Base de audio 2',
  audio_sample: 'Muestra de audio',
  audio_sample_clean: 'Muestra de audio-limpia',
  audio_base_1_clean: 'Base de audio 1-limpia',
  audio_base_2_clean: 'Base de audio 2-limpia',
  audio_alert_3_clean: 'Alerta de audio 3-limpia',
  time_stamp: 'Marca de tiempo',
  alert_3: 'Alerta 3',
  base_1: 'Base 1',
  base_2: 'Base 2',
  event_rms: 'Evento RMS',
  base_mean: 'Media de la base',
  base_std: 'Desviación típica básica',
  samples_mean: 'Media de las muestras',
  samples_std: 'Desviación típica de las muestras',
  event_gain: 'Ganancia del evento',
  samples_gain: 'Ganancia de las muestras',
  base_magnitude: 'Magnitud de la base',
  samples_magnitude: 'Magnitud de las muestras',
  factor_baseline_rms: 'Factor RMS',
  alert_samples: 'Muestras de alerta',
  sensor_history: 'Historial del sensor',
  sample_distance: 'Distancia de la muestra',
  weighted_distance: 'Distancia ponderada',
  peak_0: 'Pico 0 [{ndx}, {maxProb}]',
  peak_1: 'Pico 1 [{ndx}, {maxProb}]',
  peak_2: 'Pico 2 [{ndx}, {maxProb}]',
  send_scheduler_message: 'Enviar mensaje del programador',
  type_param: 'Tipo: {type}',
  type: 'Tipo',
  type: 'Tipo',
  type: 'Tipo',
  type: 'Tipo',
  type: 'Tipo',
  type: 'Tipo',
  type: 'Tipo',
  type: 'Tipo',
  assessment_param: 'ID de evaluación: {id}',
  area_of_interest_id: 'Área de interés {id}',
  users_list: 'Lista de usuarios',
  select_all_users: 'Seleccionar todos los usuarios',
  create_report: 'Crear informe',
  save_as_pdf: 'Guardar como PDF',
  iq_100b_mobile: 'IQ100B - Móvil',
  device_id_param: 'ID del dispositivo: {device}',
  detected_date_date: 'Fecha detectada: {date}',
  pipe_install: 'Instalación de tubería',
  closure_date_date: 'Fecha de cierre: {date}',
  fixed_date_date: 'Fecha de reparación: {date}',
  status_state_param: 'Estado: {state}',
  status_param: 'Estado: {status}',
  alert_type_param: 'Tipo de alerta: {alertType}',
  noise_alert_id_noiseId: 'ID de alerta de ruido: {noiseId}',
  comment_comment: 'Comentario: {comment}',
  end_date: 'Fecha de finalización',
  couple_id_param: 'ID de pareja: {id}',
  sample_distances: 'Distancias de las muestras',
  probability_param: 'Probabilidad: {probability}',
  leak_boss_param: 'Jefe de fuga: {boss}',
  intensity2: 'Intensidad:',
  interferences: 'Interferencias',
  is_electrolysis: 'Es electrólisis',
  is_pressure: 'Es presión',
  is_mini: 'Es G5Ultra',
  is_previous_break_reported: 'Ruptura previa reportada',
  is_rocks: 'Es rocas',
  is_voids: 'Es vacíos',
  items: 'Elementos',
  lamppost_number: 'Número de farola',
  large_diameter: 'Gran diámetro',
  last_comment: 'Último comentario:',
  last_communication: 'Última comunicación',
  last_event_time: 'Hora del último evento',
  last_installation_date: 'Fecha de la última instalación',
  latitude: 'Latitud',
  launch_street_view_for_selected_item: 'Inicia Street View para el elemento seleccionado',
  lead: 'Plomo',
  event_date_param: 'Fecha del evento: {date}',
  intensity_growth: 'Intensity Growth',
  leak_growth: 'Crecimiento de fuga',
  leak_id: 'ID de fuga',
  leak_id2: 'ID de fuga:',
  length: 'Longitud',
  license: 'Licencia',
  limitation_of_liability: 'Limitación de responsabilidad',
  limitation_of_liability2: 'LIMITACIÓN DE RESPONSABILIDAD',
  limitations: 'Limitaciones',
  limitations_on_use: 'Limitaciones en el uso',
  loading: 'Cargando...',
  location_information: 'Información de ubicación',
  logs: 'registros',
  interference_artifact: 'Artefacto de interferencia',
  longitude: 'Longitud',
  longitude_latitude: 'Longitud, Latitud:',
  mail_sms_notification: 'Notificación por correo / SMS',
  manage_distribution_lists: 'Administrar listas de distribución',
  management: 'Gestión',
  map_could_not_be_captured_please_take_a_screenshot: 'No se pudo capturar el mapa. Por favor, tome una captura de pantalla manualmente.',
  material: 'Material',
  material_and_diameter_or_placement_year: 'Material y diámetro / Año de colocación',
  material_or_placement_year: 'Material / Año de colocación',
  materials: 'Materiales',
  max_probability: 'Probabilidad MAX',
  mdpe: 'MDPE',
  mfa_enabled: 'MFA habilitado',
  metal: 'Metal',
  mfa_code: 'Código MFA:',
  mobile: 'Móvil',
  modification_of_tos: 'Modificación de los T&C',
  month: 'Mes',
  multifactor_authentication: 'Autenticación multifactor',
  n: 'N',
  min_value: 'Valor mínimo',
  max_value: 'Valor máximo',
  median_value: 'Valor mediano',
  name: 'Nombre',
  name_correlation: 'Correlación de {name}',
  nature_of_application_hosting: 'Naturaleza de la aplicación y alojamiento',
  nature_of_break_comment: 'Comentario sobre la naturaleza de la ruptura',
  network_coverage_gnss_satellites_interruption_of_s: 'Cobertura de red, satélites GNSS, interrupción del servicio',
  new_couple: 'Nueva pareja',
  new_list_item: 'Nuevo elemento de lista',
  no_data: 'SIN DATOS',
  no_filters_applyed: 'No se ha aplicado ningún filtro',
  no_lists: 'Sin lista',
  noise_NC1_NC2: 'Ruido NC1/NC2',
  noise_alert_options: 'Opciones de alerta de ruido',
  noise_alert_statuses: 'Estados de alerta de ruido',
  noise_interval: 'Intervalo de ruido',
  noise_level: 'Nivel de ruido',
  valve_alert_options: 'Opciones de alerta de válvula',
  control_valve: 'Válvula de control',
  service_valve: 'Válvula de servicio',
  gate_valve: 'Válvula de compuerta',
  air_valve: 'Válvula de aire',
  signals: 'Señales',
  tensors: 'Tensores',
  previous_samples: 'Muestras anteriores',
  fmReceiverSN: 'SN del receptor FM',
  hardwareRevision: 'Revisión de hardware',
  domainName: 'Nombre de dominio',
  at_least_length_characters_are_required: 'Se requieren al menos {length} caracteres',
  at_least_one_uppercase_letter: 'Requiere letras mayúsculas',
  at_least_one_lowercase_letter: 'Requiere letras minúsculas',
  at_least_one_number: 'Requiere números',
  the_passwords_are_not_the_same: 'Las contraseñas no son iguales',
  invalid_password: 'Contraseña inválida',
  back_to_signin_page: 'Volver a la página de inicio de sesión',
  forgot_your_password: '¿Olvidaste tu contraseña?',
  email_to_reset_password: 'Ingrese su correo electrónico a continuación y le enviaremos un mensaje para restablecer su contraseña',
  email_to_reset_password_sent: 'Hemos enviado un código de restablecimiento de contraseña por correo electrónico a {mail}. Ingréselo a continuación para restablecer su contraseña.',
  reset_my_password: 'Restablecer mi contraseña',
  new_password: 'Nueva contraseña',
  confirm_new_password: 'Ingrese la nueva contraseña de nuevo',
  change_password: 'Cambiar contraseña',
  alert_information: 'Información de alerta',
  created_date: 'Fecha de creación',
  raw_intensity: 'Intensidad bruta',
  filtered_intensity: 'Intensidad filtrada',
  consistency: 'Consistencia',
  close_alert: 'Cerrar alerta',
  number_of_samples: 'Número de muestras',
  load: 'Cargar',
  clean_sample: 'Muestra limpia',
  sample: 'Muestra',
  device_type: 'Tipo de dispositivo: {deviceType}',
  address_param: 'Dirección: {address}',
  reset_password: 'RESTABLECER CONTRASEÑA',
  rf_sync_problem_: 'Problema de sincronización RF, ',
  rsrp_dbm: 'RSRP [dbm]',
  ready: 'Listo',
  record_duration: 'Duración de la grabación',
  recording_duration_sec: 'Duración de la grabación [Seg]',
  recording_durationsec: 'Duración de la grabación seg',
  registered_microsoft_partner: 'Socio registrado de Microsoft',
  relative_sensor: 'Sensor relativo',
  reload_data: 'Volver a cargar datos',
  remember_me: 'Recuérdame',
  remove_sensor_sensorid: 'Eliminar sensor {sensorID}',
  remove: 'Eliminar',
  repair_date_: 'Fecha de reparación',
  report_enabled: 'Informe activado',
  reports_settings: 'Configuración de informes',
  reports: 'Informes',
  resets: 'Reinicios',
  sms: 'SMS',
  sop_id_: 'ID de SOP: ',
  sop_status: 'Estado de SOP',
  sop_type: 'Tipo de SOP',
  sops_filters: 'Filtros de SOP',
  sample_correlation_contrast: 'Contraste de correlación de muestra',
  sample_correlation: 'Correlación de muestra',
  sample_id_: 'ID de muestra: ',
  sample_noise: 'Ruido de muestra',
  second_sensor: 'Segundo sensor',
  select_projects: 'Seleccionar proyectos...',
 send_to_printer: 'Enviar a la impresora',
  sensitivity_level: 'Nivel de sensibilidad',
  sensor_id_: 'ID del sensor: ',
  sensor_id_id: 'ID del sensor: {id}',
  sensor_type: 'Tipo de sensor',
  sensor_comment_: 'Comentario del sensor',
  sensor_id_default: 'ID del sensor (predeterminado)',
  sensor: 'Sensor',
  sensors_report: 'Informe de sensores',
  sensors_filters: 'Filtros de sensores',
  sensors_options: 'Opciones de sensores',
  service_provider_: 'Proveedor de servicios',
  shadow: 'Sombra',
  show_hide: 'Mostrar / Ocultar',
  show_all: 'Mostrar todo',
  sim_number: 'Número de SIM',
  source_alert: 'Alerta de origen',
  start_frequency: 'Frecuencia de inicio',
  start_frequency_hz: 'Frecuencia de inicio (Hz)',
  start_time: 'Hora de inicio',
  state: 'Estado',
  status_default: 'Estado (predeterminado)',
  percent: 'Porcentaje',
  total_count: 'Conteo total',
  under_count: 'Conteo bajo',
  mixed_over_under_count: 'Conteo de tipo mixto',
  g5_count: 'Conteo G5',
  mixed_g_count: 'Conteo G mixto',
  project_id: 'ID del proyecto',
  project_display_name: 'Nombre de visualización del proyecto',
  status: 'Estado: ',
  status: 'Estado: ',
  status: 'Estado: ',
  status: 'Estado: ',
  status: 'Estado: ',
  status: 'Estado: ',
  status: 'Estado: ',
  status: 'Estado: ',
  task_id: 'ID de tarea',
  time_: 'Hora: ',
  timezone: 'Zona horaria',
  total_length_unitslable: 'Longitud total [{unitsLable}]',
  type_: 'Tipo: ',
  type_type: 'Tipo: {type}',
  under: 'Debajo',
  underground_acc_gps: 'ACC GPS subterráneo',
  underground_acc_rf: 'ACC RF subterráneo',
  underground_hyd_gps: 'HYD GPS subterráneo',
  underground_hyd_rf: 'HYD subterráneo RF',
  uninstall_device_and_remove_sensor: 'Desinstalar dispositivo y eliminar sensor',
  uninstall_device_and_install_it_on_the_new_sensor: 'Desinstalar dispositivo e instalarlo en el nuevo sensor',
  uninstall_comment: 'Comentario de desinstalación',
  uninstall: 'Desinstalar',
  uninstalled: 'Desinstalado',
  update_customer_details: 'Actualizar detalles del cliente',
  update_pipes: 'Actualizar tuberías',
  update_project_details: 'Actualizar detalles del proyecto',
  update_user_details: 'Actualizar detalles del usuario',
  use_feet_in_matlab_graphs: 'Usar pies en gráficos de Matlab',
  use_imperial_units_in_graphs: 'Usar unidades imperiales en gráficos',
  user_name_name: 'Usuario: {name}',
  users: 'Usuarios',
  w:  'W',
  your_item_located_in_project: 'Su {item} se encuentra en el proyecto {project}.',
  hz: '[Hz]',
  unitshz: ' [{units}/Hz]',
  balagan_dist_ratio_thr: 'balagan_dist_ratio_thr',
  couple: 'pareja',
  false: 'falso',
  first: 'primero',
  ft: 'pi',
  inactive_couple_no_sensors_planning: 'pareja inactiva - sin sensores - planificación',
  inactive_couple_no_sensors: 'pareja inactiva - sin sensores',
  inactive_couple: 'pareja inactiva',
  irrig_dist: 'irrig_dist',
  last: 'último',
  length_length_units: 'longitud: {length}[{units}]',
  no_correlations_warning: 'aviso de ausencia de correlación',
  slope_slope_std_std_r2_r2: 'pendiente: {slope}, std: {std}, r2: {r2}',
  sum_included_thr: 'sum_included_thr',
  update_from_aqs_mobile_details: 'actualización desde AQS Mobile. ({details})',
  name_spectrogram: 'Espectrograma de {name}',
  low_rm_rm: 'bajo RM: [{rm}]',
  noise_num_samples: 'Número de muestras de ruido',
  noise_tx_time: 'Tiempo de transmisión de ruido',
  average_last: 'Promedio último',
  none: 'Ninguno',
  not_found: 'No encontrado',
  not_include_not_installed_sensors: '* No incluir sensores "No instalados"',
  notices_and_electronic_communications: 'Avisos y comunicaciones electrónicas',
  display_item: 'Mostrar {item}',
  official_language: 'Idioma oficial',
  op_audio_table: 'tabla de audio {op}',
  or: ' O "',
  original_audio: 'Audio original',
  other: 'Otro',
  overground: 'Sobre superficie',
  override_length: 'Reemplazar longitud',
  p: 'P',
  payload: 'Carga útil',
  payment: 'Pago',
  pca: 'PCA',
  sample_images: 'Imágenes de muestra',
  user_sample_classify: 'clasificar',
  invalid_email_address: 'Dirección de correo electrónico no válida',
  phone_number: 'Número de teléfono',
  pictures: 'imágenes',
  pipe: 'Tubería:',
  pipe_coating: 'Revestimiento de tubería',
  pipe_condition_assessment_details: 'Detalles de evaluación de la condición de la tubería',
  pipe_condition_details: 'Detalles de la condición de la tubería',
  pipe_diameter: 'Diámetro de la tubería',
  pipe_id: 'ID de tubería',
  pipe_install_year: 'Año de instalación de la tubería',
  pipe_length: 'Longitud de la tubería',
  pipe_length2: 'Longitud de la tubería:',
  filter_by_customer: 'Filtrar por cliente',
  filter_by_customer: 'Filtrar por cliente',
  filter_by_customer: 'Filtrar por cliente',
  filter_by_customer: 'Filtrar por cliente',
  filter_by_customer: 'Filtrar por cliente',
  filter_by_customer: 'Filtrar por cliente',
  filter_by_customer: 'Filtrar por cliente',
  filter_by_customer: 'Filtrar por cliente',
  check_devices: 'Verificar dispositivos',
  copy_device_id_from_excel: 'Copiar ID de dispositivos desde Excel',
  count_devices: '{count} Dispositivos',
  selected_devices: '{selected} / {count} Dispositivos',
  pipe_material: 'Material de la tubería',
  pipe_section_details: 'Detalles de la sección de la tubería',
  pipe_thickness: 'Espesor de la tubería',
  pipe_thickness_at_break: 'Espesor de la tubería (en la ruptura)',
  pipe_type: 'Tipo de tubería',
  pipes_materials: 'Materiales de las tuberías:',
  please_uninstall_device: 'Por favor desinstale el dispositivo',
  point_1: 'Punto 1',
  point_2: 'Punto 2',
  poly_ethelen: 'Polietileno',
  position: 'Posición:',
  preparing_to_download_if_download_does_not_commence_please_allow_popups_for_this_site: 'Preparando para descargar. Si la descarga no comienza, permita ventanas emergentes para este sitio.',
  previous_break_operating_press: 'Presión de operación de ruptura anterior',
  prior_2_weeks_weather: 'Tiempo de las 2 semanas anteriores',
  priority: 'Prioridad',
  intensity_opr: 'Intensidad Opr',
  probability_opr: 'Probabilidad Opr',
  work_area: 'Área de trabajo',
  work_area: 'Área de trabajo',
  work_area: 'Área de trabajo',
  work_area: 'Área de trabajo',
  work_area: 'Área de trabajo',
  work_area: 'Área de trabajo',
  work_area: 'Área de trabajo',
  work_area: 'Área de trabajo',
  repair_code: 'Código de reparación',
  contractor_id: 'ID del contratista',
  detected_at: 'Detectado en',
  priority_default: 'Prioridad (predeterminado)',
  privacy_statement: 'Declaración de privacidad',
  probability: 'Probabilidad',
  processing_of_personal_data: 'Tratamiento de datos personales',
  products: 'Productos',
  project: 'Proyecto',
  project2: 'Proyecto:',
  proximity_to_other_utilities: 'Proximidad a otros servicios públicos',
  prs_calib_multiply: 'Multiplicación de calibración PRS',
  prs_calib_shift: 'Desplazamiento de calibración PRS',
  pvc: 'PVC',
  quality: 'Calidad:',
  radio: 'Radio',
  radio_frequency: 'Frecuencia de radio',
  ref_time: 'Hora de referencia',
  registration_payment: 'Registro; Pago',
  remove_filters_to_view_device: 'Eliminar filtros para ver el dispositivo',
  repair_date: 'Fecha de reparación',
  repair_details: 'Detalles de reparación',
  add_new_alert: 'Agregar nueva alerta',
  repair_type: 'Tipo de reparación',
  detection_date: 'Fecha de detección',
  report: 'Informe',
  reported_by: 'Reportado por',
  reset: 'Restablecer',
  resistivity: 'Resistividad',
  result: 'Resultado',
  s: 'S',
  sample_id: 'ID de muestra:',
  sample_time: 'Hora de muestra:',
  sample_time_1: 'Hora de muestra 1',
  sample_time_2: 'Hora de muestra 2',
  sample_time_3: 'Hora de muestra 3',
  samples: 'Muestras',
  item_is_hidden_by_filters: '{item} está oculto por filtros',
  sap_number: 'Número SAP:',
  save: 'Guardar',
  save_and_apply: 'Guardar y aplicar',
  save_as_excel: 'Guardar como Excel',
  save_changes: 'Guardar cambios',
  scheduler: 'Programador',
  add_new_mobile_alert: 'Agregar nueva alerta móvil',
  search: 'Buscar',
  search_box: 'Cuadro de búsqueda',
  sec: 'seg',
  select: 'Seleccionar...',
  select2: 'Seleccionar',
  select_draw_mode_and_draw_area_of_interest: 'Seleccione el modo de dibujo y dibuje el área de interés',
  select_sensor: 'Seleccionar sensor',
  selected_sensor_id: 'Sensor seleccionado {id}',
  sensor_id_param2: 'Sensor: {id}',
  send: 'Enviar',
  sensor1_address: 'Dirección del sensor1:',
  bands: 'Bandas',
  days: 'Días',
  avg_max_leak_val_ndx: 'Promedio máximo de fuga [ndx {maxProbIndex}, fuga ndx: {maxProbLeakIndex}, val: {maxProbVal}]',
  sensor2_address: 'Dirección del sensor2:',
  sensor_activity_threshold: 'Umbral de actividad del sensor',
  sensor_comment: 'Comentario del sensor',
  wake_up_before_radio: 'Despertar antes de la radio',
  random_delay: 'Retraso aleatorio',
  sampling_freq: 'Frecuencia de muestreo',
  sensor_id: 'ID del sensor',
  sensor_id2: 'ID del sensor:',
  sensor_id_param: 'ID del sensor: {id}',
  sensor_install: 'Instalación del sensor',
  sensor_is_not_found_check_filters_and_try_again: 'Sensor no encontrado. Verifique los filtros y vuelva a intentarlo',
  sensors: 'Sensores',
  sensors_nc_table: 'tabla NC de sensores',
  service: 'Servicio',
  selected_param: 'Seleccionado: {selectedItems}',
  service_provider: 'Proveedor de servicios',
  service_provider2: 'Proveedor de servicios',
  service_subscription: 'Suscripción de servicio',
  session_max_value: 'Valor máximo de sesión',
  session_min_value: 'Valor mínimo de la sesión',
  session_num_events: 'Número de eventos de la sesión',
  set_actual_leak_position: 'Establecer posición real de la fuga',
  set_actual_leak_position_on_map: 'Establecer la posición real de la fuga en el mapa:',
  set_apn: 'Establecer APN',
  set_leak_valve_monitor: 'Establecer monitor de válvula de fuga',
  event_magnitude_constant: 'Magnitud del evento',
  magnitude_threshold: 'Umbral de magnitud',
  leak_monitor_interval_sec: 'Intervalo de monitoreo (seg)',
  leak_monitor_alert_suspend_interval_sec: 'Intervalo de suspensión seg',
  sample_frequency_hz: 'Frecuencia de muestreo HZ',
  leak_monitor_long_sample_sec: 'Duración de la muestra',
  sample_duration: 'Duración de la muestra',
  notch_filter: 'Filtro de muesca',
  compress_enabled: 'Compresión habilitada',
  channel_10k: 'Canal 10k',
  radio_band_mask_2: 'Máscara de banda de radio 2',
  radio_band_mask_3: 'Máscara de banda de radio 3',
  radio_band_mask_4: 'Máscara de banda de radio 4',
  audio_duration: 'Duración del audio',
  mode: 'Modo',
  cat_m_preferred: 'CAT-M preferido',
  nb_iot_preferred: 'NB-IOT preferido',
  cat_m_only: 'Solo CAT-M',
  zone: 'Zona',
  threshold_3: 'Umbral 3',
  threshold_2: 'Umbral 2',
  threshold_1: 'Umbral 1',
  auto_gain: 'Ganancia automática',
  gain_1: 'Ganancia 1',
  gain_2: 'Ganancia 2',
  gain_3: 'Ganancia 3',
  gain_4: 'Ganancia 4',
  nb_iot_only: 'Solo NB-IOT',
  channel_num: 'Canal {num}',
  radio_duration: 'Duración de la radio',
  audio_fs: 'Audio FS',
  sampling_window: 'Ventana de muestreo',
  audio_num_bands: 'Número de bandas de audio',
  audio_num_coff: 'Número de coeficientes de audio',
  audio_band_mask_1: 'Máscara de banda de audio 1',
  audio_band_mask_2: 'Máscara de banda de audio 2',
  audio_band_mask_3: 'Máscara de banda de audio 3',
  audio_band_mask_4: 'Máscara de banda de audio 4',
  radio_num_bands: 'Número de bandas de radio',
  radio_num_coff: 'Número de coeficientes de radio',
  audio_enable: 'Habilitar audio',
  conf: 'Conf',
  fm2_conf: 'conf fm2',
  fm1: 'fm1',
  fm2: 'fm2',
  corr_sample_freq: 'frecuencia de muestra de correlación',
  audio_report_enabled: 'informe de audio habilitado',
  corr_radio_duration: 'duración de radio de correlación',
  corr_audio_duration: 'duración de audio de correlación',
  noise_audio_duration: 'duración de audio de ruido',
  corr_audio_fs: 'fs de audio de correlación',
  wakeup_befor: 'despertar antes',
  random_backoff_connect: 'retroceso aleatorio de conexión',
  dfu_fw: 'dfu_fw',
  dfu_type: 'dfu_type',
  dfu_block: 'dfu_block',
  modem_fw: 'FW del módem',
  snr1: 'snr1',
  snr2: 'snr2',
  last_updated: 'Última actualización',
  tlv_server_time: 'Hora del servidor TLV',
  cellular_time: 'Hora celular',
  apn: 'APN',
  domain: 'Dominio',
  modem_version: 'Versión del módem',
  search_users: 'Buscar usuarios',
  search_alert: 'Buscar alerta',
  search_sensor: 'Buscar sensor',
  model: 'Modelo',
  iemi: 'IMEI',
  iccid: 'ICCID',
  search_installation_point: 'Buscar punto de instalación',
  search_interference: 'Buscar interferencia',
  interference_id: 'ID de interferencia',
  set_default: 'Establecer predeterminado',
  prev: 'Anterior',
  next: 'Siguiente',
  required: 'Obligatorio',
  search_couple: 'Buscar pareja',
  continue: 'Continuar',
  you_are_about_to_take_a_significant_action_on_num_devices: 'ESTÁS A PUNTO DE TOMAR UNA ACCIÓN SIGNIFICATIVA EN {num} DISPOSITIVOS',
  confirm_updating_devices_apn_to: 'Confirmar actualización de APN de dispositivos a {apn}',
  search_task: 'Buscar tarea',
  lte_mode: 'Modo LTE',
  confirm_updating_lte_mode_to: 'Confirmar actualización de modo LTE a {mode}',
  search_user: 'Buscar usuario',
  search_project: 'Buscar proyecto',
  search_customer: 'Buscar cliente',
  filter_by: 'Filtrar por',
  filter_by_project: 'Filtrar por proyecto',
  set_capability: 'Establecer capacidad',
  search_alert_id: 'Buscar ID de alerta',
  set_fm_scan: 'Establecer escaneo FM',
  set_lte_configuration: 'Establecer configuración LTE',
  set_lte_mode: 'Establecer modo LTE',
  set_secondary_server: 'Establecer servidor secundario',
  low_frequency: 'Frecuencia baja',
  high_frequency: 'Frecuencia alta',
  medium_frequency: 'Frecuencia media',
  secondary: 'secundario',
  boundaries: 'Límites',
  server: 'servidor',
  set_server: 'Establecer servidor',
  set_time_source: 'Establecer fuente de tiempo',
  set_tpcip_config: 'Establecer configuración TPC-IP',
  severity: 'Gravedad:',
  show_av: 'Mostrar AV',
  site_access_password_security: 'Acceso al sitio y contraseña; Seguridad',
  slope: 'pendiente: {slope}',
  slope_std_r2: 'pendiente: {slope}, std: {std}, r2: {r2}',
  snow_depth: 'Profundidad de nieve',
  sop_id: 'ID de SOP:',
  sops: 'SOPs',
  sort_by: 'Ordenar por',
  source: 'Fuente',
  stolen: 'Robado',
  street_surface: 'Superficie de la calle',
  submit: 'Enviar',
  subscriptions_and_licenses_restrictions: 'Suscripciones y licencias; Restricciones',
  success: 'Éxito',
  sudden_water_temp_change: 'Cambio brusco de la temperatura del agua',
  supervisor_user: 'Usuario supervisor',
  support_services: 'Servicios de soporte',
  switch_to_projectName: 'Cambiar al proyecto {projectName}',
  switch_to_project: 'Cambiar al proyecto {proyecto}',
  switch: 'Cambiar',
  sync: 'Sincronizar',
  system: 'Sistema',
  tabs_container: 'contenedor de pestañas',
  task_name: 'Nombre de la tarea',
  temp_avg: 'Promedio de temperatura',
  temp_fail: 'Fallo de temperatura',
  temp_rise: 'Aumento de temperatura',
  term_and_termination: 'Término y terminación',
  terms: 'TÉRMINOS',
  text_alignment: 'alineación de texto',
  this_is_a_demo_404_page: '¡Esta es una página de demostración 404!',
  this_website_uses_cookies_to_enhance_the_user_expe: 'Este sitio web utiliza cookies para mejorar la experiencia del usuario.',
  time: 'Hora',
  time_limited_service: 'Servicio de tiempo limitado',
  time_limited_service_subscription: 'Suscripción de servicio de tiempo limitado',
  time_zone: 'Zona horaria',
  to: 'A',
  to2: 'A:',
  to_businesses_and_professionals: ') a empresas y profesionales.',
  total: 'Total',
  total2: 'Total:',
  traffic: 'Tráfico',
  transient: 'Transitorio',
  trend: 'Tendencia',
  u: 'U',
  under_over: 'Debajo / Encima',
  underground: 'Subterráneo',
  uninstall_device: 'Desinstalar dispositivo',
  update: 'Actualizar',
  update_from_aws_mobile_details: 'Actualización desde AQS Mobile. ({details})',
  update_modem_version: 'Actualizar versión del módem',
  alert_name: 'Nombre de la alerta',
  update_status_to: 'Estado actualizado en',
  update_version: 'Actualizar versión',
  updated: 'Actualizado',
  upload_files: 'Subir archivos',
  upload_screenshot: 'Subir captura de pantalla',
  us_government_restricted_rights: 'Derechos restringidos del gobierno de EE. UU.',
  user: 'Usuario',
  utc_time: 'Hora UTC',
  v: 'V',
  v_x: 'V: {totalSampledDays}, X: {totalUnSampledDays},',
  v_x2: 'V: {totalSampledDaysWithoutEmpty}, X: {totalUnSampledDaysWithoutEmpty},',
  value: 'Valor',
  valve: 'Válvula',
  valve_monitoring: 'Monitoreo de válvula',
  velocity: 'velocidad',
  version: 'Versión',
  wall: 'Pared',
  g3e_fid: 'FID G3E',
  water_loss_unit_h: 'Pérdida de agua ({unit}/h)',
  water_type: 'Tipo de agua',
  pin_location: 'Ubicación del pin',
  weather_conditions: 'Condiciones meteorológicas',
  week: 'Semana',
  wood: 'Madera',
  update_version_ultra: 'Actualizar versión Ultra',
  wover: 'wover',
  wt: 'wt',
  x: 'X',
  x_y: 'X , Y:',
  y: 'Y',
  ver: 'Ver',
  c: 'C',
  score: 'Puntuación',
  remove_sensor: 'Eliminar sensor',
  radio_band_mask_1: 'Máscara de banda de radio 1',
  Probability_AQS: 'AQS-Prob',
  Probability: 'Probabilidad',
  SensorStatus: 'Estado',
  invalid_password_format: 'Formato de contraseña inválido',
  french: 'Francés',
  italian: 'Italiano',
  spanish: 'Spanish',
  israel_standard_time: 'Hora estándar de Israel',
  probabilityLeak_param: 'Probabilidad: {probabilityLeak}',
  pipe_repair_details: 'Detalles de reparación de la tubería',
  probability_leak_opr: 'Probabilidad de fuga Opr',
};
// // Get the keys and sort them
// const sortedKeys = Object.keys(en).sort();

// // Create a new object and assign the sorted keys with their respective values
// let sortedObject = {};
// for (let key of sortedKeys) {
//   sortedObject[key] = en[key];
// }

// console.log(sortedObject);
