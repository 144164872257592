import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Plot from 'react-plotly.js';
import { BarLoader } from 'react-spinners';

const MAX_VALUE_MARGIN = 20;

const LeakGrowth = ({ data, alertItem }, context) => {
	const [layout, setLayout] = useState({
		height: 300,
		autorange: true,
		showlegend: true,
		type: 'date',
		margin: {
			t: 30,
			b: 50,
			l: 60,
			r: 30
		},
	});

	const [config] = useState({
		displaylogo: false,
		responsive: true,
		displayModeBar: true,
	});

	const [plotData, setPlotData] = useState([]);
	const [trend, setTrend] = useState();

	useEffect(() => {
		generatePlotData(data);
	}, [data]);

	const generatePlotData = (data) => {
    if (!data || !Array.isArray(data.y) || data.y.length === 0) {
      return
    }
		let plotData = [{
			x: data.x,
			y: data.y,
			mode: 'lines+markers',
			type: 'scatter',
			name: context.t('intensities')
		},{
			x: data.x,
			y: data.trendData.points,
			mode: 'lines',
			type: 'scatter',
			name: context.t('trend'),
		}];
		// let layout = this.state.layout;

		// console.log('trace 1', trace1);

    const maxValue = Math.max(...data.y);

    setPlotData(plotData);
    setLayout((current) => ({
      ...current,
      yaxis: {
        range: [0, Math.max(100, maxValue * 1.1)]
      }
    }));
	}

	const renderNoData = () => {
		return (
			<div>
				{/* <BarLoader
					color="#4cae4c"
					width="110%"
					loading={data.isFetching}
				/> */}

				{context.t('no_data')}
			</div>
		)
	}

	if (data == null) {
		return renderNoData();
	}

	if (data.x?.length === 0) {
		return renderNoData();
	}

	console.log({
		plotData,
		layout
	});


	return (
		<>
			{data.trendData &&
				<h5 style={{ marginLeft: '10px' }}>{context.t('slope', {
					slope:  alertItem?.AvgGrowthAngle || ''  //(Math.atan(data.trendData.slope) * (180 / Math.PI)).toFixed(),
				})}</h5>
			}

			<Plot
				data={plotData}
				layout={layout}
				config={config}
			/>
		</>
	);
}

LeakGrowth.contextTypes = {
	t: PropTypes.func.isRequired
}

export default LeakGrowth;
