import * as types from '../constants/ActionTypes';

export function couples(state = {}, action) {
  switch (action.type) {
    case types.REQUEST_LEAK_COUPLES:
      return Object.assign({}, state, {
        [action.coupleId]: { isFetching: true, data: [] }
      });
    case types.RECEIVE_LEAK_COUPLES:
      return Object.assign({}, state, {
        [action.coupleId]: { data: action.coupleSensors.data, isFetching: false }
      });
    case types.REQUEST_COUPLES:
      return Object.assign({}, state, {
        couples: { isFetching: true, couples: [], indexMap: {} }
      });
    case types.RECEIVE_COUPLES:
      return Object.assign({}, state, {
        couples: { isFetching: false, couples: action.couples, indexMap: action.indexMap }
      });
    // case types.REQUEST_COUPLES_RM:
    //   return Object.assign({}, state, {
    //     couples: { isFetching: true, couples: [] }
    //   });
    case types.RECEIVE_COUPLES_RM:
      return Object.assign({}, state, {
        couples: Object.assign({}, state.couples, {
          couples: action.couples,
        })
      });
    case types.REQUEST_COUPLE_SAMPLES:
      return Object.assign({}, state, {
        [action.coupleID]: { isFetching: true, data: [] }
      });
    case types.RECEIVE_COUPLE_SAMPLES:
      return Object.assign({}, state, {
        [action.coupleID]: {
          isFetching: false,
          data: action.data,
          groupData: action.groupData,
          pageIndex: action.pageIndex,
          isExtendedInfo: action.isExtendedInfo,
        }
      });
    case types.REQUEST_COPULE_PATH:
      return Object.assign({}, state, {
        couplePath: { isFetching: true, path: [] }
      });
    case types.RECEIVE_COPULE_PATH:
      return Object.assign({}, state, {
        couplePath: { isFetching: false, path: action.data }
      });
    case types.SORT_COUPLES_LOCALY:
      return Object.assign({}, state, {
        couples: Object.assign({}, state.couples, {
          couples: action.couples,
          indexMap: action.indexMap
        })
      });

    default:
      return state;
  }
}
