import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Select from '../Select/Select';
import Input from '../Input/Input';
import AddressField from '../Custom/AddressField';
import classNames from 'classnames';

const isValidCoordinate = (value) => {
  if (!value) return undefined;

  let coords;
  if (Array.isArray(value)) {
    coords = value;
  } else if (typeof value === 'string') {
    coords = value.split(',').map(coord => coord.trim());
  } else {
    return 'Invalid coordinates format';
  }

  if (coords.length !== 2) return 'Invalid coordinates format';

  const [lat, lon] = coords.map(coord =>
    typeof coord === 'string' ? parseFloat(coord) : coord
  );

  if (
    isNaN(lat) ||
    isNaN(lon) ||
    lat < -90 ||
    lat > 90 ||
    lon < -180 ||
    lon > 180
  ) {
    return 'Invalid coordinates';
  }

  return undefined;
};

const AddAlertForm = (props, context) => {
  const {
    isOpen,
    doClose,
    user,
    options,
    onCreateNewValue,
    couplesIdsData,
    submitting,
    handleSubmit,
    getAddressByLatLong,
    onFetchRecommendedCouple
  } = props;

  const panelClasses = classNames('add-alert-form', { show: isOpen });
  const formClass = 'form-group auto-center';
  const labelsClass = 'col-xs-3 control-label';
  const fieldsClass = 'col-xs-8 control-input';

  const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
  const minValue = min => value => value && value < min ? `Must be at least ${min}` : undefined;
  const maxValue = max => value => value && value > max ? `Must be at most ${max}` : undefined;
  const minValue0 = minValue(0);
  const maxValue100 = maxValue(100);

  return (
    <div className={panelClasses}>
      <div className="new-alert-header">
        <div className="actions">
          <i className="icon-close" onClick={doClose} />
        </div>
        <h2>{context.t('add_new_alert')}</h2>
      </div>
      <form className="form-horizontal sticky-actions" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-6">
            <div className={formClass}>
              <label className={labelsClass}>{context.t('priority')}</label>
              <div className={fieldsClass}>
                <Field name="ABC" component={Select} placeholderTitle={context.t('select')} options={options.ABC} />
              </div>
            </div>
            <div className={formClass}>
              <label className={labelsClass}>{context.t('probability')}</label>
              <div className={fieldsClass}>
                <Field
                  name="Probability"
                  component={Input}
                  type="number"
                  className="form-control"
                  placeholder="0"
                  validate={[number, minValue0, maxValue100]}
                  disabled={!user.editMode}
                />
              </div>
            </div>
            <div className={formClass}>
              <label className={labelsClass}>{context.t('detection_date')}:</label>
              <div className={fieldsClass}>
                <Field name="DetectionDateTime" component={Input} type="date" className="form-control" disabled={!user.editMode} />
              </div>
            </div>
            <div className={formClass}>
              <label className={labelsClass}>{context.t('repair_date')}:</label>
              <div className={fieldsClass}>
                <Field name="fixDate" component={Input} type="date" className="form-control" disabled={!user.editMode} />
              </div>
            </div>
            <div className={formClass}>
              <label className={labelsClass}>{context.t('type')}</label>
              <div className={fieldsClass}>
                <Field name="AlertType" component={Select} placeholderTitle={context.t('select')} options={options.AlertType} />
              </div>
            </div>
            <div className={formClass}>
              <label className={labelsClass}>{context.t('status')}</label>
              <div className={fieldsClass}>
                <Field name="AlertState" component={Select} placeholderTitle={context.t('select')} options={options.AlertState} />
              </div>
            </div>
          </div>
          <div className="col-xs-6">
            <div className={formClass}>
              <label className={labelsClass}>{context.t('repair_details')}</label>
              <div className={fieldsClass}>
                <Field
                  name="RepairCode"
                  component={Select}
                  disabled={!user.editMode}
                  placeholderTitle={context.t('select')}
                  options={options.PCARepairCodesEnum}
                  onCreateNewValue={onCreateNewValue}
                />
              </div>
            </div>
            <div className={formClass}>
              <label className={labelsClass}>{context.t('work_area')}</label>
              <div className={fieldsClass}>
                <Field
                  name="WorkArea"
                  component={Select}
                  disabled={!user.editMode}
                  creatable={true}
                  placeholderTitle={context.t('select')}
                  options={options.WorkAreas}
                />
              </div>
            </div>
            <div className={formClass}>
              <label className={labelsClass}>{context.t('asset_id')}</label>
              <div className={fieldsClass}>
                <Field name="KavMaim" component={Input} type="number" className="form-control" disabled={!user.editMode} />
              </div>
            </div>
            <div className={formClass}>
              <label className={labelsClass}>{context.t('couple_id')}</label>
              <div className={fieldsClass}>
                <Field
                  name="CoupleID"
                  component={Select}
                  disabled={!user.editMode}
                  placeholderTitle={context.t('select')}
                  options={couplesIdsData}
                />
                <button
                  type="button"
                  onClick={onFetchRecommendedCouple}
                  style={{
                    backgroundColor: '#50a3fd',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    marginTop: '8px',
                    display: 'block',
                    width: '100%'
                  }}
                >
                  {context.t('get_couple')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-2 control-label">{context.t('coordinate')}:</label>
          <div className="col-xs-8 control-input">
            <Field
              name="Coordinate"
              component={Input}
              placeholder={context.t('click_on_map_to_set_coordinate')}
              type="text"
              className="form-control"
              validate={[isValidCoordinate]}
            />
          </div>
        </div>
        <AddressField
          className="form-group"
          fieldClassName="col-xs-8 control-input"
          user={user}
          fieldName="StreetAddress"
          onGetAddress={getAddressByLatLong}
        />
        <div className="form-group">
          <label className="col-xs-2 control-label">{context.t('comment')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="Comment" component={Input} type="text" className="form-control" disabled={!user.editMode} />
          </div>
        </div>
        <div className="form-actions text-right">
          <a className="btn btn-info" onClick={doClose}>{context.t('cancel')}</a>
          <button className="btn btn-success" type="submit" disabled={submitting}>
            {context.t('save')}
          </button>
        </div>
      </form>
    </div>
  );
};

AddAlertForm.contextTypes = {
  t: PropTypes.func.isRequired
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ['Priority', 'DetectionDateTime', 'AlertType', 'AlertState', 'Coordinate', 'fixDate', 'CoupleID'];
  requiredFields.forEach(fieldKey => {
    if (values[fieldKey] == null || values[fieldKey] === '') {
      errors[fieldKey] = 'Required';
    }
  });
  if (values.Intensity && (values.Intensity <= 0 || values.Intensity > 32767)) {
    errors.Intensity = 'Illegal Value';
  }
  return errors;
};

const addAlertForm = reduxForm({
  form: 'add-alert',
  enableReinitialize: true,
  validate,
})(AddAlertForm);

export default addAlertForm;
