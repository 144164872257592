import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import { Field, reduxForm } from 'redux-form';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import UIDateFormater from '../../containers/UIhelper/UIDateFormater';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

import './PipeAssessmentReportModal.scss';
import useProjectTimezone from '../../hooks/useProjectTimeZone';

function PipeAssessmentReportModal(props, context) {
  const {
    isAqsAdmin,
    coverageSource,
    coverageLayerSelected,
    project,
    records,
    timeZone,
    alertStateOptions,
    alertTypeOptions,
    loadReport,
    getMapCapture,
  } = props;

  const [open, setOpen] = useState(false);
  const [imgPng, setImgPng] = useState(null);
  const [processing, setProcessing] = useState(false);
  const processingIntervalRef = useRef(null);
  const ellipsisRef = useRef(null);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      clearInterval(processingIntervalRef.current);
    };
  }, []);

  // Open Dialog & load map capture
  const onOpen = () => {
    setOpen(true);
    loadReport();
    getMapCapture().then((url) => {
      const image = new Image();
      image.crossOrigin = 'Anonymous';
      image.src = url;
      setImgPng(url);
    });
  };

  // Close dialog
  const onClose = () => {
    setOpen(false);
  };

  // PDF operations: save, print or export
  const pipeAssessmentReportPDF = (output) => {
    setProcessing(true);
    animateEllipsis();

    const divToPrint = document.getElementById('pipe-assessment-body');

    html2canvas(divToPrint, {
      useCORS: true,
      allowTaint: false,
      logging: true,
    })
      .then((canvas) => {
        const pdf = new jsPDF({
          orientation: 'l',
        });
        pdf.addImage(canvas, 'JPEG', 8, 30);

        switch (output) {
          case 'export':
            pdf.save('pipe-assessment-report.pdf');
            break;
          case 'print':
            pdf.autoPrint();
            pdf.output('dataurlnewwindow');
            break;
          case 'save':
            // ToDo: implement save functionality
            break;
          default:
            pdf.output('dataurlnewwindow');
            setOpen(false);
            break;
        }
        setProcessing(false);
        clearInterval(processingIntervalRef.current);
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const saveAndClose = () => {
    pipeAssessmentReportPDF('save');
  };

  const printPipeAssessmentReport = () => {
    pipeAssessmentReportPDF('print');
  };

  const exportToPDF = () => {
    pipeAssessmentReportPDF('export');
  };

  // Animate ellipsis for processing indication
  const animateEllipsis = () => {
    setTimeout(() => {
      const ellipsis = ellipsisRef.current;
      processingIntervalRef.current = setInterval(() => {
        if (ellipsis.innerHTML.length > 3) {
          ellipsis.innerHTML = "";
        } else {
          ellipsis.innerHTML += ".";
        }
      }, 500);
    });
  };
  const projectTimezone = useProjectTimezone()
  // Render records
  const recordsRender = () => {
    return (
      <div className="records-container">
        {records &&
          records.map((item, index) => (
            <div key={index} className="pipe-assessment-record">
              <div>
                <p>
                  {context.t('couple_id_coupleid_material_count_alerts', {
                    CoupleID: item.ID,
                    material: item.Material,
                    Count: item.Leaks,
                  })}
                </p>
                <span>
                  {context.t('addresses_address1_address2', {
                    address1: item.Address1,
                    address2: item.Address2,
                  })}
                </span>
              </div>
              <div>
                <table>
                  <thead>
                  <tr>
                    <th>{context.t('alert_id')}</th>
                    <th>{context.t('intensity')}</th>
                    <th>{context.t('state')}</th>
                    <th>{context.t('detected')}</th>
                    <th>{context.t('latitude')}</th>
                    <th>{context.t('longitude')}</th>
                    <th>{context.t('x')}</th>
                    <th>{context.t('y')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {item.alerts.map((alert, sampleIndex) => {
                    const alertState = alert.AlertState;
                    const state = alertStateOptions.find(
                      (option) => option.value === alertState
                    );
                    return (
                      <tr key={sampleIndex}>
                        <td>{alert.id}</td>
                        <td>{alert.Intensity}</td>
                        <td>{state.label}</td>
                        <td>
                          {UIDateFormater(alert.DetectedAt, DateType.DATE, timeZone, projectTimezone)}
                        </td>
                        <td>{alert.Latitude.toFixed(7)}</td>
                        <td>{alert.Longitude.toFixed(7)}</td>
                        <td>{alert.X}</td>
                        <td>{alert.Y}</td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
      </div>
    );
  };

  return (
    <div>
      {isAqsAdmin &&
        coverageSource === 'pipeAssessment' &&
        coverageLayerSelected && (
          <button
            type="button"
            className="btn btn-success btn-assessments-report"
            onClick={onOpen}
            title={context.t('pipe_assessment_report')}
          />
        )}

      <AppModal
        open={open}
        onClose={onClose}
        title={context.t('pipe_assessments_report_projectname_epsg', {
          projectName: project.ShortName,
          epsg: project.EPSG,
        })}
        content={
          <div>
            <div className="map-container">
              <img
                src={imgPng}
                className="pipeAssessmentImage"
                id="pipeAssessmentMap"
                alt="Pipe Assessment"
              />
              <div className="form-group rightSideGuidlinesInPipeAssessmentReport">
                <div>{context.t('guidelines')}</div>
                <div className="col-xs-8 control-input">
                  <Field
                    name="guidelines"
                    component="textarea"
                    className="form-control textAreaGuidline"
                  />
                </div>
              </div>
            </div>
            {recordsRender()}
          </div>
        }
        actions={
          <button
            className="btn btn-info"
            onClick={exportToPDF}
            title={context.t('export_the_view_to_pdf')}
          >
            {context.t('export')}
          </button>
        }
      />
      {/* Ellipsis for processing indicator */}
      <span ref={ellipsisRef} />
    </div>
  );
}

PipeAssessmentReportModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const pipeAssessmentReportModal = reduxForm({
  form: 'PipeAssessmentReportModal', // a unique name for this form
  enableReinitialize: true,
})(PipeAssessmentReportModal);

export default pipeAssessmentReportModal;
