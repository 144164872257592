import PropTypes from 'prop-types';
import React from 'react';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

import '../../../../images/ic_v.png'; // Ensure these images are correctly resolved by your bundler
import '../../../../images/ic_x.png';
import useProjectTimeZone from '../../../hooks/useProjectTimeZone';

require('../SensorInfo/SensorInfo.scss');

function SensorLogInfo(props, context) {
  const { selectedSensorLog, timeZone, downloadFile, selectSensorLog } = props;
  const projectTimeZone = useProjectTimeZone()
  const closePanel = () => {
    selectSensorLog({});
  };

  return (
    <div className="sample-info">
      <i className="icon-close" onClick={closePanel} />
      <h2 style={{ marginLeft: '20px' }}>
        {context.t('log_information_device_deviceid', { deviceId: selectedSensorLog.DeviceID })}
      </h2>
      <div className="sample-graphs">
        <table>
          <tbody>
          <tr>
            <th>{context.t('battery_voltage_pre')}</th>
            <td>{selectedSensorLog.BatteryVoltagePreRec}</td>
          </tr>
          <tr>
            <th>{context.t('battery_voltage_post')}</th>
            <td>{selectedSensorLog.BatteryVoltage}</td>
          </tr>
          <tr>
            <th>{context.t('sim_number')}</th>
            <td>{selectedSensorLog.SimNumber}</td>
          </tr>
          <tr>
            <th>{context.t('bit_type')}</th>
            <td>{selectedSensorLog.Meaning}</td>
          </tr>
          <tr>
            <th>{context.t('hardware_version')}</th>
            <td>{selectedSensorLog.HardVersion}</td>
          </tr>
          <tr>
            <th>{context.t('firmware_version')}</th>
            <td>{selectedSensorLog.FirmwareVersion}</td>
          </tr>
          </tbody>
        </table>

        <table>
          <tbody>
          <tr>
            <th>{context.t('recording_time_1')}</th>
            <td>{UIDateFormater(selectedSensorLog.RecordingTime1, DateType.TIME, timeZone, projectTimeZone)}</td>
          </tr>
          <tr>
            <th>{context.t('recording_time_2')}</th>
            <td>{UIDateFormater(selectedSensorLog.RecordingTime2, DateType.TIME, timeZone, projectTimeZone)}</td>
          </tr>
          <tr>
            <th>{context.t('recording_time_3')}</th>
            <td>{UIDateFormater(selectedSensorLog.RecordingTime3, DateType.TIME, timeZone, projectTimeZone)}</td>
          </tr>
          <tr>
            <th>{context.t('end_record_time')}</th>
            <td>{UIDateFormater(selectedSensorLog.EndRecordTime, DateType.TIME, timeZone, projectTimeZone)}</td>
          </tr>
          <tr>
            <th>{context.t('config_record_duration')}</th>
            <td>{selectedSensorLog.ConfigRecordingTime}</td>
          </tr>
          <tr>
            <th>{context.t('file_size')}</th>
            <td>{selectedSensorLog.FTPFileSize}</td>
          </tr>
          <tr>
            <th>{context.t('modem_usage')}</th>
            <td>{selectedSensorLog.ModemUsage}</td>
          </tr>
          </tbody>
        </table>

        <table>
          <tbody>
          <tr>
            <th>{context.t('is_send_file_ok')}</th>
            <td>
              {selectedSensorLog.IsFtpOk ? (
                <img className="yesNoBtn" src={require('../../../../images/ic_v.png')} alt="yes" />
              ) : (
                <img className="yesNoBtn" src={require('../../../../images/ic_x.png')} alt="no" />
              )}
            </td>
          </tr>
          <tr>
            <th>{context.t('is_modem_closed_ok')}</th>
            <td>
              {selectedSensorLog.IsModemClosedOk ? (
                <img className="yesNoBtn" src={require('../../../../images/ic_v.png')} alt="yes" />
              ) : (
                <img className="yesNoBtn" src={require('../../../../images/ic_x.png')} alt="no" />
              )}
            </td>
          </tr>
          <tr>
            <th>{context.t('is_csq_ok')}</th>
            <td>
              {selectedSensorLog.IsCsqOk ? (
                <img className="yesNoBtn" src={require('../../../../images/ic_v.png')} alt="yes" />
              ) : (
                <img className="yesNoBtn" src={require('../../../../images/ic_x.png')} alt="no" />
              )}
            </td>
          </tr>
          <tr>
            <th>{context.t('modem_atenna_signal_strength')}</th>
            <td>{selectedSensorLog.ModemAtennaSignalStrength}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

SensorLogInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SensorLogInfo;
