/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { commands } from '../../../constants/G5MessagesTypes';

export default function ControlButtons(props, context) {
  const ButtonComp = (compProps) => (
    <Button
      style={{
        width: '-webkit-fill-available',
        fontSize: '1.5rem',
      }}
      variant='contained'
      color={compProps.sensitive ? 'error' : 'primary'}
      // onClick={(compProps.handleClick) || (() => props.sendCommand(compProps.actionKey))}
      onClick={(e) => {
        if (compProps.handleClick) {
          compProps.handleClick(e, compProps.actionkey);
        } else {
          props.sendCommand(compProps.actionkey);
        }
      }}
      disabled={!props.enable}
      {...compProps}
    >
      {compProps.children}
    </Button>
  );
  ButtonComp.propTypes = {
    actionkey: PropTypes.string,
    children: PropTypes.node,
    handleClick: PropTypes.func,
    user: PropTypes.object,
  };

  const [open, setOpen] = React.useState(false);
  const [significantActionKey, setSignificantKey] = React.useState(null);

  function askBeforSubmit(event, actionKey) {
    event.preventDefault();

    setOpen(true);
    setSignificantKey(actionKey);
    debugger;
  }

  function handleClose() {
    setOpen(false);
  }

  function submitValue() {
    handleClose();
    props.sendCommand(significantActionKey);
    setSignificantKey(null);
  }

  return (
    <React.Fragment>
      <Grid container direction='column' spacing={1}>
        {/* <Grid item ><ButtonComp actionkey={commands.RESET}>{context.t('Reset Command')}</ButtonComp></Grid> */}
        <Grid item>
          <ButtonComp actionkey={commands.GET_SCHEDULER_CONFIG}>
            {context.t('get_scheduler')}
          </ButtonComp>
        </Grid>
        <Grid item>
          <ButtonComp actionkey={commands.GET_AUDIO_CONFIG}>
            {context.t('get_audio_config')}
          </ButtonComp>
        </Grid>
        {/* <Grid item ><ButtonComp actionkey={commands.GET_CORRELATION_CONFIG}>{context.t('Get Correlation Config')}</ButtonComp></Grid> */}
        <Grid item>
          <ButtonComp actionkey={commands.GET_TIME_ZOME}>
            {context.t('get_time_zone')}
          </ButtonComp>
        </Grid>
        <Grid item>
          <ButtonComp actionkey={commands.GET_LTE_MODE}>
            {context.t('get_lte_mode')}
          </ButtonComp>
        </Grid>
        <Grid item>
          <ButtonComp actionkey={commands.START_FM_SCAN}>
            {context.t('start_fm_scan')}
          </ButtonComp>
        </Grid>
        <Grid item>
          <ButtonComp actionkey={commands.GET_FM_CHANNEL}>
            {context.t('get_fm_channel')}
          </ButtonComp>
        </Grid>
        <Grid item>
          <ButtonComp actionkey={commands.GET_SERVER}>
            {context.t('get_server')}
          </ButtonComp>
        </Grid>
        <Grid item>
          <ButtonComp actionkey={commands.GET_APN}>
            {context.t('get_apn')}
          </ButtonComp>
        </Grid>
        <Grid item>
          <ButtonComp actionkey={commands.GET_PDP}>
            {context.t('get_pdp')}
          </ButtonComp>
        </Grid>
        {/* <Grid item>
          <ButtonComp actionkey={commands.GET_SET_LEAK_VALVE_MONITOR_CONFIG}>
            {context.t('get_valve_leak')}
          </ButtonComp>
        </Grid> */}
        <Grid item>
          <ButtonComp actionkey={commands.GET_AGC}>
            {context.t('get_agc_conf')}
          </ButtonComp>
        </Grid>
        <Grid item>
          <ButtonComp actionkey={commands.GET_FALLBACK_SERVER}>
            {context.t('get_fallback_server')}
          </ButtonComp>
        </Grid>
        <Grid item>
          <ButtonComp actionkey={commands.GET_CAPABILITY_REGISTER}>
            {context.t('get_capability')}
          </ButtonComp>
        </Grid>
        <Grid item>
          <ButtonComp actionkey={commands.GET_LTE_CONFIGURATION}>
            {context.t('get_lte_configuration')}
          </ButtonComp>
        </Grid>

        <Grid item>
          <ButtonComp actionkey={commands.HIGH_PRIORITY_BIT_REQUEST}>
            {context.t('bit_request')}
          </ButtonComp>
        </Grid>
        <Grid item>
          <ButtonComp actionkey={commands.RESET}>
            {context.t('reset')}
          </ButtonComp>
        </Grid>
        <Grid item>
          <ButtonComp actionkey={commands.RESET_BASELINE}>
            {context.t('reset_base_line')}
          </ButtonComp>
        </Grid>
        <Grid item>
          <ButtonComp actionkey={commands.ACTIVATE_TOOLKIT}>
            {context.t('activate_toolkit')}
          </ButtonComp>
        </Grid>
        <Grid item>
          <ButtonComp
            actionkey={commands.DEACTIVATE}
            handleClick={askBeforSubmit}
            disabled={!props.enable || !props.user.g5AqsAdmin}
            sensitive
          >
            {context.t('deactivate')}
          </ButtonComp>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {context.t('you_are_about_to_take_a_significant_action')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {context.t('sure_you_want_to_deactivate_sensors')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={handleClose} autoFocus>
            {context.t('cancel')}
          </Button>
          <Button color='primary' onClick={submitValue} autoFocus>
            {context.t('continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

ControlButtons.contextTypes = {
  t: PropTypes.func.isRequired,
};

ControlButtons.propTypes = {
  advancedOptions: PropTypes.bool,
  enable: PropTypes.bool,
  sendCommand: PropTypes.func,
};
