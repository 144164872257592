export const ADD_FRIEND = 'ADD_FRIEND';
export const STAR_FRIEND = 'STAR_FRIEND';
export const DELETE_FRIEND = 'DELETE_FRIEND';

export const SET_PIPES_SOURCE = 'SET_PIPES_SOURCE';
export const SET_COVERAGE_SOURCE = 'SET_COVERAGE_SOURCE';

export const SELECT_LEAK = 'SELECT_LEAK';
export const ADD_SINGLE_FEATURE = 'ADD_SINGLE_FEATURE';

export const SENSOR_GRAPHS_TOGGLE = 'SENSOR_GRAPHS_TOGGLE';

// Reports
export const REQUEST_REPORT = 'REQUEST_REPORT';
export const RECEIVE_REPORT = 'RECEIVE_REPORT';

//Leak actions.
export const REQUEST_LEAKS = 'REQUEST_LEAKS';
export const RECEIVE_LEAKS = 'RECEIVE_LEAKS';
export const RECEIVE_LEAKS_IDS = 'RECEIVE_LEAKS_IDS';
export const RECEIVE_SINGLE_LEAK = 'RECEIVE_SINGLE_LEAK';
export const SORT_LEAKS = 'SORT_LEAKS';
export const FILTER_LEAKS = 'FILTER_LEAKS';
export const ADD_OPTION_REQUEST = 'ADD_OPTION_REQUEST';
export const ADD_OPTION_RECEIVE = 'ADD_OPTION_RECEIVE';
export const SET_ADDING_MANUAL_ALERTS_MODE = 'SET_ADDING_MANUAL_ALERTS_MODE';
export const SET_ACTUAL_LEAK_POSITION_MODE = 'SET_ACTUAL_LEAK_POSITION_MODE';
export const RECEIVE_SUSPICIOUS_ALERTS_IDS = 'RECEIVE_SUSPICIOUS_ALERTS_IDS';
export const SET_MOBILE_NOISE_SAMPLE_COLOR_SOURCE_ATTR =
  'SET_MOBILE_NOISE_SAMPLE_COLOR_SOURCE_ATTR';

//Mobile Module Leak actions.
// export const RECEIVE_MOBILE_LEAKS = 'RECEIVE_MOBILE_LEAKS';
// export const REQUEST_MOBILE_LEAKS = 'REQUEST_MOBILE_LEAKS';

// export const REQUEST_MOBILE_ALERTS = 'REQUEST_MOBILE_ALERTS';
// export const RECEIVE_MOBILE_ALERTS = 'RECEIVE_MOBILE_ALERTS';
// export const SORT_MOBILE_ALERTS = 'SORT_MOBILE_ALERTS';


// Noise Alerts Actions
export const REQUEST_NOISE_ALERTS = 'REQUEST_NOISE_ALERTS';
export const RECEIVE_NOISE_ALERTS = 'RECEIVE_NOISE_ALERTS';
export const SELECT_NOISE_ALERT = 'SELECT_NOISE_ALERT';
export const NOISE_ALERTS_SET_SORT_PROPS = 'NOISE_ALERTS_SET_SORT_PROPS';
export const SET_ADDING_MANUAL_NOISE_ALERTS_MODE =
  'SET_ADDING_MANUAL_NOISE_ALERTS_MODE';
export const SELECT_SENSOR_FOR_NEW_NOISE_ALERT =
  'SELECT_SENSOR_FOR_NEW_NOISE_ALERT';
export const REQUEST_NOISE_ALERTS_HISTORY = 'REQUEST_NOISE_ALERTS_HISTORY';
export const RECEIVE_NOISE_ALERTS_HISTORY = 'RECEIVE_NOISE_ALERTS_HISTORY';

//Sensors Actions
export const REQUEST_SENSORS = 'REQUEST_SENSORS';
export const RECEIVE_SENSORS = 'RECEIVE_SENSORS';
export const RECEIVE_SENSORS_NON_ASSOCIATED = 'RECEIVE_SENSORS_NON_ASSOCIATED';
export const SORT_SENSORS = 'SORT_SENSORS';
export const SORT_SENSORS_LOCALY = 'SORT_SENSORS_LOCALY';
export const SET_SENSORS_FILTERS = 'SET_SENSORS_FILTERS';
export const SET_SENSORS_DEF_FILTERS = 'SET_SENSORS_DEF_FILTERS';
export const REQUEST_SENSOR_G5_STATUS = 'REQUEST_SENSOR_G5_STATUS';
export const RECEIVE_SENSOR_G5_STATUS = 'RECEIVE_SENSOR_G5_STATUS';
export const REQUEST_SENSOR_G5_BIT = 'REQUEST_SENSOR_G5_BIT';
export const RECEIVE_SENSOR_G5_BIT = 'RECEIVE_SENSOR_G5_BIT';
export const REQUEST_SENSOR_G5_NOISE = 'REQUEST_SENSOR_G5_NOISE';
export const REQUEST_SENSOR_G5_BIT_SAMPLE_MESSAGES = 'REQUEST_SENSOR_G5_BIT_SAMPLE_MESSAGES';
export const RECEIVE_SENSOR_G5_BIT_SAMPLE_MESSAGES = 'RECEIVE_SENSOR_G5_BIT_SAMPLE_MESSAGES';
export const SELECT_SENSOR_G5_BIT_SAMPLE_MESSAGE = 'SELECT_SENSOR_G5_BIT_SAMPLE_MESSAGE';
export const RECEIVE_SENSOR_G5_BIT_SAMPLE_AUDIO = 'RECEIVE_SENSOR_G5_BIT_SAMPLE_AUDIO';
export const RECEIVE_SENSOR_G5_NOISE = 'RECEIVE_SENSOR_G5_NOISE';
export const SELECT_SENSOR_G5_MESSAGE = 'SELECT_SENSOR_G5_MESSAGE';
export const SELECT_SENSOR_G5_NOISE_MESSAGE = 'SELECT_SENSOR_G5_NOISE_MESSAGE';
export const SET_G5_NOISE_GRAPH = 'SET_G5_NOISE_GRAPH';
export const REQUEST_SENSORS_ACTIVITY_DATA = 'REQUEST_SENSORS_ACTIVITY_DATA';
export const RECEIVE_SENSORS_ACTIVITY_DATA = 'RECEIVE_SENSORS_ACTIVITY_DATA';
export const REQUEST_SENSORS_TECHNICIAN_DATA = 'REQUEST_SENSORS_TECHNICIAN_DATA';
export const RECEIVE_SENSORS_TECHNICIAN_DATA = 'RECEIVE_SENSORS_TECHNICIAN_DATA';
export const SENSORS_ACTIVITY_DATA_SET_VISIBILITY = 'SENSORS_ACTIVITY_DATA_SET_VISIBILITY';
export const SENSORS_TECHNICIAN_DATA_SET_VISIBILITY = 'SENSORS_TECHNICIAN_DATA_SET_VISIBILITY';
export const SET_ACTUAL_INSTALLATION_POINT_POSITION_MODE = 'SET_ACTUAL_INSTALLATION_POINT_POSITION_MODE';


//Sample Actions
export const REQUEST_SAMPLES = 'REQUEST_SAMPLES';
export const RECEIVE_SAMPLES = 'RECEIVE_SAMPLES';
export const SORT_SAMPLES = 'SORT_SAMPLES';
export const SELECT_SAMPLE = 'SELECT_SAMPLE';
export const REQUEST_SAMPLE_RESOURCES = 'REQUEST_SAMPLE_RESOURCES';
export const RECEIVE_SAMPLE_RESOURCES = 'RECEIVE_SAMPLE_RESOURCES';

// Customers Actions:
export const REQUEST_CUSTOMERS = 'REQUEST_CUSTOMERS';
export const RECEIVE_CUSTOMERS = 'RECEIVE_CUSTOMERS';
export const SORT_CUSTOMERS_LOCALY = 'SORT_CUSTOMERS_LOCALY';
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';
export const DISTRIBUTION_LIST_SELECT_LIST = 'DISTRIBUTION_LIST_SELECT_LIST';
export const REQUEST_DISTRIBUTION_LISTS = 'REQUEST_DISTRIBUTION_LISTS';
export const RECEIVE_DISTRIBUTION_LISTS = 'RECEIVE_DISTRIBUTION_LISTS';
export const RECEIVE_DISTRIBUTION_LIST_USERS =
  'RECEIVE_DISTRIBUTION_LIST_USERS';
export const DISTRIBUTION_LIST_REMOVE_USER = 'DISTRIBUTION_LIST_REMOVE_USER';
export const DISTRIBUTION_LIST_ADD_USER = 'DISTRIBUTION_LIST_ADD_USER';
export const DISTRIBUTION_LIST_SET_USER_ATTRIBUTE =
  'DISTRIBUTION_LIST_SET_USER_ATTRIBUTE';
export const DISTRIBUTION_LIST_SET_LIST_ANNOUNCES_STATUS_CHANGE =
  'DISTRIBUTION_LIST_SET_LIST_ANNOUNCES_STATUS_CHANGE';
export const DISTRIBUTION_LIST_SET_IS_VALVE_MONITORING_CHANGE =
  'DISTRIBUTION_LIST_SET_IS_VALVE_MONITORING_CHANGE';
export const DELETE_DISTRIBUTION_LIST_FOR_PROJECT =
  'DELETE_DISTRIBUTION_LIST';
export const DISTRIBUTION_LISTS_RECEIVE_USERS_OF_CUSTOMER =
  'DISTRIBUTION_LISTS_RECEIVE_USERS_OF_CUSTOMER';
export const DISTRIBUTION_LISTS_RECEIVE_USERS_OF_PROJECT =
  'DISTRIBUTION_LISTS_RECEIVE_USERS_OF_PROJECT';
export const REQUEST_DISTRIBUTION_LIST_FOR_PROJECT =
  'REQUEST_DISTRIBUTION_LIST_FOR_PROJECT';
export const RECEIVE_DISTRIBUTION_LIST_FOR_PROJECT =
  'RECEIVE_DISTRIBUTION_LIST_FOR_PROJECT';
export const IQ100B_MOBILE_REPORTS_DATA_SET_VISIBILITY =
  'IQ100B_MOBILE_REPORTS_DATA_SET_VISIBILITY';

//Project actions.
export const INVALIDATE_PROJECT = 'INVALIDATE_PROJECT';
export const SELECT_PROJECT = 'SELECT_PROJECT';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const SET_UNIT = 'SET_UNIT';
export const SET_ZONE = 'SET_ZONE';
export const REQUEST_PROJECTS = 'REQUEST_PROJECTS';
export const REQUEST_PROJECT_ATTR = 'REQUEST_PROJECT_ATTR';
export const RECEIVE_PROJECT_ATTR = 'RECEIVE_PROJECT_ATTR';
export const VIEW_PROJECT = 'VIEW_PROJECT';
export const GET_CENTER_FROM_MAP = 'GET_CENTER_FROM_MAP';
export const UPDATE_PROJECT_REQ = 'UPDATE_PROJECT_REQ';
export const UPDATE_PROJECT_RES = 'UPDATE_PROJECT_RES';
export const SET_PROJECT_CENTER_RES = 'SET_PROJECT_CENTER_RES';
export const SORT_PROJECTS_LOCALY = 'SORT_PROJECTS_LOCALY';

export const RECEIVE_PROJECTS = 'RECEIVE_PROJECTS';
export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';

export const SET_FILTERS = 'SET_FILTERS';
export const SET_DEF_FILTERS = 'SET_DEF_FILTERS';
export const REQUEST_OPTIONS = 'REQUEST_OPTIONS';
export const RECEIVE_OPTIONS = 'RECEIVE_OPTIONS';

export const SET_NOISE_ALERTS_FILTERS = 'SET_NOISE_ALERTS_FILTERS';
export const SET_NOISE_ALERTS_DEF_FILTERS = 'SET_NOISE_ALERTS_DEF_FILTERS';

export const REQUEST_USERS = 'REQUEST_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const SORT_USERS_LOCALY = 'SORT_USERS_LOCALY';
export const SET_USERS_DEF_FILTERS = 'SET_USERS_DEF_FILTERS';
//Cell actions.
export const UPDATE_LEAK_DATA = 'UPDATE_LEAK_DATA';
export const UPDATE_STATUS = 'UPDATE_STATUS';

// export const REQUEST_LEAK_DETAILS = 'REQUEST_DETAILS';
// export const RECEIVE_LEAK_DETAILS = 'RECEIVE_LEAK_DETAILS';

export const REQUEST_LEAK_HISTORY = 'REQUEST_LEAK_HISTORY';
export const RECEIVE_LEAK_HISTORY = 'RECEIVE_LEAK_HISTORY';

export const REQUEST_LEAK_GROWTH = 'REQUEST_LEAK_GROWTH';
export const RECEIVE_LEAK_GROWTH = 'RECEIVE_LEAK_GROWTH';

export const RECEIVE_ALERTS_GROWING_DATA = 'RECEIVE_ALERTS_GROWING_DATA';
export const RECEIVE_ALERT_GROWTH_DATA = 'RECEIVE_ALERT_GROWTH_DATA';

export const REQUEST_SENSOR_HISTORY = 'REQUEST_SENSOR_HISTORY';
export const RECEIVE_SENSOR_HISTORY = 'RECEIVE_SENSOR_HISTORY';

export const REQUEST_LEAK_IMAGES = 'REQUEST_LEAK_IMAGES';
export const RECEIVE_LEAK_IMAGES = 'RECEIVE_LEAK_IMAGES';

// export const REQUEST_MOBILE_LEAK_DETAILS = 'REQUEST_MOBILE_LEAK_DETAILS';
// export const RECEIVE_MOBILE_LEAK_DETAILS = 'RECEIVE_MOBILE_LEAK_DETAILS';

export const REQUEST_SENSOR_IMAGES = 'REQUEST_SENSOR_IMAGES';
export const RECEIVE_SENSOR_IMAGES = 'RECEIVE_SENSOR_IMAGES';

export const REQUEST_LEAK_FILES = 'REQUEST_LEAK_FILES';
export const RECEIVE_LEAK_FILES = 'RECEIVE_LEAK_FILES';

export const REQUEST_DEVICE_LOG = 'REQUEST_DEVICE_LOG';
export const RECEIVE_DEVICE_LOG = 'RECEIVE_DEVICE_LOG';

export const REQUEST_SENSOR_DETAILS = 'REQUEST_SENSOR_DETAILS';
export const RECEIVE_SENSOR_DETAILS = 'RECEIVE_SENSOR_DETAILS';

export const REQUEST_SENSOR_LOGS = 'REQUEST_SENSOR_LOGS';
export const RECEIVE_SENSOR_LOGS = 'RECEIVE_SENSOR_LOGS';
export const SELECT_SENSOR_LOG = 'SELECT_SENSOR_LOG';

export const REQUEST_SENSOR_BITS = 'REQUEST_SENSOR_BITS';
export const RECEIVE_SENSOR_BITS = 'RECEIVE_SENSOR_BITS';
export const SELECT_SENSOR_BIT = 'SELECT_SENSOR_BIT';

export const REQUEST_SENSOR_NC = 'REQUEST_SENSOR_NC';
export const RECEIVE_SENSOR_NC = 'RECEIVE_SENSOR_NC';

export const REQUEST_SENSOR_NOISE_ALERT = 'REQUEST_SENSOR_NOISE_ALERT';
export const RECEIVE_SENSOR_NOISE_ALERT = 'RECEIVE_SENSOR_NOISE_ALERT';

export const SET_NOISE_SAMPLES_COUNT = 'SET_NOISE_SAMPLES_COUNT';

// SOPs actions:
export const REQUEST_SOPS = 'REQUEST_SOPS';
export const RECEIVE_SOPS = 'RECEIVE_SOPS';
export const SORT_SOPS = 'SORT_SOPS';
export const SET_SOPS_FILTERS = 'SET_SOPS_FILTERS';
export const SET_SOPS_DEF_FILTERS = 'SET_SOPS_DEF_FILTERS';
export const UPDATE_SOP_REQUEST = 'UPDATE_SOP_REQUEST';
export const UPDATE_SOP_RESPONSE = 'UPDATE_SOP_RESPONSE';
export const SET_SEFAULT_FOR_NEW_SOP = 'SET_SEFAULT_FOR_NEW_SOP';

// Interfernces actions
export const REQUEST_INTERFERENCES = 'REQUEST_INTERFERENCES';
export const RECEIVE_INTERFERENCES = 'RECEIVE_INTERFERENCES';
export const MARK_INTERFERENCES = 'MARK_INTERFERENCES';
export const CLEAN_INTERFERENCE_DETAILS = 'CLEAN_INTERFERENCE_DETAILS';
export const SET_INTERFERENCE_POLYGON_POINTS =
  'SET_INTERFERENCE_POLYGON_POINTS';
export const SET_INTERFERENCE_SETTINGS = 'SET_INTERFERENCE_SETTINGS';
export const SORT_INTERFERENCES_LOCALY = 'SORT_INTERFERENCES_LOCALY';
export const SORT_MOBILE_TASKS_LOCALLY = 'SORT_MOBILE_TASKS_LOCALLY';
// export const SET_ADDING_MANUAL_MOBILE_ALERTS_MODE = SET_ADDING_MANUAL_MOBILE_ALERTS_MODE;


// Water Meter actions:
export const REQUEST_WATER_METER_ALERTS = 'REQUEST_WATER_METER_ALERTS';
export const RECEIVE_WATER_METER_ALERTS = 'RECEIVE_WATER_METER_ALERTS';

export const REQUEST_NOISE_SAMPLES = 'REQUEST_NOISE_SAMPLES';
export const RECEIVE_NOISE_SAMPLES = 'RECEIVE_NOISE_SAMPLES';
export const SET_NOISE_SAMPLE_COLOR_SOURCE_ATTR =
  'SET_NOISE_SAMPLE_COLOR_SOURCE_ATTR';

export const REQUEST_IQUARIUS_PUBLISHED_SAMPLES =
  'REQUEST_IQUARIUS_PUBLISHED_SAMPLES';
export const RECEIVE_IQUARIUS_PUBLISHED_SAMPLES =
  'RECEIVE_IQUARIUS_PUBLISHED_SAMPLES';

export const RECEIVE_IQUARIUS_CUSTOMERS = 'RECEIVE_IQUARIUS_CUSTOMERS';
export const RECEIVE_IQUARIUS_TASKS = 'RECEIVE_IQUARIUS_TASKS';

export const SET_SELECTED_IQUARIUS_CUSTOMER = 'SET_SELECTED_IQUARIUS_CUSTOMER';
export const SET_SELECTED_TASK = 'SET_SELECTED_TASK';
export const RECEIVE_IQUARIUS_SAMPLES = 'RECEIVE_IQUARIUS_SAMPLES';

// Couples actions:
export const REQUEST_COUPLES = 'REQUEST_COUPLES';
export const RECEIVE_COUPLES = 'RECEIVE_COUPLES';

export const REQUEST_COUPLES_RM = 'REQUEST_COUPLES_RM';
export const RECEIVE_COUPLES_RM = 'RECEIVE_COUPLES_RM';

export const SORT_COUPLES = 'SORT_COUPLES';
export const SORT_COUPLES_LOCALY = 'SORT_COUPLES_LOCALY';
export const SET_COUPLES_FILTERS = 'SET_COUPLES_FILTERS';
export const SET_COUPLES_DEF_FILTERS = 'SET_COUPLES_DEF_FILTERS';

export const REQUEST_COPULE_PATH = 'REQUEST_COPULE_PATH';
export const RECEIVE_COPULE_PATH = 'RECEIVE_COPULE_PATH';

export const REQUEST_COUPLE_SAMPLES = 'REQUEST_COUPLE_SAMPLES';
export const RECEIVE_COUPLE_SAMPLES = 'RECEIVE_COUPLE_SAMPLES';

export const SELECT_COUPLE_SAMPLE = 'SELECT_COUPLE_SAMPLE';
export const REQUEST_COUPLE_SAMPLE_RESOURCES =
  'REQUEST_COUPLE_SAMPLE_RESOURCES';
export const RECEIVE_COUPLE_SAMPLE_RESOURCES =
  'RECEIVE_COUPLE_SAMPLE_RESOURCES';
export const SORT_COUPLE_SAMPLES = 'SORT_COUPLE_SAMPLES';

export const REQUEST_SENSOR_LINED_COUPLES = 'REQUEST_SENSOR_LINED_COUPLES';
export const RECEIVE_SENSOR_LINED_COUPLES = 'RECEIVE_SENSOR_LINED_COUPLES';

export const REQUEST_DELETE_COUPLE = 'REQUEST_DELETE_COUPLE';
export const RECEIVE_DELETE_COUPLE = 'RECEIVE_DELETE_COUPLE';

// Assessments actions:
export const REQUEST_COUPLES_ALERTS = 'REQUEST_COUPLES_ALERTS';
export const RECEIVE_COUPLES_ALERTS = 'RECEIVE_COUPLES_ALERTS';

export const REQUEST_COUPLE_SAMPLES_FOR_ALERT =
  'REQUEST_COUPLE_SAMPLES_FOR_ALERT';
export const RECEIVE_COUPLE_SAMPLES_FOR_ALERT =
  'RECEIVE_COUPLE_SAMPLES_FOR_ALERT';

export const CHANGE_ASSESSMENTS_MODE = 'CHANGE_ASSESSMENTS_MODE';
export const SELECT_COUPLE_ALERT = 'SELECT_COUPLE_ALERT';
export const ASSESSMENT_SET_SHOW_ONLY_REAL = 'ASSESSMENT_SET_SHOW_ONLY_REAL';

// Couples Coverage Paths
export const REQUEST_COUPLES_COVERAGE_PATHS = 'REQUEST_COUPLES_COVERAGE_PATHS';
export const RECEIVE_COUPLES_COVERAGE_PATHS = 'RECEIVE_COUPLES_COVERAGE_PATHS';

export const REQUEST_PROJECT_BOUNDARIES = 'REQUEST_PROJECT_BOUNDARIES';
export const RECEIVE_PROJECT_BOUNDARIES = 'RECEIVE_PROJECT_BOUNDARIES';

export const REQUEST_LEAK_COUPLES = 'REQUEST_LEAK_COUPLES';
export const RECEIVE_LEAK_COUPLES = 'RECEIVE_LEAK_COUPLES';

export const EDIT_ALERT_COMMENT = 'EDIT_ALERT_COMMENT'; // TODO: REMOVE IF NOT USE
export const UPDATE_LEAK_REQ = 'UPDATE_LEAK_REQ';
export const UPDATE_LEAK_RES = 'UPDATE_LEAK_RES';

export const UPDATE_SINGLE_LEAK_REQ = 'UPDATE_SINGLE_LEAK_REQ';

export const REFRESH_STATE = 'REFRESH_STATE';
export const ON_MAP_LAYER_CHANGE = 'ON_MAP_LAYER_CHANGE';
export const SET_MAP_DRAW_MODE = 'SET_MAP_DRAW_MODE';
export const SAVE_DRAWN_FEATURE = 'SAVE_DRAWN_FEATURE';

export const UPDATE_SENSOR_REQ = 'UPDATE_SENSOR_REQ';
export const UPDATE_SENSOR_RES = 'UPDATE_SENSOR_RES';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

export const INVALIDATE_LEAK = 'INVALIDATE_LEAK';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const UPLOAD_FILE_REQ = 'UPLOAD_FILE_REQ';
export const UPLOAD_FILE_RES = 'UPLOAD_FILE_RES';

export const UPDATE_USER_REQ = 'UPDATE_USER_REQ';
export const UPDATE_USER_RES = 'UPDATE_USER_RES';

export const DELETE_FILE_RES = 'DELETE_FILE_RES';
export const DELETE_FILE_REQ = 'DELETE_FILE_REQ';

// Management
export const EDIT_USER = 'EDIT_USER';
export const RECEIVE_CUSTOM_DETAILS = 'RECEIVE_CUSTOM_DETAILS';

export const CHANGE_MANAGE_CONTEXT = 'CHANGE_MANAGE_CONTEXT';
export const SELECT_USER = 'SELECT_USER';
export const ADD_NEW_MANAGE_ITEM = 'ADD_NEW_MANAGE_ITEM';
export const CLEAR_MANAGE_ITEM = 'CLEAR_MANAGE_ITEM';

export const SET_PROJECT_POLYGON_POINTS = 'SET_PROJECT_POLYGON_POINTS';

// Planing:
export const SET_COUPLE_EDIT_MODE = 'SET_COUPLE_EDIT_MODE';
export const CREATE_NEW_COUPLE = 'CREATE_NEW_COUPLE';
export const RESET_NEW_COUPLE = 'RESET_NEW_COUPLE';
export const SELECT_SENSOR_FOR_NEW_COUPLE = 'SELECT_SENSOR_FOR_NEW_COUPLE';
export const SET_EDIT_COUPLE_PATH_MODE = 'SET_EDIT_COUPLE_PATH_MODE';
export const SET_COUPLE_PATH_POINT = 'SET_COUPLE_PATH_POINT';
export const CLEAR_COUPLE_PATH = 'CLEAR_COUPLE_PATH';
export const SET_COUPLE_PATH_READY = 'SET_COUPLE_PATH_READY';
export const SET_COUPLE_PATH_LENGTH = 'SET_COUPLE_PATH_LENGTH';
export const SETUP_NEW_COUPLE = 'SETUP_NEW_COUPLE';
export const UPDATE_COUPLE_PATH_POINTS = 'UPDATE_COUPLE_PATH_POINTS';
export const CLEAR_ALL_NEW_COUPLE_DATA = 'CLEAR_ALL_NEW_COUPLE_DATA';
export const GENERATE_COUPLE_REQUEST = 'GENERATE_COUPLE_REQUEST';
export const GENERATE_COUPLE_RECEIVE = 'GENERATE_COUPLE_RECEIVE';
export const REQUEST_PROJECT_PIPES = 'REQUEST_PROJECT_PIPES';
export const RECEIVE_PROJECT_PIPES = 'RECEIVE_PROJECT_PIPES';
export const REQUEST_SINGLE_COUPLE = 'REQUEST_SINGLE_COUPLE';
export const RECEIVE_SINGLE_COUPLE = 'RECEIVE_SINGLE_COUPLE';

//Install
export const CLEAR_INSTALL_ITEM = 'CLEAR_INSTALL_ITEM';
export const ADD_NEW_INSTALL_ITEM = 'ADD_NEW_INSTALL_ITEM';
export const CHANGE_INSTALL_CONTEXT = 'CHANGE_INSTALL_CONTEXT';
export const SELECT_SOP = 'SELECT_SOP';
export const SELECT_SENSOR = 'SELECT_SENSOR';
export const REQUEST_SOP_RESOURCES = 'REQUEST_SOP_RESOURCES';
export const RECEIVE_SOP_RESOURCES = 'RECEIVE_SOP_RESOURCES';
export const SET_NEW_SOP_TEMP_POSITION = 'SET_NEW_SOP_TEMP_POSITION';
export const REQUEST_CONFIGS = 'REQUEST_CONFIGS';
export const RECEIVE_CONFIGS = 'RECEIVE_CONFIGS';
export const SET_SENSORS_UNINSTALL_MODE = 'SET_SENSORS_UNINSTALL_MODE';
export const SET_SENSOR_FOR_UNINSTALL_DETAILS =
  'SET_SENSOR_FOR_UNINSTALL_DETAILS';
export const SET_INSTALL_ACTION_WORKING_STATE =
  'SET_INSTALL_ACTION_WORKING_STATE';
export const INSTALL_ACTION_DONE_SUCCESSFULLY =
  'INSTALL_ACTION_DONE_SUCCESSFULLY';

export const REQUEST_SONSOR_ON_SOP_DETAILS = 'REQUEST_SONSOR_ON_SOP_DETAILS';
export const RECEIVE_SONSOR_ON_SOP_DETAILS = 'RECEIVE_SONSOR_ON_SOP_DETAILS';
// Pressure:
export const REQUEST_SENSOR_PRS = 'REQUEST_SENSOR_PRS';
export const RECEIVE_SENSOR_PRS = 'RECEIVE_SENSOR_PRS';

export const REQUEST_SENSOR_TRAN = 'REQUEST_SENSOR_TRAN';
export const RECEIVE_SENSOR_TRAN = 'RECEIVE_SENSOR_TRAN';

export const REQUEST_SENSORS_WITH_ACTUAL_PRESSURE_TRANSIENT =
  'REQUEST_SENSORS_WITH_ACTUAL_PRESSURE_TRANSIENT';
export const RECEIVE_SENSORS_WITH_ACTUAL_PRESSURE_TRANSIENT =
  'RECEIVE_SENSORS_WITH_ACTUAL_PRESSURE_TRANSIENT';

export const CHANGE_PRESSURE_DISPLAY_MODE = 'CHANGE_PRESSURE_DISPLAY_MODE';
export const SET_OPEN_DISTRIBUTOR_PROJECTS_MODAL =
  'SET_OPEN_DISTRIBUTOR_PROJECTS_MODAL';

export const REQUEST_DISTRIBUTOR_PROJECTS = 'REQUEST_DISTRIBUTOR_PROJECTS';
export const RECEIVE_DISTRIBUTOR_PROJECTS = 'RECEIVE_DISTRIBUTOR_PROJECTS';

export const ASSESSMENTS_REPORT_REQUEST = 'ASSESSMENTS_REPORT_REQUEST';
export const ASSESSMENTS_REPORT_RECEIVE = 'ASSESSMENTS_REPORT_RECEIVE';

export const SAVE_TEMP_ADDRESS_FOR_ITEM = 'SAVE_TEMP_ADDRESS_FOR_ITEM';

export const SET_PCA_OPEN = 'SET_PCA_OPEN';
export const SET_PCA_LEAK_GROWTH_OPEN = 'SET_PCA_LEAK_GROWTH_OPEN';
export const SET_STATUS_OPEN = 'SET_STATUS_OPEN';

export const SET_ALERT_TYPE = 'SET_ALERT_TYPE';
export const SET_SINGLE_ALERT_TYPE = 'SET_SINGLE_ALERT_TYPE';

export const RECEIVE_PCA_DATA_FOR_ALERT = 'RECEIVE_PCA_DATA_FOR_ALERT';

export const CLEAR_TEMP_PCA = 'CLEAR_TEMP_PCA';
export const CLEAR_TEMP_STATUS = 'CLEAR_TEMP_STATUS';

export const REQUEST_TOTAL_PIPES_LENGTH = 'REQUEST_TOTAL_PIPES_LENGTH';
export const RECEIVE_TOTAL_PIPES_LENGTH = 'RECEIVE_TOTAL_PIPES_LENGTH';

export const SELECT_SENSOR_SAMPLE = 'SELECT_SENSOR_SAMPLE';
export const RECEIVE_SENSOR_SAMPLE_WAVS_PATHS =
  'RECEIVE_SENSOR_SAMPLE_WAVS_PATHS';

// DMAs:
export const REQUEST_DMAS = 'REQUEST_DMAS';
export const RECEIVE_DMAS = 'RECEIVE_DMAS';
export const SET_DMA_DATA_DISPLAY = 'SET_DMA_DATA_DISPLAY';
export const REQUEST_DMA_DATA = 'REQUEST_DMA_DATA';
export const RECEIVE_DMA_DATA = 'RECEIVE_DMA_DATA';

// INSPECT
export const RECEIVE_ALERTS_INVESTIGATION = 'RECEIVE_ALERTS_INVESTIGATION';
export const REQUEST_ALERTS_INVESTIGATION = 'REQUEST_ALERTS_INVESTIGATION';
export const INSPECT_SELECT_FILTERED_ALERTS = 'INSPECT_SELECT_FILTERED_ALERTS';

export const RECEIVE_PIPES_INVESTIGATION = 'RECEIVE_PIPES_INVESTIGATION';
export const REQUEST_PIPES_INVESTIGATION = 'REQUEST_PIPES_INVESTIGATION';

export const SET_ALERTS_DATA_FOR_CATEGORY = 'SET_ALERTS_DATA_FOR_CATEGORY';

export const SAVE_INVESTIGATION_ANALYSIS_DATA =
  'SAVE_INVESTIGATION_ANALYSIS_DATA';
export const SORT_INVESTIGATION_ALERTS_LOCALY =
  'SORT_INVESTIGATION_ALERTS_LOCALY';

// PRESSURE TRANSIENT ALERTS:

export const REQUEST_PRESSURE_TRANSIENT_ALERTS =
  'REQUEST_PRESSURE_TRANSIENT_ALERTS';
export const RECEIVE_PRESSURE_TRANSIENT_ALERTS =
  'RECEIVE_PRESSURE_TRANSIENT_ALERTS';
export const CHANGE_ALERTS_CONTEXT = 'CHANGE_ALERTS_CONTEXT';
export const SORT_PRESSURE_ALERTS_LOCALY = 'SORT_PRESSURE_ALERTS_LOCALY';

// Samples Output Data

export const REQUEST_SAMPLE_OUTPUT_DATA = 'REQUEST_SAMPLE_OUTPUT_DATA';
export const RECEIVE_SAMPLE_OUTPUT_DATA = 'RECEIVE_SAMPLE_OUTPUT_DATA';
export const REQUEST_DEVICE_MESSAGE_DATA = 'REQUEST_DEVICE_MESSAGE_DATA';
export const RECEIVE_DEVICE_MESSAGE_DATA = 'RECEIVE_DEVICE_MESSAGE_DATA';
export const REQUEST_DEVICE_PROBABILITY_DATA =
  'REQUEST_DEVICE_PROBABILITY_DATA';
export const RECEIVE_DEVICE_PROBABILITY_DATA =
  'RECEIVE_DEVICE_PROBABILITY_DATA';
export const REQUEST_TENSOR_DATA = 'REQUEST_TENSOR_DATA';
export const RECEIVE_TENSOR_DATA = 'RECEIVE_TENSOR_DATA';
export const SET_TRANSIENTS_ALERTS_DATES_RANGE =
  'SET_TRANSIENTS_ALERTS_DATES_RANGE';
export const REQUEST_TRANSIENTS_ALERT_SAMPLES =
  'REQUEST_TRANSIENTS_ALERT_SAMPLES';
export const RECEIVE_TRANSIENTS_ALERT_SAMPLES =
  'RECEIVE_TRANSIENTS_ALERT_SAMPLES';
export const SELECT_TRANSIENT_ALERT_SAMPLE = 'SELECT_TRANSIENT_ALERT_SAMPLE';
export const UPDATE_AUDIO_CLASSIFICATION = 'UPDATE_AUDIO_CLASSIFICATION';

export const GRAPHS = {
  CLEAN: 'CLEAN_ALL_GRAPHS_DATA',
  SET_RAW_DATA: 'SET_GRAPH_RAW_DATA',
  SET_SENSORS_POSITIONS: 'SET_SENSORS_POSITIONS',
  SET_MAX_PROBABILITIES: 'SET_MAX_PROBABILITIES_POSITIONS',
  SET_GRAPH_DISTANCES_DATA: 'SET_GRAPH_DISTANCES_DATA',
  SET_GRAPH_DISTANCES_DATA_SENSORS_ONLY:
    'SET_GRAPH_DISTANCES_DATA_SENSORS_ONLY',
  SET_INSTANCE_CORRELATION: 'SET_INSTANCE_CORRELATION',
  SET_INSTANCE_HISTOGRAM: 'SET_INSTANCE_HISTOGRAM',
  SET_AVE_CORRELATION: 'SET_AVE_CORRELATION',
  SET_AVE_HISTOGRAM: 'SET_AVE_HISTOGRAM',
  SET_HISTORY: 'SET_HISTORY',
  SET_HISTORY_NOISE_DATA: 'SET_HISTORY_NOISE_DATA',
  SET_SIGNALS: 'SET_SIGNALS',
  SET_TREND: 'SET_TREND',
  SET_BIAS: 'SET_BIAS',
  SET_RADIO_CORRELATION: 'SET_RADIO_CORRELATION',
  SET_MAX_PROB_GRAPH: 'SET_MAX_PROB_GRAPH',
  // SET_INCLUDED: 'SET_INCLUDED'
};

export const DEVICE_CONFIGURATION = {
  REQUEST_DEVICE_LIST: 'REQUEST_DEVICE_LIST',
  RECEIVE_DEVICE_LIST: 'RECEIVE_DEVICE_LIST',
  SORT_G5_DEVICES: 'SORT_G5_DEVICES',
  SET_DEVICES_SELECTION: 'SET_DEVICES_SELECTION',
  // SELECT_MULTIPLE_DEVICES: 'SELECT_MULTIPLE_DEVICES',
  REQUEST_DEVICE_BIT_INFO: 'DEVICE_CONFIGURATION_REQUEST_DEVICE_BIT_INFO',
  RECEIVE_DEVICE_BIT_INFO: 'DEVICE_CONFIGURATION_RECEIVE_DEVICE_BIT_INFO',
  RECEIVE_G5_VERSIONS: 'DEVICE_CONFIGURATION_RECEIVE_G5_VERSIONS',
  RECEIVE_G5_ULTRA_VERSIONS: 'DEVICE_CONFIGURATION_RECEIVE_G5_ULTRA_VERSIONS',
  RECEIVE_G5_MODEM_VERSIONS: 'DEVICE_CONFIGURATION_RECEIVE_G5_MODEM_VERSIONS',
  RECEIVE_G5_CONFIGURATION_LOGS: 'DEVICE_CONFIGURATION_RECEIVE_G5_CONFIGURATION_LOGS',
  SET_DEFAULT_PROJECT_VALUES: 'SET_DEFAULT_PROJECT_VALUES',
};
