import React from 'react';
import PropTypes from 'prop-types';

import DeleteModal from '../CouplesTabs/DetailsTab/DeleteModal';
import Input from '../Input/Input';
import Select from '../Select/Select';
import FieldTimezonePicker from '../Custom/FieldTimezonePicker';
import CustomerDistributionListsModal from '../CustomersTabs/CustomerDistributionListsModal';
import ProjectAdvancedForm from './ProjectAdvancedForm';
import { Field, reduxForm } from 'redux-form';
import { SYSTEM_RESOURCE } from '../../constants/EndPoints';
import timezones from '../../constants/timezones';

// Import the timezones JSON file (adjust the path as needed)

require('./Projects.scss');

class ProjectDetails extends React.Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleDrawMode = this.toggleDrawMode.bind(this);
    this.renderBasicParams = this.renderBasicParams.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);

    this.state = {
      page: 1,
      advanced: false,
      distributionListManageOpen: false,
    };
  }

  openModal() {
    this.setState({
      distributionListManageOpen: true
    });
  }

  closeModal() {
    this.setState({
      distributionListManageOpen: false
    });
  }

  toggleDrawMode() {
    this.props.changeDrawMode(this.props);
  }

  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  required = (value) => {
    const error = value ? undefined : this.context.t('required');
    return error;
  }

  renderBasicParams() {
    const {
      handleSubmit,
      user,
      customerOptions,
      pristine,
      submitting,
      isNew,
      projectOptions,
      mapDrawMode,
      projectCurrentBounding,
      userEditMode
    } = this.props;
    const labelClassName = 'col-xs-4 control-label';
    const lsbelClassNameLeftColumn = 'col-xs-6 control-label';
    const controlClassName = 'col-xs-6 control-input';
    const fieldsClass = 'col-xs-6 control-input';
    const fieldsClassLeftColumn = 'col-xs-6 control-input';
    const divStyle = { float: 'left', width: '100%' };
    const imperialStyle = { width: '20% !important', height: '20px !important' };
    const buttonTitle = isNew ? this.context.t('create_new_project') : this.context.t('update_project_details');
    const drawTitle = this.props.projectCurrentBounding ? "Edit" : "Draw";

    return (
      <form className="form-horizontal sticky-actions" onSubmit={handleSubmit}>
        <div style={{ display: 'flex' }}>
          <div style={divStyle}>
            <div className="form-group">
              <label className={lsbelClassNameLeftColumn}>
                {this.context.t('project_name')}:
              </label>
              <div className={fieldsClassLeftColumn}>
                <Field
                  name="ShortName"
                  component={Input}
                  type="text"
                  className="form-control"
                  validate={this.required}
                  disabled={!user.editMode}
                />
              </div>
            </div>

            <div className="form-group">
              <label className={lsbelClassNameLeftColumn}>
                {this.context.t('epsg')}:
              </label>
              <div className={fieldsClassLeftColumn}>
                <Field
                  name="EPSG"
                  placeholder={this.context.t('meteric_feet_epsg_only')}
                  component={Input}
                  type="text"
                  className="form-control"
                  validate={this.required}
                  disabled={!isNew}
                />
              </div>
            </div>

            <div className="form-group">
              <label
                className={lsbelClassNameLeftColumn}
                title={this.context.t('sensor_activity_threshold')}
              >
                {this.context.t('sensor_activity_threshold')}:
              </label>
              <div className={fieldsClassLeftColumn}>
                <Field
                  name="SensorActivityRatioDaysBack"
                  component={Input}
                  type="number"
                  className="form-control"
                  disabled={!user.editMode}
                />
              </div>
            </div>

            {/* New Timezone Field with GMT offset */}
            <div className="form-group">
              <label className={lsbelClassNameLeftColumn}>
                {this.context.t('project_timezone')}:
              </label>
              <div className={fieldsClassLeftColumn}>
                <Field
                  name="TimezoneName"
                  component={(props) => (
                    <Select
                      {...props}
                      placeholderTitle={this.context.t('select_timezone')}
                      clearable={false}
                      backspaceRemoves={false}
                      multi={false}
                      options={timezones.map(tz => ({
                        value: tz.name,
                        label: `${tz.label} - GMT ${tz.offset >= 0 ? '+' : ''}${tz.offset}`
                      }))}
                      // disabled={!isNew}
                    />
                  )}
                />
              </div>
            </div>

            {/* Draw Mode Button */}
            {!isNew &&
              <div className="form-group">
                <label className={lsbelClassNameLeftColumn}>
                  {this.context.t(`Click to ${drawTitle} project area`)}:
                </label>
                <div className={controlClassName}>
                  <a onClick={this.toggleDrawMode} className="btn btn-info"> {drawTitle} </a>
                </div>
              </div>
            }

            {!isNew &&
              <div className="form-group">
                <label className="col-xs-10 control-label">
                  {this.context.t('click_to_set_current_map_position_as_project_center')}:
                </label>
                <div className="col-xs-2 control-input">
                  <img
                    src={require('./../../../images/ic_center_of_project.png')}
                    className="btn btn-info center"
                    onClick={() => {
                      this.props.setProjectCenter();
                    }}
                  />
                </div>
              </div>
            }

            {SYSTEM_RESOURCE !== 'trimble' &&
              <div className="form-group">
                <label className="col-xs-6 control-label">
                  {this.context.t('use_imperial_units_in_graphs')}:
                </label>
                <div className={controlClassName}>
                  <Field
                    name="UseFeetInMatlabGraphs"
                    component={Input}
                    type="checkbox"
                    className={imperialStyle}
                    disabled={!user.editMode}
                  />
                </div>
              </div>
            }
          </div>

          <div style={divStyle}>
            <div className="form-group">
              <label className={labelClassName}>
                {this.context.t('customer')}:
              </label>
              <div className={fieldsClass}>
                <Field
                  name="CustomerID"
                  component={(props) => (
                    <Select
                      {...props}
                      placeholderTitle={this.context.t('select')}
                      clearable={false}
                      backspaceRemoves={false}
                      multi={false}
                      options={customerOptions}
                    />
                  )}
                />
              </div>
            </div>

            <div className="form-group">
              <label className={labelClassName}>
                {this.context.t('mode')}:
              </label>
              <div className={fieldsClass}>
                <Field
                  name="PilotMode"
                  component={(props) => (
                    <Select
                      {...props}
                      placeholderTitle={this.context.t('select')}
                      clearable={false}
                      backspaceRemoves={false}
                      multi={false}
                      options={projectOptions}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {user.isAQS &&
          <a
            style={{ right: '6px', position: 'absolute' }}
            className="btn btn-info"
            onClick={this.nextPage}
          >
            {this.context.t('advanced')}
          </a>
        }
        {userEditMode &&
          <div className="form-actions">
            {!isNew &&
              <a
                style={{ float: 'left', marginRight: '2px' }}
                className="btn btn-info"
                onClick={() => {
                  this.props.updateProjectPipes();
                }}
              >
                {this.context.t('update_pipes')}
              </a>
            }

            {!isNew &&
              <>
                <DeleteModal
                  id={this.props.initialValues.ID}
                  name={this.props.initialValues.Name}
                  type={'project'}
                  onDelete={this.props.onDelete}
                />
                {JSON.parse(process.env.REACT_APP_DASHBOARD) &&
                  <a
                    style={{
                      float: 'left',
                      pointerEvents: !user.isAQS ? 'none' : 'auto',
                      opacity: !user.isAQS ? 0.5 : 1,
                    }}
                    className='btn btn-info btn-submit'
                    onClick={!user.isAQS ? null : this.openModal}
                  >
                    {this.context.t('manage_distribution_lists')}
                  </a>
                }
                <CustomerDistributionListsModal
                  open={this.state.distributionListManageOpen}
                  closeFunction={this.closeModal}
                  notify={this.props.notify}
                  isProjectLevel={true}
                />
              </>
            }

            <button
              className="btn btn-success btn-submit"
              disabled={(this.props.projectEditedPoints ? submitting : pristine || submitting)}
              type="submit"
            >
              {buttonTitle}
            </button>
          </div>
        }
      </form>
    );
  }

  render() {
    const { user } = this.props;
    const { page } = this.state;
    return (
      <div>
        {page === 1 && this.renderBasicParams()}
        {user.isAQS && page === 2 && <ProjectAdvancedForm previousPage={this.previousPage} />}
      </div>
    );
  }
}

ProjectDetails.contextTypes = {
  t: PropTypes.func.isRequired
};

const projectDetails = reduxForm({
  form: 'projectDetails', // a unique name for this form
  enableReinitialize: true,
})(ProjectDetails);

export default projectDetails;
