import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import { BarLoader } from 'react-spinners';
import { InstallOperationMode, UninstallMode } from '../../constants/Misc';
import AddressField from '../Custom/AddressField';
import Input from '../Input/Input';
import Select from '../Select/Select';
import CommentField from '../CommentField/CommentField';
import UninstallSensorModal from '../Modals/UninstallSensorModal';

class SOPsOperationsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isG5: false,
    };

    this.openActualInstallationPointLocation =
      this.openActualInstallationPointLocation.bind(this);
  }
  openActualInstallationPointLocation() {
    this.props.openActualInstallationPointLocation();
  }
  render() {
    const {
      mode,
      uninstallMode,
      uninstallDetails,
      isButtonEnabled,
      isFetchingDetails,
      isDeviceFieldEnable,
      isWorking,
      initialValues,
      selectedFeature,
      selectedFeatureType,
      user,
      sensorTypes,
      syncTypes,
      deviceGenerationType,
      configs,
      pristine,
      submitting,
      error,
      handleSubmit,
      dispatch,
    } = this.props;

    let buttonTitle = '';
    const bIsFieldsEnable = mode != InstallOperationMode.SOP_ONLY;
    let bButtonDisable = true;
    if (!isFetchingDetails) {
      if (mode == InstallOperationMode.SOP_ONLY) {
        bButtonDisable = !isButtonEnabled;
      } else {
        bButtonDisable = !isButtonEnabled || pristine || submitting;
      }
    }

    if (!isFetchingDetails) {
      switch (mode) {
        case InstallOperationMode.SOP_ONLY:
          buttonTitle = this.context.t('add_sensor');
          break;
        case InstallOperationMode.SOP_AND_SENSOR:
          buttonTitle = this.context.t('install_device');
          break;
        case InstallOperationMode.SOP_AND_SENSOR_AND_DEVICE:
          buttonTitle = this.context.t('update');
          break;
      }
    }

    console.log('selectedFeature', selectedFeatureType);

    return (
      <form className='form-horizontal sticky-actions' onSubmit={handleSubmit}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }}
        >
          <BarLoader color='#4cae4c' width='110%' loading={isWorking} />
        </div>
        <CommentField
          fieldName='Comment'
          featureID={selectedFeature}
          placeholder={this.context.t('sensor_comment')}
          onEdit={this.props.onEdit}
          onCopy={this.props.onCopy}
          disabled={!user.editMode}
        />
        <div className='form-group'>
          <label className='col-xs-2 control-label'>
            {this.context.t('device_id')}:
          </label>
          <div className='col-xs-10'>
            <Field
              className='form-control'
              name='DeviceID'
              component={Input}
              type='number'
              maxlength={15}
              size={15}
              disabled={!user.editMode || !isDeviceFieldEnable}
            />
          </div>
        </div>
        <div className='form-group'>
          <AddressField
            user={this.props.user}
            fieldName='StreetAddress'
            onGetAddress={this.props.getAddressByLatLong}
          />
        </div>
        <div className='form-group'>
          <label className='col-xs-2 control-label'>
            {this.context.t('config')}:
          </label>
          <div className='col-xs-10 control-input'>
            <Field
              name='Template'
              disabled={!user.editMode}
              component={(props) => (
                <Select
                  {...props}
                  key={'Template'}
                  placeholderTitle={this.context.t('select')}
                  options={configs}
                />
              )}
            />
          </div>
        </div>
        <div className='inline-group'>
          <label className='col-xs-4 control-label'>
            {this.context.t('under_over')}:
          </label>
          <div className='col-xs-8 control-input'>
            <Field
              name='isUnderground'
              disabled={!user.editMode}
              component={(props) => (
                <Select
                  {...props}
                  key={'isUnderground'}
                  placeholderTitle={this.context.t('select')}
                  options={[
                    { value: 0, label: this.context.t('overground') },
                    { value: 1, label: this.context.t('underground') },
                  ]}
                />
              )}
            />
          </div>
        </div>
        <div className='inline-group'>
          <label className='col-xs-4 control-label'>
            {this.context.t('type')}:
          </label>
          <div className='col-xs-8 control-input'>
            <Field
              name='SensorType'
              disabled={!user.editMode}
              component={(props) => (
                <Select
                  {...props}
                  key={'SensorType'}
                  placeholderTitle={this.context.t('select')}
                  options={sensorTypes}
                />
              )}
            />
          </div>
        </div>
        <div className='inline-group'>
          <label className='col-xs-4 control-label'>
            {this.context.t('sync')}:
          </label>
          <div className='col-xs-8 control-input'>
            <Field
              name='SyncMethod'
              disabled={!user.editMode}
              component={(props) => (
                <Select
                  {...props}
                  key={'SyncMethod'}
                  placeholderTitle={this.context.t('select')}
                  options={syncTypes}
                />
              )}
            />
          </div>
        </div>

        <div className='inline-group'>
          <label className='col-xs-4 control-label'>
            {this.context.t('generation')}:
          </label>
          <div className='col-xs-8 control-input'>
            <Field
              name='DeviceGenerationValue'
              component={(props) => (
                <Select
                  {...props}
                  onChangeHandler={(generationValue) => {this.setState({ isG5: generationValue === 1 })}}
                  value={this.props.selectedGenerationType}
                  key={'DeviceGenerationValue'}
                  placeholderTitle={this.context.t('select')}
                  options={deviceGenerationType}
                />
              )}
            />
          </div>
        </div>

        <div className='inline-group'>
          <label className='col-xs-4 control-label'>
            {this.context.t('is_pressure')}:
          </label>
          <div className='col-xs-8 control-input'>
            <Field
              key={'IsPressure'}
              style={{ width: '30px' }}
              className='checkbox-control'
              name='IsPressure'
              component={Input}
              type='checkbox'
              maxlength={15}
              size={15}
              disabled={!user.editMode}
            />
          </div>
        </div>

        {this.state.isG5 && (
          <div className='inline-group'>
            <label className='col-xs-4 control-label'>
              {this.context.t('is_mini')}:
            </label>
            <div className='col-xs-8 control-input'>
              <Field
                key={'isMini'}
                style={{ width: '30px' }}
                className='checkbox-control'
                name='isMini'
                component={Input}
                type='checkbox'
                maxlength={15}
                size={15}
                disabled={!user.editMode}
              />
            </div>
          </div>
        )}


        <div className='form-actions'>
          {buttonTitle != '' && (
            <button className='btn btn-success btn-submit' type='submit'>
              {buttonTitle}
            </button>
          )}
          {mode == InstallOperationMode.SOP_AND_SENSOR_AND_DEVICE && (
            <a
              className='btn btn-warning'
              onClick={() => {
                this.props.setUninstallMode(UninstallMode.NORMAL);
              }}
            >
              {this.context.t('uninstall_device')}
            </a>
          )}
          {/*<div className='form-feedback text-danger'>{error}</div>*/}
          {user.isAQS && selectedFeatureType === 'sensor' &&(
          <a
            className='btn btn-warning btn-order'
            onClick={this.openActualInstallationPointLocation}
          >
            {this.context.t('set_actual_installation_point_position')}
          </a>
          )}
          {mode == InstallOperationMode.SOP_AND_SENSOR && (
            <>
              <a
                className='btn btn-danger'
                onClick={() => {
                  this.props.setUninstallMode(UninstallMode.JUST_REMOVE_SENSOR);
                }}
              >
                {this.context.t('remove_sensor')}
              </a>

              {initialValues?.LastUninstallReason !== 14 && (
                <a
                  style={{ marginLeft: '5px' }}
                  className='btn btn-info'
                  onClick={() => {
                    this.props.setAsArchived();
                  }}
                >
                  {this.context.t('archived')}
                </a>
              )}
            </>
          )}
        </div>

        <UninstallSensorModal
          onClose={() => {
            this.props.setUninstallMode(UninstallMode.NONE);
          }}
          mode={uninstallMode}
          details={uninstallDetails}
          notify={this.props.notify}
        />
      </form>
    );
  }
}

SOPsOperationsTab.contextTypes = {
  t: PropTypes.func.isRequired,
};

const validate = (values, props) => {

  const errors = {};
  if (props.mode != InstallOperationMode.SOP_ONLY) {
    if (values.DeviceID == null) {
      errors.DeviceID = 'Required! should contains 15 digits***';

    } else if (values.DeviceID.length != 15) {
      errors.DeviceID = 'Device ID should contains 15 digits';
    }

    if (values.Template == null || values.Template.toString() == '') {
      if (values.DeviceGenerationValue === 0) {
        errors.Template = 'Required!';
      }
    }
    if (values.isUnderground == null || values.isUnderground.toString() == '') {
      errors.isUnderground = 'Required!';
    }
    if (values.SensorType == null || values.SensorType.toString() == '') {
      errors.SensorType = 'Required!';
    }
    if (values.SyncMethod == null || values.SyncMethod.toString() == '') {
      errors.SyncMethod = 'Required!';
    }
  }

  return errors;
};

// Decorate the form component
SOPsOperationsTab = reduxForm({
  form: 'sopOperationsForm', // a unique name for this form
  enableReinitialize: true,
  touchOnBlur: false,
  validate,
})(SOPsOperationsTab);
export default SOPsOperationsTab;

