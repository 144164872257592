import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { makeStyles } from '@mui/styles';

// import './style.scss';

const useStyle = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  title: {
    left: '0px',
    position: 'absolute',
    minInlineSize: 'fit-content',
  },
  tabs: {
    maxWidth: '75%',
    zIndex: 90,
  },
  tabsWithoutTitle: {
    // boxShadow: '0px 0px 2px 2px lightslategrey',
    // backgroundColor: 'azure',
    zIndex: 90,
  },
  tab: {
    minWidth: '140px',
    maxWidth: 'inherit',
    fontSize: '1.5rem',
    textTransform: 'none',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
  },
  tabWhite: {
    minWidth: '140px',
    maxWidth: 'inherit',
    fontSize: '1.5rem',
    textTransform: 'none',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
    color: 'white'
  },
  closeButton: {
    position: 'absolute',
    top: '3px',
    right: 0
  }
});

export default function TabsContainer(props) {
  const classes = useStyle();

  const [selectedTab, setSelectedTab] = React.useState(0);

  React.useLayoutEffect(() => {
    if (props.title === 'New Couple') {
      // debugger;
    }
    setSelectedTab(0);
  }, [props.tabs.length]);

  // eslint-disable-next-line no-unused-vars
  const handleTabChange = (_event, newValue) => {
    if (props.onTabChange) {
      props.onTabChange(selectedTab, newValue);
    }

    setSelectedTab(newValue);
  };

  if (props.title === 'New Couple') {
    // debugger;
  }

  const justify = props.justifyCenter ? 'center' : 'flex-end';

  return (
    <Paper
      elevation={1}
      className={classes.root}
    >

      <Box display="flex" justifyContent={justify} style={props.tabsContainerStyle || null}>

        { props.title &&
          <Typography className={classes.title} variant='h2'>{props.title}</Typography>
        }
        <Tabs
          className={(props.title) ? classes.tabs : classes.tabsWithoutTitle}
          value={(props.tabs.length > selectedTab) ? selectedTab : 0}
          indicatorColor="primary"
          // textColor={props.textColor || "primary"}
          onChange={handleTabChange}
          aria-label={'tabs_container'}
          variant='scrollable'
          style={props.tabsButtonsStyle || null}
        >

          {
            props.tabs.map((tab, index) => (
              <Tab
                classes={{root: props.whiteTabs ? classes.tabWhite : classes.tab}}
                key={index}
                value={index}
                label={tab.label}
              />
            ))
          }
        </Tabs>
        {props.handleClose &&
          <IconButton onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        }
      </Box>

      {
        props.tabs.map((tab, index) => (
          <div
            key={index}
            role="tabpanel"
            hidden={selectedTab !== index}
            id={`app-tabpanel-${index}`}
            aria-labelledby={`app-tab-${index}`}
            style={(tab.style) ? tab.style : { maxHeight: '355px', overflowY: 'auto' }}
          >
            { selectedTab === index && (
              <Box style={{maxHeight: 'inherit'}}>
                {tab.component}
              </Box>
            )}
          </div>
        ))
      }

    </Paper>
  );
}

TabsContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  handleClose: PropTypes.func,
  justifyCenter: PropTypes.bool,
  onTabChange: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    component: PropTypes.node.isRequired,
    style: PropTypes.object,
  })).isRequired,
  tabsButtonsStyle: PropTypes.object,
  tabsContainerStyle: PropTypes.object,
  title: PropTypes.string,
};
