// import * as authCogService from './authCognito';
// import * as authFireService from './authFirebase';

// export default process.env.REACT_APP_AUTH_PROVIDER === 'cognito' ? authCogService : authFireService;

import * as dotenv from 'dotenv';
dotenv.config();

let authService;
if (process.env.REACT_APP_AUTH_PROVIDER === 'cognito') {
  authService = require('./authCognito');
} else {
  authService = require('./authFirebase');
}

export default authService;