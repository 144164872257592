import React from 'react';
import { zIndexs, featuresTypes } from './Options';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

import { Style, Fill, Stroke, Text } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer} from 'ol/layer';
import { Vector as VectorSource} from 'ol/source';
import { Polygon, MultiPolygon } from 'ol/geom';
const proj = require('ol/proj');


const source = new VectorSource();

export const layer = new VectorLayer({
    name: featuresTypes.DMAS,
    source: source,
    zIndex: zIndexs.projectsCoverage,
  });

export const setSource = (dmas, props) => {
  let iconFeatures = [];

  dmas.forEach((dma) => {
    if (dma.Boundary != null) {
        var points = [];

        const loopFunction = (data) => {
          let polygonPointsArray = [];

          // if (Array.isArray(data[0][0])) {
          if (Array.isArray(data[0][0])) {
            data.forEach((array) => {
              let arrayPoints = loopFunction(array);
              polygonPointsArray.push(arrayPoints);
            });
          } else {
            let pointsArray = [];
            data.forEach((itrPoint) => {
              let point = proj.transform([itrPoint[0], itrPoint[1]],'EPSG:4326','EPSG:3857');
              polygonPointsArray.push(point);
            });
            //polygonPointsArray.push(pointsArray);
          }

          return polygonPointsArray;
        };

        // dma.Boundary.forEach((point) => {
        //   const p = proj.transform([point[0], point[1]],'EPSG:4326','EPSG:3857');
        //   points.push(p);
        // });

        points = loopFunction(dma.Boundary);

        let dmaGeometry;
        if (points.length > 1) {
          let innerPoints = [];
          points.forEach((polygonPoints, index) => {
            if (index == 0) {
              innerPoints.push(polygonPoints[0][0]);
            } else {
              innerPoints.push(polygonPoints[0]);
            }
          });
          dmaGeometry = new MultiPolygon(innerPoints);
        } else {
          dmaGeometry = new Polygon(points[0][0]);
        }
        const iconFeature = new Feature({
          geometry: dmaGeometry,
          type: featuresTypes.DMAS,
          id: dma.DmaID,
          flow: dma.Sensitivity,
          date: dma.RelevantToDate,
          customer: dma.Comment,
        });
        const style = getStyle(dma);
        iconFeature.setStyle(style);
        iconFeatures.push(iconFeature);
      }
  });

  clear();
  source.addFeatures(iconFeatures);
};

export const getLayers = () => {
    return [layer];
};

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getStyle = (feature, bSelected) => {
    let alertStyle = new Style({
        fill: new Fill({
          color: '#0037ffa8'
        }),
        stroke: new Stroke({
          color: '#0000ff',
          width: 1
        }),
        text: new Text({
          font: '20px Helvetica, sans-serif',
          overflow: true,
          fill: new Fill({
            color: '#000'
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2
          }),
          text: feature.Comment
        })
      });

    return alertStyle;
};

export const getInfoTooltip = (feature, props) => {
    const attr = feature.getProperties();
    let infoTooltip = {
        type: attr.type,
        id: attr.id,
        flow: attr.flow,
        date: attr.date,
        customer: attr.customer
    };

    return (infoTooltip);
};

export const tooltipRender = (itemInfo, context, props) => {
    return (
        <span className="tooltip">
          <div className="tooltip-row">{context.t('customer_customerParam', {customer: itemInfo.customer})}</div>
          { itemInfo.flow &&
            <div className="tooltip-row">{context.t('flow_param', {flow: itemInfo.flow})}</div>
          }
          { itemInfo.date &&
            <div className="tooltip-row">{context.t('from_date_date', {date: UIDateFormater(itemInfo.date, DateType.DATE_AND_TIME, props.timeZone, props.projectTimezone)})}</div>
          }
        </span>
    );
};
