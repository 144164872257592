import { connect } from 'react-redux';

import PressureTabs from '../../components/SensorsTabs/Pressure/PressureTabs';
import UIDateFormater, { DateType } from '../UIhelper/UIDateFormater';
import * as actionsPressure from '../../actions/SensorsPressureActions';
import { PRESSURE_DISPLAY_MODE } from '../../constants/Misc';

const mapStateToProps = (state, ownProps) => {
    const { timeZone } = state.local;
    const { trendData, transientData, sensorPrsDaysBack, mode } = state.leaksList.pressure;

    const trend = trendData.isFetching ? [] : parseTrendForPlotly(trendData.data, trendData.avgData, mode);
    const transient = transientData.isFetching ? [] : parseTransientForPlotly(transientData.data, mode, timeZone);
    const titleDate = new Date();
    if (sensorPrsDaysBack != 0) {
        const dateParam = titleDate.getDate();
        titleDate.setDate(dateParam - sensorPrsDaysBack);
    }

    const title = getGraphTitle(mode, titleDate, timeZone);

    return Object.assign({}, {
        isTrendFetching: trendData.isFetching,
        isTransientFetching: transientData.isFetching,
        title,
        mode,
        trend,
        transient,
        sensorPrsDaysBack
    }, ownProps);
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        changeDisplayMode: (mode) => {
            const { sensorItem } = ownProps;
            const { DeviceID } = ownProps.sensorItem;
            dispatch(actionsPressure.changeDisplayMode(sensorItem, DeviceID, mode.value));
        },

        reloadDataDaysBack: (direction, daysBack, mode) => {
            const { sensorItem } = ownProps;
            const { DeviceID } = ownProps.sensorItem;
            const step = (mode == PRESSURE_DISPLAY_MODE.DAY) ? 1 : 7; // one Day or one Week.
            let days = daysBack;
            switch (direction) {
                default:
                case 'prev': {
                    days += step;
                    break;
                }
                case 'next': {
                    days -= step;
                    break;
                }
            }

            dispatch(actionsPressure.fetchPressure(sensorItem, DeviceID, days));
            dispatch(actionsPressure.fetchPressureTransient(sensorItem, DeviceID, days));
        }
    };
};

const getGraphTitle = (mode, date, timeZone, projectTimezone) => {


  let title;
    const mainDateTitle = UIDateFormater(date.getTime(), DateType.DATE, timeZone, projectTimezone);
    switch(mode) {
        default:
        case PRESSURE_DISPLAY_MODE.DAY: {
            title = mainDateTitle;
            break;
        }
        case PRESSURE_DISPLAY_MODE.WEEK: {
            const fromDate = new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000));
            const fromTitle = UIDateFormater(fromDate.getTime(), DateType.DATE, timeZone, projectTimezone);
            title = fromTitle + ' - ' + mainDateTitle;
            break;
        }
    }

    return (title);
};

const parseTransientForPlotly = (data, mode, timeZone, projectTimezone) => {
    let returnData;


    if (data != null && data.length > 0) {
        returnData = [];

        switch (mode) {
            case PRESSURE_DISPLAY_MODE.DAY: {
                data.forEach((itrValues) => {
                    const yValues = itrValues.y.map((val) => parseInt(val));
                    returnData.push({
                        name: UIDateFormater(itrValues.x, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone, projectTimezone),
                        y: yValues,
                        type: 'box',
                    });
                });
                break;
            }

            case PRESSURE_DISPLAY_MODE.WEEK: {
                const xValues = [];
                const boxes = [];

                let tempBoxForWeekDisplay = {};

                data.forEach((val, index, xValuesArray) => {

                    let xVal = new Date(val.x);

                    if (index == 0) {
                        // set the first value:
                        const dateString = xVal.toLocaleDateString();
                        tempBoxForWeekDisplay = {
                            name: dateString,
                            y: Object.assign([], val.y),
                            type: 'box',
                        };
                        // insert the X value:
                        xValues.push(xVal);
                    } else {
                        let xValPrv = new Date(xValuesArray[index - 1]);

                        let prevDate = xValPrv.getDate();
                        let currDate = xVal.getDate();

                        if (prevDate == currDate) {
                            // push the Y value to temp Box:
                            tempBoxForWeekDisplay.y.push(val.y);
                        } else {
                            // the prev box is complete.
                            // push the temp box the boxes array and generate the next box:
                            boxes.push(Object.assign({}, tempBoxForWeekDisplay));

                            const dateString = xVal.toLocaleDateString();
                            tempBoxForWeekDisplay = {
                                name: dateString,
                                y: Object.assign([], val.y),
                                type: 'box',
                            };

                            // insert the new X value:
                            xValues.push(xVal);
                        }
                    }
                });

                if (tempBoxForWeekDisplay.y.length > 0) {
                    boxes.push(Object.assign({}, tempBoxForWeekDisplay));
                }

                boxes.forEach((box) => {
                    box.x = xValues;
                });

                returnData = boxes;
                break;
            }

            default:
                break;
        }
    }

    return (returnData);
};

const parseTrendForPlotly = (data, avgData, mode) => {
    let returnData;

    if (data != null) {
        const pressureXValues = data.x;
        const yValues = data.y;

        if (pressureXValues != null && yValues) {
            switch (mode) {
                case PRESSURE_DISPLAY_MODE.DAY: {
                    const xValues = [];

                    pressureXValues.forEach((val) => {
                        let xVal = new Date(val); // UIDateFormater(val, DateType.DATE_AND_TIME, timeZone);
                        // push the date for the TREND graph:
                        xValues.push(xVal);
                    });

                    returnData = [{
                        x: xValues,
                        y: yValues,
                        mode: 'lines+points',
                        type: 'scatter',
                        // visible: 'legendonly',
                        name: 'Raw Data',
                    }];

                    if (avgData) {
                      returnData.push({
                        x: avgData.x.map(val => new Date(val)),
                        y: avgData.y,
                        mode: 'lines+points',
                        type: 'scatter',
                        name: 'avg'
                      });
                    }
                    break;
                }
                case PRESSURE_DISPLAY_MODE.WEEK: {
                    const xValues = [];
                    const boxes = [];

                    let tempBoxForWeekDisplay = {};

                    pressureXValues.forEach((val, index, xValuesArray) => {
                        let xVal = new Date(val);

                        if (index == 0) {
                            // set the first value:
                            const dateString = xVal.toLocaleDateString();
                            tempBoxForWeekDisplay = {
                                name: dateString,
                                y: [yValues[index]],
                                type: 'box',
                            };
                            // insert the X value:
                            xValues.push(xVal);
                        } else {
                            let xValPrv = new Date(xValuesArray[index - 1]);

                            let prevDate = xValPrv.getDate();
                            let currDate = xVal.getDate();

                            if (prevDate == currDate) {
                                // push the Y value to temp Box:
                                tempBoxForWeekDisplay.y.push(yValues[index]);
                            } else {
                                // the prev box is complete.
                                // push the temp box the boxes array and generate the next box:
                                boxes.push(Object.assign({}, tempBoxForWeekDisplay));

                                const dateString = xVal.toLocaleDateString();
                                tempBoxForWeekDisplay = {
                                    name: dateString,
                                    y: [yValues[index]],
                                    type: 'box',
                                };

                                // insert the new X value:
                                xValues.push(xVal);
                            }
                        }
                    });

                    if (tempBoxForWeekDisplay.y.length > 0) {
                        boxes.push(Object.assign({}, tempBoxForWeekDisplay));
                    }

                    boxes.forEach((box) => {
                        box.x = xValues;
                    });

                    returnData = boxes;
                    break;
                }
                default:
                    break;
            }
        }
    }

    return (returnData);
};

const cSensorPressureTabs = connect(
    mapStateToProps,
    mapDispatchToProps
)(PressureTabs);

export default cSensorPressureTabs;
