import React from 'react';
import { zIndexs, featuresTypes } from './Options';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';
import { Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import Point from 'ol/geom/Point';
const proj = require('ol/proj'); import _ from 'lodash';


const source = new VectorSource({
  features: [],
});

export const layer = new VectorLayer({
  name: 'mobileAlertsLayer',
  source: source,
  zIndex: zIndexs.mobileAlerts,
});

// const icons = {
//   red_high: require('./icons/red_deep.png'),
//   red: require('./icons/red.png'),
//   orange: require('./icons/orange.png'),
//   green: require('./icons/alert-mobile.png'),
// };

// const colorValues = {
//   thr_strong_red: 80,
//   thr_red: 70,
//   thr_orange: 55
// };
export const setSource = (features, props) => {

  const iconFeatures = features.map((mobileAlert) => {
    const iconFeature = new Feature({
      geometry: new Point(
        proj.transform(
          [mobileAlert.longitude, mobileAlert.latitude],
          'EPSG:4326',
          'EPSG:3857'
        )
      ),

      id: mobileAlert.alert_id,
      alertState: mobileAlert.alert_state,
      alertType: mobileAlert.alert_type,
      taskName: mobileAlert.task_name,
      createDate: mobileAlert.create_date,
      type: featuresTypes.MOBILE_ALERTS,
      // Add other alert properties as needed
    });
    const style = getStyle(iconFeature, false, props);
    iconFeature.setStyle(style);
    return iconFeature;
  });
  source.addFeatures(iconFeatures);
  return iconFeatures;
};

export const getStyle = (feature, selected, props) => {
  const style = [];

  // The original icon style
  style.push(new Style({
    image: new Icon({
      src: require('../../../../images/map/alerts/alert-mobile.png'),
      scale: 0.6,
      anchor: [0.5, 0.5], // Same anchor to overlay correctly
    }),
  }));

  // If the feature is selected, add the overlay style for the selected icon
  if (selected) {
    style.push(new Style({
      image: new Icon({
        src: require('../../../../images/map/alerts/alert-mobile-selected.png'),
        scale: 0.6, // Adjust scale if needed
        anchor: [0.5, 0.5], // Same anchor to overlay correctly
      }),
      zIndex: 1, // Higher z-index for the selected icon
    }));
  }

  return style;
};

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

export const getLayers = () => {
  return [layer];
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getInfoTooltip = (feature) => {
  const attr = feature.getProperties();

  const infoTooltip = {
    id: attr.id,
    createDate: attr.createDate,
    alertState: attr.alertState,
    alertType: attr.alertType,
    taskName: attr.taskName,
    type: attr.type,
    // Add other alert properties as needed
  };

  return infoTooltip;
};

export const tooltipRender = (itemInfo, context, props) => {

  const alertState = props.alertStateOptions.find((aState) => aState.value === itemInfo.alertState);
  const alertType = props.alertTypeOptions.find((aType) => aType.value === itemInfo.alertType);

  return (
    <span className="tooltip">
      <div className="tooltip-row">
        {context.t('alert_id')}: {itemInfo.id}
      </div>
      <div className='tooltip-row'>
        {context.t('create_date')}: {itemInfo.createDate ? UIDateFormater(itemInfo.createDate, DateType.DATE, props.timeZone, props.projectTimezone) : '--'}
      </div>
      <div className="tooltip-row">
        {context.t('alert_state')}: {alertState?.label || itemInfo.alertState}
      </div>
      <div className="tooltip-row">
        {context.t('alert_type')}: {alertType?.label}
      </div>
      <div className="tooltip-row">
        {context.t('task_name')}: {itemInfo.taskName}
      </div>
      {/* Add more tooltip rows as needed */}
    </span>
  );
};
