import PropTypes from 'prop-types';
import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { browserHistory } from 'react-router';

import LangSelect from '../../components/UserSettings/LangSelect';
import TimezoneSelect from '../../components/UserSettings/TimezoneSelect';
import UnitSelect from '../../components/UserSettings/UnitSelect';
import { useSelector } from 'react-redux';

const misc = require('../../constants/Misc');

require('./UserBlock.scss');

const userGuideFileName = 'AQS_v5.0_User_Interface_Guide.pdf';

export default function UserBlock(props, context) {
  const { user, local, lang, downloadFile } = props;
  const [aboutOpen, setAboutOpen] = React.useState(false);

  // Compute projectTimezone using useSelector with logs.
  const projectTimezone = useSelector(state => {
    if (!state.leaksList || !state.leaksList.projectsList) {
      return null;
    }
    const selectedProject = state.leaksList.selectedProject;
    if (!selectedProject) {
      return null;
    }
    const projectIndex = state.leaksList.projectsList.projectIndexMap[selectedProject];
    if (projectIndex === undefined) {
      return null;
    }
    const project = state.leaksList.projectsList.items[projectIndex];
    const tzName = project ? project.TimezoneName : null;
    return tzName;
  });


  return (
    <div className="userblock">
      <span>{context.t("hello_username", { userName: user.name })}</span>
      {JSON.parse(process.env.REACT_APP_LANG_SELECT) && <span><LangSelect /></span>}
      <span><UnitSelect /></span>
      <span>
        <TimezoneSelect
          user={user}
          local={local}
          lang={lang}
          projectTimezone={projectTimezone} // Passed down to TimezoneSelect
        />
      </span>
      <span>
        <a onClick={() => { downloadFile(userGuideFileName, 'manual'); }}>
          {context.t('help')}
        </a>
      </span>
      <span>
        <a onClick={() => { setAboutOpen(true); }}>
          {context.t('about')}
        </a>
      </span>
      <span>
        <a onClick={() => { props.signOutFunc(); }}>
          {context.t('logout')}
        </a>
      </span>

      <Dialog open={aboutOpen} onClose={() => setAboutOpen(false)}>
        <DialogTitle>
          <h1>Aquarius-Spectrum</h1>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          {context.t('copyright_2021_aquarius_spectrumbrbr_all_rights_re')}
          <br /><br />
          <a href='./certificates/notices.pdf' target='_blank'>
            {context.t('licneces')}
          </a>
        </DialogContent>
      </Dialog>
    </div>
  );
}

UserBlock.contextTypes = {
  t: PropTypes.func.isRequired
};
