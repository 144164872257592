import { useSelector } from 'react-redux';

const useProjectTimezone = () => {
  return useSelector(state => {
    if (!state.leaksList || !state.leaksList.projectsList) {
      return null;
    }
    const selectedProject = state.leaksList.selectedProject;
    if (!selectedProject) {
      return null;
    }
    const projectIndex = state.leaksList.projectsList.projectIndexMap[selectedProject];
    if (projectIndex === undefined) {
      return null;
    }
    const project = state.leaksList.projectsList.items[projectIndex];
    return project ? project.TimezoneName : null;
  });
};

export default useProjectTimezone;
