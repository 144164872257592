/**
 * Created by Yair Caspi on 07/06/2017.
 */
let getOffset = (timeZone = 'UTC', date = new Date()) => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 36e5;
}
export default function (longDateTime, dateType, local, timeZoneName) {
    let returnValue = longDateTime;


    if (longDateTime && typeof(longDateTime) === "number") {
        let time = longDateTime;
        if (local && local === 'local') {
            let offset = new Date().getTimezoneOffset() * 60 * 1000;
            time -= offset;
        }
        else if (local && local === 'project' && timeZoneName) {
            let offset = getOffset(timeZoneName);
            time += offset * 36e5;
        }

        let date = new Date(time).toISOString().split('T');

        switch (dateType) {
            default:
            case DateType.NONE:
                returnValue = time;
                break;

            case DateType.DATE:
                returnValue = date[0];
                break;

            case DateType.SHORT_DATE:
                returnValue = date[0].substring('xxxx-'.length);
                break;

            case DateType.DATE_AND_TIME:
                returnValue = date[0] + ", " + date[1].substring(0, 5);
                break;

            case DateType.DATE_AND_TIME_WITH_SECONDS:
                returnValue = date[0] + ", " + date[1].substring(0, 8);
                break;

            case DateType.TIME:
                returnValue = date[1].substring(0, 5);
                break;

            case DateType.TIME_WITH_SECONDS:
                returnValue = date[1].substring(0, 8);
                break;
        }
    }
    return (returnValue);
}

export const DateType = {
    NONE: 0,
    DATE: 1,
    DATE_AND_TIME: 2,
    DATE_AND_TIME_WITH_SECONDS: 3,
    TIME:4,
    SHORT_DATE: 5,
};
