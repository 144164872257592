import React from 'react';
import UIDateFormater, {
  DateType,
} from '../../containers/UIhelper/UIDateFormater';

import './alertsTabs.scss';
import useProjectTimezone from '../../hooks/useProjectTimeZone';
const actionsTypes = {
  0: 'Close',
  1: 'Create',
  2: 'Update',
};


function NoiseAlertHistory(props) {

const projectTimezone = useProjectTimezone();

  return (
    <ul className='history-container'>
      {props.history.map((item, index) => {
        const user = item.UserName;
        const status = item.AlertState
          ? props.options.find((opt) => opt.value === item.AlertState)
          : '--';
        const time = UIDateFormater(
          item.LoggingDate,
          DateType.DATE_AND_TIME_WITH_SECONDS,
          props.timeZone,
          projectTimezone
        );
        let content;

        switch (item.ActionCode) {
          case 0:
            content = <span>{props.context.t('close_noise_alert')}</span>;
            break;
          case 1:
            content = (
              <span>{props.context.t('create_a_new_noise_alert')}</span>
            );
            break;
          case 2:
            content = (
              <span>
                {props.context.t('update_status_to')}
                <strong>{status.label}</strong>
              </span>
            );
            break;
          case 3:
            content = (
              <span>
                {props.context.t('commented')}
                <i>{item.Comment}</i>"
              </span>
            );
            break;

          default:
            content = null;
        }

        return (
          <li key={index} className='noise-history-item'>
            <strong>{user}</strong>
            {content}
            <span className='noise-history-time'>{time}</span>
          </li>
        );
      })}
    </ul>
  );
}

export default NoiseAlertHistory;
