export const he = {
  Sensor_id: 'מזהה חיישן',
  X: '',
  a: 'A',
  ownership: 'בעלות',
  account: 'חשבון',
  active: 'פעיל',
  add: 'הוסף',
  alert_classification: 'סיווג התרעה',
  add_new_noise_alert: 'הוסף התרעת רעש חדשה',
  add_new_task: 'הוסף משימה חדשה',
  add_sensor: 'הוסף חיישן',
  address: 'כתובת',
  adhoc_correlation: 'קורלציה יזומה',
  advance_alerts: 'התרעות מתקדמות',
  advance_pipes: 'צינורות מתקדמים',
  advanced: 'מתקדם',
  agc_config: 'תצורת AGC',
  agreement: 'הסכם',
  alert: 'התרעה',
  alert_alertId: 'התרעה {alertId}',
  alert_comment: 'הערת התרעה',
  alert_id: 'מזהה התרעה',
  a_valid_segment: 'קטע תקין',
  above_under: 'מעל / מתחת',
  above: 'מעל',
  action: 'פעולה',
  address_: 'כתובת: ',
  addresses_address1_address2: 'כתובות: {address1}, {address2}',
  alert_date: 'תאריך התרעה: ',
  alert_id_filter: 'מסנן מזהה התרעה',
  alert_id_id: 'מזהה התרעה {id}',
  alert_id_: 'מזהה התרעה: ',
  alert_id_id_: 'מזהה התרעה: {id}',
  id: 'מזהה',
  alert_status: 'סטטוס התרעה',
  alert_type: 'סוג התרעה',
  alert_alertid: 'התרעה {alertId}',
  alerts_options: 'אפשרויות התרעות',
  alerts: 'התרעות',
  allow_false_positive: 'אפשר שגיאה חיובית',
  allow_prod2_run: 'אפשר הפעלת Prod2',
  allowprod2run: 'אפשרProd2Run',
  analyzer: 'מנתח',
  apply_changes: 'החל שינויים',
  are_you_sure_you_want_to_remove_this_device: 'האם אתה בטוח שברצונך להסיר את המכשיר הזה?',
  are_you_sure_you_want_to_uninstall_the_device_and_remove_this_sensor: 'האם אתה בטוח שברצונך להסיר את ההתקן ולהסיר את החיישן הזה?',
  are_you_sure_you_want_to_uninstall_the_device: 'האם אתה בטוח שברצונך להסיר את ההתקן?',
  are_you_sure_you_want_to_remove_this_sensor: 'האם אתה בטוח שברצונך להסיר את החיישן הזה?',
  are_you_sure: 'האם אתה בטוח?',
  this_action_will_rerun_override: 'פעולה זו תדרוס את הדגימות הקודמות',
  confirmation_success: 'אישור עדין שהפעולה שלך הצליחה',
  confirmation_error: 'הפעולה שלך לא הצליחה',
  assessment_id_id: 'מזהה הערכה: {id}',
  assetid_asset: 'מזהה התקן: {asset}',
  attenuation: 'היחלשות',
  recording_time_1: 'זמן הקלטה ראשון',
  recording_time_2: 'זמן הקלטה שני',
  recording_time_3: 'זמן הקלטה שלישי',
  authorization: 'אישור',
  auto: 'אוטומטי',
  autoclosedate: 'תאריך סגירה אוטומטי',
  azbest_cement: 'צמנט אסבסט',

  alert_id_param: 'מזהה התרעה: {id}',
  alert_state: 'מצב התרעה',
  alert_statuses: 'מצבי התרעה',
  all_boundaries_selected: 'כל הגבולות נבחרו',
  analyze: 'נתח',
  application_services: 'שירותי יישום',
  archived: 'ארכיון',
  are_you_sure_you_want_to_delete_this_type: 'האם אתה בטוח שברצונך למחוק את {type}?',
  asc: 'עולה',
  assembly: 'הרכבה',
  blank_space: ' ',
  asset_id: 'מזהה התקן',
  asset_id_param: 'מזהה נכס: {asset}',
  Probability_AQS: 'AQS-סיכוי',
  Probability: 'סיכוי',
  SensorStatus: 'סטטוס',
  exported_to_google_sheet_successfully: 'יוצא בהצלחה ל-Google Sheet',
  open_google_sheet: 'פתח Google Sheet',
  export_to_excel: 'יצוא ל-Excel',
  export_alerts_history: 'יצוא היסטוריית התרעות',
  export_noise_alerts_history: 'יצוא היסטוריית התרעות רעש',
  sensors_maintenance: 'תחזוקת חיישנים',
  sensors_activity_report: 'דו״ח פעילות חיישנים',
  sensors_technician_report: 'דו״ח טכנאי חיישנים',
  sensors_admin_report: 'דו״ח מנהל חיישנים',
  there_is_no_data_available: 'אין נתונים זמינים',
  no_data_available: 'אין נתונים זמינים',
  based_on_last_weeks_data: 'בהתבסס על נתוני השבוע האחרון',
  network_response_not_ok: 'תגובת הרשת אינה תקינה',
  technician_report: 'דו״ח טכנאי',
  report_type: 'סוג דו״ח',
  select_customer: 'בחר לקוח',
  rerun_historical_data: 'הרצת נתונים היסטוריים מחדש',
  select_time_frame: 'בחר מסגרת זמן',
  time_source: 'מקור זמן',
  valve_leak: 'דליפת שסתום',
  is_ultra: 'אולטרה',
  rerun: 'הרצה מחדש',
  please_select: 'אנא בחר',
  export_shp_file: 'יצוא קובץ SHP',
  export_alerts_to_excel: 'יצוא התרעות ל-Excel',
  export_all_transients_to_excel: 'יצוא כל המעברים ל-Excel',
  hello_username: 'שלום {userName}',
  about: 'אודות',
  help: 'עזרה',
  logout: 'התנתק',
  licneces: 'רישיונות',
  local: 'מקומי',
  gmt: 'GMT',
  last_correlation: 'קורלציה אחרון',
  last_spectrogram: 'ספקטרוגרם אחרון',
  radio_quality: 'איכות רדיו [dt: {dt}, quality: {quality}]',
  please_enter_your_new_password_below: 'אנא הזן את הסיסמה החדשה שלך למטה',
  enter_new_password_again: 'הזן את הסיסמה החדשה שוב',
  meter: 'מטר',
  fit: 'רגל',
  not_sensitive: 'לא רגיש',
  is_emi: 'האם EMI',
  is_saturation: 'האם רוויה',
  is_delayed_wakeup: 'האם התעוררות מושהית',
  iq_100b_reports: 'דוחות IQ100B',
  is_white_noise: 'האם רעש לבן',
  is_overlapping: 'האם חפיפה',
  audio: 'אודיו',
  device_installation_date: 'תאריך התקנת מכשיר: {deviceInstallationDate}',
  device_generation: 'דור מכשיר',
  audio_clean: 'אודיו נקי',
  sync_method: 'שיטת סנכרון',
  average: 'ממוצע',
  avg_max_correlation: 'קורלציה ממוצעת מקסימלית',
  avg_max_spectrogram: 'ספקטרוגרם ממוצע מקסימלי',
  bit_information: 'מידע BIT',
  bit_type: 'סוג BIT',
  back: 'חזור',
  bad_status: 'סטטוס גרוע',
  battery_voltage_post: 'מתח סוללה לאחר',
  battery_voltage_pre: 'מתח סוללה לפני',
  battery: 'סוללה',
  bounding: 'גבולות',
  csq: 'CSQ',
  cannot_find_your_search: 'לא ניתן למצוא את החיפוש שלך',
  cast_iron: 'ברזל יצוק',
  change_statename_of_leak_id_from_prevstate_to_nextstate: 'שנה {stateName} של נזילה {id} מ-{prevState} ל-{nextState}',
  change_statename_of_alert_type_from_prevstate_to_nextstate: 'שנה {stateName} של {alertType} {id} מ-{prevState} ל-{nextState}',
  circle: 'מעגל',
  city: 'עיר',
  click_to_set_current_map_position_as_project_center: 'לחץ כדי להגדיר את מיקום המפה הנוכחי כמרכז הפרויקט',
  comm_ratio: 'יחס תקשורת',
  comment: 'הערה',
  comment_date: 'תאריך הערה',
  communication_problem: 'בעיית תקשורת',
  compression_enabled: 'דחיסה מופעלת',
  compression: 'דחיסה',

  b: 'B',
  back_to_home_view: 'חזרה לתצוגת הבית',
  back_to_qalertsq: 'חזרה להתרעות',
  backfill: 'מילוי חוזר',
  backfill_comment: 'הערת מילוי חוזר',
  bedding_comment: 'הערת שכבת יסוד',
  bedding_type: 'סוג שכבת יסוד',
  brass: 'פליז',

  config_record_duration: 'הגדר משך הקלטה',
  config_status: 'הגדר סטטוס',
  consumption_time_: 'זמן צריכה: ',
  consumtion: 'צריכה',
  consumtion_: 'צריכה: ',
  contact_email: 'אימייל ליצירת קשר',
  contact_person: 'איש קשר',
  contact_phone_1: 'טלפון 1 ליצירת קשר',
  contact_phone_2: 'טלפון 2 ליצירת קשר',
  correlation_audio: 'קורלצייה אודיו',
  correlation_radio: 'קורלצייה רדיו',
  couple_coverage: 'כיסוי זוגות',
  couple_deleted_successfuly: 'זוג נמחק בהצלחה',
  couple_id_: 'מזהה זוג',
  couple_id_coupleid_material_count_alerts: 'מזהה זוג: {CoupleID}, חומר: {material}, {Count} התרעות',
  couple_id_id: 'מזהה זוג: {id}',
  couple_path: 'נתיב זוג: ',
  couple_coupleid: 'זוג {coupleId}',
  couple_id_id_: 'זוג {id}',
  couples_days_back_for_only_updated: 'ימים אחורה לזוגות רק עבור מעודכנים',
  create_couple: 'צור זוג',
  create_task: 'צור משימה',
  create_new_customer: 'צור לקוח חדש',
  create_new_project: 'צור פרויקט חדש',
  create_new_sop: 'צור נקודת התקנה חדשה',
  create_new_user: 'צור משתמש חדש',
  password_requirements_instructions: 'הסיסמה חייבת להיות באורך 12 תווים, לכלול אות גדולה אחת, מספר אחד וסמל מיוחד אחד.',
  create_new_area_of_interest: 'צור אזור עניין חדש',
  creation_time: 'זמן יצירה',
  customer_id_: 'מזהה לקוח: ',
  invalid_password_format: 'פורמט סיסמה לא חוקי',
  customer_name: 'שם לקוח',
  customer_customer: 'לקוח: {customer}',
  customer_name_: 'לקוח: {name}',
  customers: 'לקוחות',
  c: 'C',
  cancel: 'ביטול',
  yes: 'כן',
  changed: 'שונה',
  choose_location_for_new_installation_point: 'בחר מיקום לנקודת התקנה חדשה',
  claims: 'טענות',
  clarity: 'בהירות: ',
  english: 'אנגלית',
  hebrew: 'עברית',
  french: 'צרפתית',
  italian: 'איטלקית',
  spanish: 'ספרדית',
  layer_manager: 'מנהל שכבות',
  satellite_view: 'תצוגת לוויין',
  show_alerts: 'הצג התרעות',
  minimal_noise_intensity: 'עוצמת רעש מינימלית',
  filtered_noise_intensity: 'עוצמת רעש מסוננת',
  show_mobile_samples: 'הצג דגימות ניידות',
  show_mobile_alerts: 'הצג התרעות ניידות',
  show_sensors: 'הצג חיישנים',
  show_installation_points: 'הצג נקודות התקנה',
  show_areas_of_interest: 'הצג אזורי עניין',
  pipes_by_material: 'צינורות לפי חומר',
  pipes_by_diameter: 'צינורות לפי קוטר',
  pipes_by_pressure: 'צינורות לפי לחץ',
  pipes_by_placement_year: 'צינורות לפי שנת התקנה',
  couples_coverage: 'כיסוי זוגות',
  show_couples_path: 'הצג נתיב זוגות',
  project_boundaries: 'גבולות הפרויקט',
  show_water_meter_layer: 'הצג שכבת מד מים',
  project_coverage: 'כיסוי פרויקט',
  email: 'אימייל',
  password: 'סיסמה',
  forgot_password: 'שכחתי סיסמה',
  login: 'התחבר',
  manual_reset_at: 'איפוס ידני ב-{lastManualResetDate}',
  manual_reset_count: 'מספר איפוסים ידניים {manualResetCount}',
  auto_reset_at: 'איפוס אוטומטי ב-{lastAutoResetDate}',
  auto_reset_count: 'מספר איפוסים אוטומטיים {autoResetCount}',
  sensors_activity: 'פעילות חיישנים',
  select_month: 'בחר חודש',
  boundary: 'גבול',
  include_archived_sensors: 'כלול חיישנים בארכיון',
  all: 'הכל',
  sensor_1: 'חיישן 1',
  sensor_2: 'חיישן 2',
  device: 'מכשיר',
  pressure: 'לחץ',
  g5_status: 'סטטוס G5',
  classification: 'סיווג',
  clear_selection: 'נקה בחירה',
  click_on_map_to_set_coordinate: 'לחץ על המפה כדי להגדיר קואורדינטות',
  complete_alert_details_and_save: 'אנא השלם פרטי התרעה ושמור',
  click_to_download: 'לחץ להורדה',
  close: 'סגור',
  close_noise_alert: 'סגור התרעת רעש',
  close_valve_alert: 'סגור התרעת שסתום',
  closure_date: 'תאריך סגירה: ',
  code: 'קוד',
  commands: 'פקודות',
  commented: 'הגיב: ',
  comments: 'הערות',
  communications_service: 'שירותי תקשורת',
  compaction: 'דחיסה',
  compliance_with_laws: 'עמידה בתקנות',
  concrete: 'בטון',
  config: 'תצורה',
  config_duration: 'משך תצורה',
  configuration: 'הגדרות',
  configure: 'הגדר',
  consent_to: 'הסכמה ל',
  consent_to_use_of_data: 'הסכמה לשימוש בנתונים',
  consumption: 'צריכה: ',
  consumption_time: 'זמן צריכה: ',
  contact_details: 'פרטי קשר',
  content: 'תוכן',
  contrast: 'ניגודיות',
  control_buttons: 'כפתורי שליטה',
  coordinate: 'קואורדינטות',
  copper: 'נחושת',
  copyright_2021_aquarius_spectrumbrbr_all_rights_re: 'זכויות יוצרים © 2021 Aquarius Spectrum. כל הזכויות שמורות. Aquarius Spectrum1 HaMahshev, נתניה 4250711, ישראל. טלפון: +972-74-7136644 פקס: +972-4-6709014',
  corr_interval: 'מרווח קורלציה',
  corr_num_samples: 'מספר דגימות קורלציה',
  correlation: 'קורלציה',
  corrosion_type: 'סוג קורוזיה',
  cost: 'עלות: ',
  count: 'כמות',
  couple_and_id: 'זוג {id}: ',
  couple_assessment_report: 'דוח הערכת זוגות',
  couple_id: 'מזהה זוג',
  couple_id2: 'זוג {coupleId}: ',
  couple_length: 'אורך זוג',
  couple_comment: 'הערת זוג',
  couples: 'זוגות',
  couples_options: 'אפשרויות זוגות',
  crack_diameter_inches: 'קוטר סדק (אינצ\'ים)',
  create: 'צור',
  create_a_new_noise_alert: 'צור התרעת רעש חדשה',
  create_a_new_valve_alert: 'צור התרעת שסתום חדשה',
  create_date: 'תאריך יצירה',
  create_new_installation_point: 'צור נקודת התקנה חדשה',
  created_new_alert: 'נוצרה התרעה חדשה',
  current_device_id: 'מזהה מכשיר נוכחי',
  current_status: 'סטטוס נוכחי',
  customer: 'לקוח',
  customer_account: 'חשבון לקוח',
  customer_customerParam: 'לקוח: {customer}',
  flow_param: 'זרימה: {flow}',
  from_date_date: 'מתאריך: {date}',
  amplitude: 'אמפליטודה',
  file: 'קובץ',
  min_rms: 'מינימום RMS',
  catm_flags: 'דגלי catm',
  hw_v: 'גרסת HW',
  classify: 'סיווג',
  min: 'מינימום',
  nc_3: 'nc_3',
  threshold: 'סף',
  contact_name: 'שם ליצירת קשר',
  hi: 'שלום',
  assambly: 'הרכבה',
  g5_intensity: 'עוצמת G5',
  comm_messages: 'הודעות תקשורת',
  installation_point: 'נקודת התקנה',
  bit_samples: 'דגימות BIT',
  bit_messages: 'הודעות BIT',
  bit: 'BIT',
  gain: 'הגברה',
  download: 'הורד',
  seconds: 'שניות',
  freq: 'תדר',
  noise: 'רעש',
  low: 'נמוך',
  radio_clean: 'ניקוי רדיו',
  power: 'עוצמה',
  radio_frequency_data: 'נתוני תדר רדיו',
  noise_intensity_min_band_power: 'עוצמת רעש - עוצמת בנד מינימלי',
  audio_data_size: 'גודל נתוני שמע',
  request_time: 'זמן בקשה',
  response_time: 'זמן תגובה',
  selected_fm_channel: 'ערוץ FM שנבחר',
  tp_avg: 'TP ממוצע',
  tp_rms: 'TP RMS',
  std_agc: 'STD AGC',
  std_agc_2: 'STD AGC 2',
  tp_rms_2: 'TP RMS 2',
  original: 'מקורי',
  audio_gain: 'הגברת שמע',
  imsi: 'IMSI',
  batt: 'סוללה',
  acc_tech: 'טכנולוגיית אקסלרומטר',
  tech: 'טכנולוגיה',
  nbiot_flags: 'דגלי NB-IoT',
  nbiot_tx_tout: 'זמן שידור NB-IoT',
  nbiot_rx_tout: 'זמן קבלה NB-IoT',
  nbiot_tx_power:'עוצמת שידור NB-IoT',
  nbiot_tx_gain: 'הגברת שידור NB-IoT',
  nbiot_packet: 'חבילת NB-IoT',
  catm_packet: 'חבילת Cat-M',
  catm_rx_tout: 'זמן קבלה Cat-M',
  catm_tx_tout: 'זמן שידור Cat-M',

  rsrq_db: 'RSRQ [דציבלים]',
  rsrp_db: 'RSRP [דציבלים]',
  hw_rev: 'גרסת חומרה',
  nb: 'NB',
  unknown: 'לא ידוע',
  cat_m: 'Cat-M',
  fw_rev: 'גרסת תוכנה',
  fw_v: 'גרסת FW',
  rst: 'RST',
  orig_2: 'מקור 2',
  technology: 'טכנולוגיה',
  cell_id: 'מזהה תא',
  med: 'בינוני',
  high: 'גבוה',
  customer_id: 'מזהה לקוח: ',
  customer_indemnification: 'זהות לקוח',
  dmas_layer: 'שכבת DMAS',
  dates: 'תאריכים',
  days_correlation_incative: 'ימים ללא קורלציה',
  days_inactive: 'ימים לא פעילים',
  delete_failed: 'מחיקה נכשלה',
  device_id_: 'מזהה מכשיר: ',
  device_id_device: 'מזהה מכשיר: {device}',
  device_installation: 'התקנת מכשיר',
  device_already_installed_in_project_project: 'המכשיר כבר מותקן בפרויקט {project}',
  diameter_placement_year: 'קוטר / שנת התקנה',
  dist_m: 'מרחק [מטרים]',
  do_not_take_0_samples_in_average: 'אל תיקח 0 דגימות בממוצע',
  done: 'בוצע',
  download_audio: 'הורד שמע',
  download_log_file: 'הורד קובץ לוג',
  draw_mode: 'מוד ציור',
  dual_signal: 'אות כפול',
  d: 'D',
  dashboard: 'לוח בקרה',
  date: 'תאריך',
  day: 'יום',
  days_detected: 'ימים שזוהו: ',
  delete: 'מחק',
  delete_type_id: 'מחק {type} {id}',
  deletion_of_customer_data: 'מחיקת נתוני לקוח',
  deletion_of_data: 'מחיקת נתונים',
  desc: 'יורד',
  design_operating_press: 'לחץ עבודה בצינור',
  details: 'פרטים',
  detected: 'זוהה',
  device_configuration_messages_logs: 'הודעת לוג של תצורת המכשיר',
  device_id: 'מזהה מכשיר',
  device_id_val: 'מכשיר {id}',
  mobile_alert_id_val: 'התרעה ניידת {id}',
  frequency_hz: 'תדר: {freq} [Hz]',
  duration_sec: 'משך: [שניות]',
  access_point: 'נקודת גישה',
  fill_addresses: 'מלא כתובות',
  task_samples: 'דגימות של המשימה',
  samples_count: 'מספר דגימות',
  creation_date_default: 'תאריך יצירה (ברירת מחדל)',
  tasks: 'משימות',
  tasks_options: 'הגדרות של משימות',
  tasks_filters: 'מסנני משימות',
  alerts_filters: 'מסנני התרעות',
  creation_date: 'תאריך יצירה',
  survey: 'סקר',
  devices: 'מכשירים',
  clean: 'נקי',
  raw: 'גולמי',
  low_intensity: 'עוצמה נמוכה',
  user_name: 'שם משתמש',
  duration: 'משך',
  intensity_raw: 'עוצמה [גולמי]',
  intensity_db: 'עוצמה: [דציבלים]',
  intensity: 'עוצמה',
  battery_level: 'רמת סוללה',
  terms_and_conditions: 'תנאים והגבלות',
  battery_post: 'מצב סוללה',
  fw_version: 'גרסת תוכנה',
  hw_version: 'גרסת חומרה',
  rsrq: 'RSRQ',
  rsrp: 'RSRP',
  rssi: 'RSSI',
  snr: 'SNR',
  noise_gain: 'הגברת רעש',
  noise_min_rms: 'מינימום RMS רעש',
  normalize_noise: 'נרמל רעש',
  by_clicking_login_you_agree_to_our: 'בלחיצה על \'התחבר\' אתה מסכים ל-',
  fm_radio_channel: 'ערוץ רדיו FM',
  and_our: 'ול-',

  get_audio_config: 'קבל תצורת שמע',
  get_time_zone: 'קבל אזור זמן',
  get_lte_mode: 'קבל מצב LTE',
  get_server: 'קבל שרת',
  get_fm_channel: 'קבל ערוץ FM',
  start_fm_scan: 'התחל סריקת FM',
  get_apn: 'קבל APN',
  get_valve_leak: 'קבל דליפת שסתום',
  get_agc_conf: 'קבל תצורת AGC',
  get_fallback_server: 'קבל שרת חלופי',
  get_capability: 'קבל יכולת',
  get_lte_configuration: 'קבל תצורת LTE',
  bit_request: 'בקשת BIT',
  activate_toolkit: 'הפעל toolkit',
  you_are_about_to_take_a_significant_action: 'אתה עומד לבצע פעולה משמעותית',
  sure_you_want_to_deactivate_sensors: 'האם אתה בטוח שברצונך להשבית חיישנים?',
  privecy_policy: 'מדיניות פרטיות',
  confirm_updating_devices_server: 'אשר עדכון שרת המכשירים',
  confirm_updating_devices_as_version: 'אשר עדכון מכשירים כגרסת {version}',
  get_scheduler: 'קבל מתזמן',
  discard: 'בטל',
  edit: 'ערוך',
  deactivate: 'השבת',
  no_leak: 'אין נזילה',
  suspected_fast_developing_leak: 'חשד לנזילה עם התפתחות מהירה',
  suspected_leak: 'נזילה חשודה',
  uncertain: 'לא ודאי',
  undefined: 'לא מוגדר',
  hydrant: 'הידרנט',
  couple_intensity: 'Couple Intensity',
  sample_intensity: 'עוצמת דגימה',
  weighted_intensity: 'עוצמת דגימה משוקללת',
  low_rm: 'RM נמוך: [{rm}]',
  prob: 'סיכוי',
  show_in_5g: 'הצג ב-5G',
  draw_sensors_selection_area: 'שרטט אזור בחירת חיישנים',
  sensors_selected: '{num} חיישנים נבחרו',
  last_sample: 'דגימה אחרונה: ',
  couple_length_units: 'אורך זוג: {coupleLength}[{units}]',
  diameter_or_placement_year: 'קוטר / שנת התקנה',
  disclaimer: 'כתב ויתור',
  distance: 'מרחק: ',
  distance_between_sensors: 'מרחק בין חיישנים: ',
  distance_calculation: 'חישוב מרחק: ',
  distance_from_sensor1: 'מרחק מחיישן 1: ',
  distances: 'מרחקים',
  diameter_inch: 'קוטר באינצ\'ים',
  distribution_list: 'רשימת תפוצה: ',
  download_failed_file_not_found: 'ההורדה נכשלה - קובץ לא נמצא',
  download_sensor_audio: 'הורד שמע של חיישן',
  ductile_iron: 'ברזל גמיש',
  end_user: 'משתמש קצה',
  enter_an_mfa_code_to_complete_signin: 'הזן קוד MFA להשלמת ההתחברות.',
  expand_row: 'הרחב שורה',
  export2: 'ייצוא',
  export_the_view_to_PDF: 'ייצא את התצוגה ל-PDF',
  epsg: 'EPSG',
  edit_distributorname_projects: 'ערוך את פרויקטי {distributorName}: ',

  end_frequency: 'תדר סיום',
  end_frequency_hz: 'תדר סיום (Hz)',
  end_frequncy: 'תדר סיום',
  end_record_time: 'זמן סיום הקלטה',
  end_time: 'זמן סיום',
  estimated_address: 'כתובת משוערת',
  event_cause: 'גורם אירוע',
  event_date: 'תאריך אירוע',
  event_information: 'מידע על האירוע',
  event_max_value: 'ערך מקסימלי של האירוע',
  event_min_value: 'ערך מינימלי של האירוע',
  event_nature: 'אופי האירוע',
  exclude_sample_1_by_4_flag: 'אל תכלול דגימה 1 לפי 4 דגל',
  exclude_sample_1_by_4_number: 'אל תכלול דגימה 1 לפי 4 מספר',
  exclude_sample_1_by_4_radius: 'אל תכלול דגימה 1 לפי 4 רדיוס',
  export_all_projects_to_excel: 'ייצא את כל הפרויקטים ל-Excel',
  iq100b_reports: 'דוחות IQ100B',
  export_the_view_to_pdf: 'ייצא את התצוגה ל-PDF',
  export: 'ייצוא',
  ftpend_send_time: 'זמן שליחת FTPEnd',
  file_size: 'גודל קובץ',
  fading_alert: 'התרעה נעלמת',
  false_path: 'נתיב שגוי',
  fast_development: 'פיתוח מהיר',
  file_name: 'שם קובץ',
  filtered_avg: 'ממוצע מסונן',
  filtered_intensity_avg: 'עוצמה מסוננת ממוצעת',
  filtered_noise: 'רעש מסונן',
  filters_: 'מסננים: ',
  firmware_version: 'גרסת חומרה',
  first_name: 'שם פרטי',
  fixed_leaks: 'דליפות שתוקנו',
  flow_flow: 'זרימה: {flow}',
  fmax: 'Fmax',
  fragmented_file: 'קובץ מקוטע',
  frequency_histogram: 'היסטוגרמת תדרים',
  failed: 'נכשל',
  fees: 'עמלות',
  files: 'קבצים',
  filter: 'סנן',
  filters: 'מסננים',
  first_installation_date: 'תאריך התקנה ראשון',
  fm2_receiver: 'מקלט FM2',
  fm_receiver: 'מקלט FM',
  fmin: 'fmin',
  frequency: 'תדר',
  from: 'מ: ',
  from_to: 'מ - עד',
  frost_depth: 'עומק כפור',
  fs: 'fs',
  go: 'לך',
  gps_ratio: 'יחס GPS',
  generate: 'צור',
  good_status: 'מצב טוב',
  fast_growing_alert: 'התרעה מתפתחת במהירות',
  slow_growing_alert: 'התרעה מתפתחת לאט',
  guidelines_: 'הנחיות: ',
  hardware_version: 'גרסת חומרה',
  high_severity_more_than_4_leaks: 'חומרה גבוהה',
  high_frq: 'תדר גבוה',
  mid_frq: 'תדר בינוני',
  low_frq: 'תדר נמוך',
  inactive: 'לא פעיל',
  index_band_to_send: 'אינדקס רצועה לשליחה',
  index_: 'אינדקס',
  infrastructure: 'תשתית',
  install_date: 'תאריך התקנה',
  installation_point_comment: 'הערה על נקודת התקנה',
  intensity_not_filtered: 'אינטנסיביות (לא ממוינת)',
  intensity_: 'אינטנסיביות',
  intensity_intensity: 'אינטנסיביות: {intensity}',
  area_of_interest_comment: 'הערה על אזור עניין',
  area_of_interest_id_id: 'אזור עניין {id}',
  area_of_interest_type: 'סוג אזור עניין',
  area_of_interest: 'אזור עניין',
  areas_of_interest: 'אזורים של עניין',
  edit_area_of_interest: 'ערוך אזור עניין',
  is_csq_ok: 'האם מצב האות בסדר',
  is_display_city_column: 'האם להציג עמודה של עיר',
  is_elecrolysis: 'האם אלקטרוליזה',
  is_modem_closed_ok: 'האם המודם סגור בסדר',
  is_send_file_ok: 'האם קובץ נשלח בסדר',
  is_ok: 'האם בסדר',
  log: 'לוג',
  last_event_date: 'Last Event Date',
  last_name: 'שם משפחה',
  last_run_days: 'הריצה האחרונה (ימים)',
  last_sample_: 'דגימה אחרונה',
  last_uninstall_reason: 'סיבת הסרה אחרונה',
  lasteventfrequency: 'תדירות האירועים האחרונים',
  leak_per_units: 'נזילה לכל {units}',
  leakgrowth: 'גידול דליפות',
  leakperkm: 'דליפות לקמ',
  leaks_infrastructure: 'דליפות / תשתית',
  leaks_by_diameter: 'דליפות לפי קוטר',
  leaks_by_materials: 'דליפות לפי חומרים',
  leaks_by_years: 'דליפות לפי שנים',
  leaks: 'דליפות',
  legend: 'מקרא',
  linked_couples: 'זוגות מקושרים',
  list_name: 'שם רשימה',
  log_information_device_deviceid: '{deviceId} - מידע יומן. מכשיר: {deviceId}',
  low_severity_1_leak: 'חומרה נמוכה (נזילה אחת)',
  low_severity_with_fast_development_alert: 'חומרה נמוכה עם התראה על התפתחות מהירה',
  mail: 'דואר',
  main: 'ראשי',
  manage_distributor: 'ניהול מפיץ',
  manual: 'ידני',
  map_alerts: 'התראות מפה',
  map_pipes_diameter_type: 'סוג קוטר צינורות במפה',
  material_diameter_placement_year: 'חומר & קוטר / שנת התקנה',
  material_placement_year: 'חומר / שנת התקנה',
  material_name: 'שם החומר',
  medium_severity_2_4_leaks: 'חומרה בינונית (2 - 4 דליפות)',
  meteric_feet_epsg_only: 'מטרי / רגל EPSG בלבד',
  min_nc1_days: 'מינימום ימי Nc1',
  min_noise: 'רעש מינימלי',
  min_rms_nc1: 'מינימום RMS NC1',
  minimal_intensity: 'אינטנסיביות מינימלית',
  minimal_noise_sampling_taken_by_the_sensor_prior_to_audio_recording: 'דגימה של רעש מינימלי שנלקחה על ידי החיישן לפני הקלטת שמע',
  modem_atenna_signal_strength: 'עוצמת אות אנטנת מודם',
  modem_usage: 'שימוש במודם',
  more_samples: 'יותר דגימות',
  most_problematic_pipes_based_on_leaks_count: 'הצינורות הבעייתיים ביותר בהתבסס על מספר דליפות',
  most_problematic_pipes_based_on_leaks_per_units: 'הצינורות הבעייתיים ביותר בהתבסס על דליפות לכל {units}',
  multi_diameter: 'קוטר משונה',
  multi_material: 'חומר משונה',
  nc3_std_mean_ratio: 'יחס ממוצע סטנדרטי NC3',
  nc3_proj_mean: 'ממוצע פרוייקט NC3',
  name_: 'שם',
  new_customer: 'לקוח חדש',
  new_list_name: 'שם רשימה חדשה',
  new_project: 'פרויקט חדש',
  no_leaks: 'אין דליפות',
  nois_eaudio_duration_ms: 'משך זמן רעש eAudio MS',
  noise_alert_const_prob_thr: 'סף בעייתי קבוע בהתראת רעש',
  noise_alert_step_prob_thr: 'סף בעייתי שלב בהתראת רעש',
  noise_audio_fs: 'רעש FS אודיו',
  noise_audio_gain: 'גידול רעש אודיו',
  noise_intensity_gain: 'גידול אינטנסיביות רעש',
  noise_ok_threshold: 'סף תקין לרעש',
  noise_sample: 'דגימת רעש',
  noise_samples: 'דגימות רעש',
  noise_sync: 'סנכרון רעש',
  noise_level_: 'רמת רעש',
  noise_sampling_taken_by_the_sensor_during_audio_recording: 'דגימת רעש שנלקחה על ידי החיישן במהלך הקלטת שמע',
  noise_value_val: '{val} - ערך רעש: {val}',
  non_associated: 'לא מקושר',
  not_active: 'לא פעיל',
  not_installed: 'לא מותקן',
  num_coefficients: 'מספר מקדמים',
  num_frequency_bands: 'מספר רצועות תדרים',
  numbadburstsamples_thr: 'סף מספר דגימות פרץ לא טובות',
  number_of_transients: 'מספר טרנזיינטים',
  open_assessment: 'הערכה פתוחה',
  open_new_leak_on_0_sample: 'פתח נזילה חדשה על דגימה 0',
  open_projects: 'פרויקטים פתוחים',
  open_reports: 'פתיחת דוחות AQS',
  open: 'פתח',
  overground_acc_gps: 'אקסלרומטר עלי GPS',
  overground_acc_rf: 'אקסלרוטר עלי רדיו',
  overground_hyd_gps: 'הדופון עליי GPS',
  overground_hyd_rf: 'הידרופון עלי רדיו',
  phone: 'טלפון',
  pipe_accuracy: 'דיוק צינור',
  pipe_assessment_report: 'דוח הערכת צנרת',
  pipe_assessment: 'הערכת צינור',
  pipe_assessments_report_projectname_epsg: 'דוח הערכות צינור. {projectName} [EPSG: {epsg}]',
  pipe_assessments: 'הערכת צנרת',
  pipe_condition_analyzer: 'אנליזת מצב צינור',
  pipe_length_: 'אורך צינור',
  pipe_: 'צינור: ',
  pipes_by_assessment: 'צינורות לפי הערכה',
  pipes_by_presure: 'צינורות לפי לחץ',
  pipes_high_severity_analysis: 'אנליזת חומרה גבוהה לצינורות: ',
  pipes_by_placement_year_: 'צינורות לפי שנת התקנה',
  pipes_by_diameter_: 'צינורות לפי קוטר',
  pipes_by_materials: 'צינורות לפי חומרים',
  pipes_by_presure_: 'צינורות לפי לחץ',
  pipes_severity_score: 'ציון חומרת צינורות',
  score: 'ציון',
  pipes: 'צינורות',
  placement_year: 'שנת התקנה',
  polyethelen: 'פוליאתילן',
  polygon: 'פוליגון',
  poor_condition_pipe: 'צינור במצב גרוע',
  position_: 'מיקום: ',
  possible_pumps: 'משאבות אפשריות',
  pump: 'משאבה',
  prv: 'PRV',
  washout: 'ברז שטיפה',
  watering: 'השקיה',
  fountain: 'מזרקה',
  possible_ufr: 'UFR אפשרי',
  pressure_value_coefficient: 'מקדם ערך לחץ',
  pressure_y_value_coefficient: 'מקדם ערך לחץ Y',
  print: 'הדפסה',
  prob_irrig: 'P.I.',
  prob4_burst: 'סיכוי להתפוצצות 4',
  prob4_consumption: 'סיכוי לצריכה 4',
  processing: 'מעבד',
  profiler: 'פרופילר',
  proj_int_factor: 'מקדם פרויקט אינט',
  proj_int_len_factor: 'מקדם אורך פרויקט אינט',
  project_name: 'שם פרויקט',
  project_name_: 'פרויקט: {name}',
  projects_coverage: 'כיסוי פרויקטים',
  projects: 'פרויקטים',
  quiality: 'איכות',

  g5_devices: 'התקני G5',
  g5_devices_table: 'טבלת התקני G5',
  g5_logs_table: 'טבלת יומני G5',
  general: 'כללי',
  general_description: 'תיאור כללי',
  general_information: 'מידע כללי',
  general_provisions: 'הוראות כלליות',
  generate_report: 'יצירת דוח',
  generation: 'דור',
  get_project_defaults: 'קבל ברירות מחדל לפרויקט',
  grant_of_rights: 'הענקת זכויות',
  graph: 'גרף',
  ground_type: 'סוג קרקע',
  guidelines: 'הנחיות: ',
  hidden_div_for_generate_plotly_image: 'DIV מוסתר עבור יצירת תמונת Plotly',
  historical_information: 'מידע היסטורי',
  history: 'היסטוריה',
  id: 'ID',
  id_default: 'ID (ברירת מחדל)',
  images: 'תמונות',
  img: 'תמונה',
  in_these_tos: 'בתנאים אלה',
  include_map: 'הכלל מפה',
  indemnification: 'פיצוי',
  indemnity: 'פיצוי',
  index: 'מדד',
  install: 'התקנה',
  install_device: 'התקנת מכשיר',
  installation_point_id: 'מזהה נקודת התקנה {id}',
  installation_point_id_2: 'מזהה נקודת התקנה',
  installation_points: 'נקודות התקנה',
  instance_distances: 'מרחקים בין מופעים',
  instance_dts: 'מופעי DTS',
  intellectual_property_rights: 'זכויות קניין רוחני',
  intensities: 'עצימות',
  diameter: 'קוטר',
  intensity_param: 'עצימות: {intensity}',
  israel_standard_time: 'שעון ישראל',
  add_alert: 'הוסף התראה',
  create_alert: 'צור התראה',
  audio_alert_3: 'התראה אודיו 3',
  audio_base_1: 'בסיס אודיו 1',
  audio_base_2: 'בסיס אודיו 2',
  audio_sample: 'דוגמת אודיו',
  audio_sample_clean: 'דוגמת אודיו-נקי',
  audio_base_1_clean: 'בסיס אודיו 1-נקי',
  audio_base_2_clean: 'בסיס אודיו 2-נקי',
  audio_alert_3_clean: 'התראה אודיו 3-נקי',
  time_stamp: 'חותמת זמן',
  alert_3: 'התראה 3',
  base_1: 'בסיס 1',
  base_2: 'בסיס 2',
  event_rms: 'RMS אירוע',
  base_mean: 'ממוצע בסיס',
  base_std: 'סטיית תקן בסיס',
  samples_mean: 'ממוצע דגימות',
  samples_std: 'סטיית תקן דגימות',
  event_gain: 'דרגת הגבר באירוע',
  samples_gain: 'דרגת הגבר של הדגימה',
  base_magnitude: 'עוצמת בסיס',
  samples_magnitude: 'עוצמת דגימות',
  factor_baseline_rms: 'מקדם RMS',
  alert_samples: 'דגימות התרעה',
  sensor_history: 'היסטוריית חיישן',
  sample_distance: 'מרחק דגימה',
  sample_distances: 'מרחקי דגימות',
  weighted_distance: 'מרחק משוקלל',
  peak_0: 'שיא 0 [{ndx}, {maxProb}]',
  peak_1: 'שיא 1 [{ndx}, {maxProb}]',
  peak_2: 'שיא 2 [{ndx}, {maxProb}]',
  send_scheduler_message: 'שלח הודעת לוח זמנים',
  type_param: 'סוג: {type}',
  type: 'סוג',
  assessment_param: 'הערכת ID: {id}',
  area_of_interest_id: 'אזור עניין {id}',
  users_list: 'רשימת משתמשים',
  select_all_users: 'בחר את כל המשתמשים',
  create_report: 'צור דוח',
  save_as_pdf: 'שמור כ-PDF',
  iq_100b_mobile: 'IQ100B - נייד',
  device_id_param: 'מזהה התקן: {device}',
  detected_date_date: 'תאריך זיהוי: {date}',
  pipe_install: 'התקנת צינור',
  closure_date_date: 'תאריך סגירה: {date}',
  fixed_date_date: 'תאריך תיקון: {date}',
  event_date_param: 'תאריך אירוע: {date}',
  status_state_param: 'סטטוס: {state}',
  status_param: 'סטטוס: {status}',
  alert_type_param: 'סוג התראה: {alertType}',
  noise_alert_id_noiseId: 'מזהה התראת רעש: {noiseId}',
  comment_comment: 'הערה: {comment}',
  end_date: 'תאריך סיום',
  couple_id_param: 'מזהה זוג: {id}',
  probability_param: 'סיכוי: {probability}',
  probabilityLeak_param: 'סיכוי נזילה: {probabilityLeak}',
  leak_boss_param: 'מקור נזילה: {boss}',
  intensity2: 'עצימות: ',
  interferences: 'הפרעות',
  interference_artifact: 'ממצא הפרעה',
  is_electrolysis: 'האם אלקטרוליזה',
  is_pressure: 'האם לחץ',
  is_mini: 'האם G5Ultra',
  is_previous_break_reported: 'האם הפסקה קודמת דווחה',
  is_rocks: 'האם סלעים',
  is_voids: 'האם חללים',
  items: 'פריטים',

  lamppost_number: 'מספר עמוד תאורה',
  large_diameter: 'קוטר גדול',
  last_comment: 'ההערה האחרונה: ',
  last_communication: 'תקשורת אחרונה',
  last_event_time: 'זמן אירוע אחרון',
  last_installation_date: 'תאריך התקנה אחרון',
  latitude: 'קו רוחב',
  launch_street_view_for_selected_item: 'הפעל תצוגת רחוב עבור פריט נבחר',
  lead: 'הובלה',
  intensity_growth: 'Intensity Growth',
  leak_growth: 'גידול נזילה',
  leak_id: 'מזהה נזילה',
  leak_id2: 'מזהה נזילה: ',
  length: 'אורך',
  license: 'רישיון',
  limitation_of_liability: 'הגבלת אחריות',
  limitation_of_liability2: 'הגבלת אחריות',
  limitations: 'הגבלות',
  limitations_on_use: 'הגבלות על שימוש',
  loading: 'טוען...',
  location_information: 'מיקום מידע',
  logs: 'לוגים',
  longitude: 'אורך',
  longitude_latitude: 'אורך, רוחב',
  mail_sms_notification: 'הודעת דואל / SMS',
  manage_distribution_lists: 'ניהול רשימות הפצה',
  management: 'ניהול',
  map_could_not_be_captured_please_take_a_screenshot: 'לא ניתן ללכוד את המפה. בבקשה קח צילום מסך ידני.',
  material: 'חומר',
  material_and_diameter_or_placement_year: 'חומר וקוטר / שנת התקנה',
  material_or_placement_year: 'חומר / שנת התקנה',
  materials: 'חומרים',
  max_probability: 'הסתברות מרבית',
  mdpe: 'MDPE',
  mfa_enabled: 'MFA פעיל',
  metal: 'מתכת',
  mfa_code: 'קוד MFA:',
  mobile: 'נייד',
  modification_of_tos: 'שינוי תנאי השימוש',
  month: 'חודש',
  multifactor_authentication: 'אימות רב-גורמי',
  n: 'N',
  min_value: 'ערך מינימלי',
  max_value: 'ערך מקסימלי',
  median_value: 'ערך ממוצע',
  name: 'שם',
  name_correlation: 'קורלציה של {שם}',
  nature_of_application_hosting: 'טבע היישום ואחסון',
  nature_of_break_comment: 'טבע ההערה על ההפסקה',
  network_coverage_gnss_satellites_interruption_of_s: 'כיסוי רשת, לווייני GNSS, הפסקת שירות',
  new_couple: 'זוג חדש',
  new_list_item: 'פריט חדש ברשימה',
  no_data: 'אין נתונים',
  no_files_uploaded_yet: 'טרם הועלו קבצים...',
  no_filters_applyed: 'לא הוחלו פילטרים',
  no_lists: 'אין רשימות',
  noise_NC1_NC2: 'רעש NC1/NC2',
  noise_alert_options: 'אפשרויות התראה על רעש',
  noise_alert_statuses: 'מצבי התראה על רעש',
  noise_interval: 'מרווח רעש',
  noise_level: 'רמת רעש',
  valve_alert_options: 'אפשרויות התראה על שסתום',
  control_valve: 'שסתום בקרה',
  service_valve: 'שסתום שירות',
  gate_valve: 'שסתום שער',
  air_valve: 'שסתום אוויר',
  manhole: 'חפירת בור',
  fitting: 'אביזר',
  signals: 'אותות',
  tensors: 'טנסורים',
  previous_samples: 'דגימות קודמות',
  fmReceiverSN: 'מספר סידורי של מקלט FM',
  hardwareRevision: 'עדכון חומרה',
  domainName: 'שם דומיין',
  at_least_length_characters_are_required: 'נדרש לפחות {אורך} תווים',
  at_least_one_uppercase_letter: 'נדרש אותיות רשיות',
  at_least_one_lowercase_letter: 'נדרש אותיות קטנות',
  at_least_one_number: 'נדרש מספרים',
  the_passwords_are_not_the_same: 'הסיסמאות אינן תואמות',
  invalid_password: 'סיסמה לא תקינה',
  back_to_signin_page: 'חזור לדף ההתחברות',
  forgot_your_password: 'שכחת את סיסמתך?',
  email_to_reset_password: 'הזן את כתובת הדואל שלך למטה ונשלח לך הודעה לאיפוס הסיסמה',
  email_to_reset_password_sent: 'שלחנו קוד לאיפוס סיסמה לדואל {דואל}. הזן אותו למטה לאיפוס הסיסמה שלך.',
  reset_my_password: 'איפוס הסיסמה שלי',
  new_password: 'סיסמה חדשה',
  confirm_new_password: 'הכנס את הסיסמה החדשה שוב',
  change_password: 'שנה סיסמה',
  alert_information: 'מידע על התראה',
  created_date: 'תאריך יצירה',
  raw_intensity: 'עוצמה גולמית',
  filtered_intensity: 'עוצמה מסוננת',
  consistency: 'עקביות',
  pi: 'P.I',
  close_alert: 'סגור התראה',
  number_of_samples: 'מספר דגימות',
  load: 'עומס',
  clean_sample: 'דגימה נקייה',
  sample: 'דגימה',
  device_type: 'סוג התקן: {deviceType}',
  address_param: 'כתובת: {כתובת}',
  reset_password: 'איפוס סיסמה',
  rf_sync_problem_: 'בעיה בסינכרון RF',
  rsrp_dbm: 'RSRP [dBm]',
  radio_: 'רדיו',
  ready: 'מוכן',
  record_duration: 'משך ההקלטה',
  recording_duration_sec: 'משך ההקלטה [שניות]',
  recording_durationsec: 'משך ההקלטה שניות',
  registered_microsoft_partner: 'שותף רשום של מיקרוסופט',
  relative_sensor: 'חיישן יחוס',
  reload_data: 'טען נתונים מחדש',
  remember_me: 'זכור אותי',
  remove_sensor_sensorid: 'הסר חיישן {sensorID}',
  remove: 'הסר',
  repair_date_: 'תאריך תיקון',
  report_enabled: 'דוח מופעל',
  reports_settings: 'הגדרות דוחות',
  reports: 'דוחות',
  resets: 'איפוסים',
  sms: 'SMS',
  sop_id_: 'SOP ID: ',
  sop_status: 'סטטוס SOP',
  sop_type: 'סוג SOP',
  sops_filters: 'מסנני SOPs',
  sample_correlation_contrast: 'נגודיות דגימת הקורלציה',
  sample_correlation: 'קורלציית דגימה',
  sample_id_: 'מזהה דגימה:',
  sample_noise: 'רעש דגימה',
  sample_size: 'גודל דגימה',
  sample_time_: 'זמן דגימה:',
  second_sensor: 'חיישן שני',
  select_projects: 'בחר פרויקטים...',
  select_report: 'בחר דוח...',
  select_project: 'בחר פרויקט',
  select_: 'בחר...',
  selected_selectedindex_total_total: 'נבחר: {selectedIndex}. סך הכל: {total}',
  send_to_printer: 'שלח למדפסת',
  sensitivity_level: 'רמת רגישות',
  sensor_id_: 'מזהה חיישן:',
  sensor_id_id: 'מזהה חיישן: {id}',
  sensor_type: 'סוג חיישן',
  sensor_comment_: 'הערת חיישן',
  sensor_id_default: 'מזהה חיישן (ברירת מחדל)',
  sensor: 'חיישן',
  sensors_report: 'דוח חיישנים',
  sensors_filters: 'מסנני חיישנים',
  sensors_options: 'אפשרויות חיישנים',
  service_provider_: 'ספק שירות',
  settings: 'הגדרות',
  shadow: 'צל',
  show_hide: 'הצג / הסתר',
  show_all: 'הצג הכל',
  show_iquarius_samples: 'הצג דגימות iQuarius',
  sim_number: 'מספר סים',
  sopid: 'SopID',
  source_alert: 'התראת מקור',
  start_frequency: 'תדירות התחלה',
  start_frequency_hz: 'תדירות התחלה (Hz)',
  start_time: 'זמן התחלה',
  state: 'מדינה',
  status_default: 'סטטוס (ברירת מחדל)',
  percent: 'אחוז',
  total_count: 'סך הכל ספירות',
  under_count: 'ספירות מתחת',
  over_count: 'ספירות מעל',
  mixed_over_under_count: 'ספירות סוג מעורב',
  g5_count: 'ספירת G5',
  g4_count: 'ספירת G4',
  mixed_g_count: 'ספירת G מעורבת',
  project_id: 'מזהה פרויקט',
  project_display_name: 'שם תצוגה פרויקט',
  status: 'סטטוס:',
  sudden_air_temp_change: 'שינוי חפוז במעלות האוויר',
  task_id: 'מזהה משימה',
  time_: 'זמן:',
  timezone: 'אזור זמן',
  total_length_unitslable: 'אורך כולל [{unitsLable}]',
  type_: 'סוג:',
  type_type: 'סוג: {type}',
  under: 'מתחת',
  underground_acc_gps: 'GPS Acc תת-קרקעי',
  underground_acc_rf: 'RF Acc תת-קרקעי',
  underground_hyd_gps: 'GPS הידראולי תת-קרקעי',
  underground_hyd_rf: 'RF הידראולי תת-קרקעי',
  uninstall_device_and_remove_sensor: 'הסר התקן והסר חיישן',
  uninstall_device_and_install_it_on_the_new_sensor: 'הסר התקן והתקן אותו על החיישן החדש',
  uninstall_comment: 'הערת הסרה',
  uninstall: 'הסרה',
  uninstalled: 'הוסר',
  update_customer_details: 'עדכן פרטי לקוח',
  update_pipes: 'עדכן צינורות',
  update_project_details: 'עדכן פרטי פרויקט',
  update_user_details: 'עדכן פרטי משתמש',
  use_feet_in_matlab_graphs: 'השתמש ברגליים בגרפים של Matlab',
  use_imperial_units_in_graphs: 'השתמש ביחידות אימפריאליות בגרפים',
  user_name_name: 'משתמש: {name}',
  users: 'משתמשים',
  w: 'W',
  your_item_located_in_project: 'הפריט שלך ממוקם בפרויקט {project}.',
  display_item: 'הצג {item}',
  hz: '[Hz]',
  unitshz: ' [{units}/Hz]',
  balagan_dist_ratio_thr: 'balagan_dist_ratio_thr',
  couple: 'זוג',
  false: 'שקר',
  first: 'ראשון',
  ft: 'רגל',
  inactive_couple_no_sensors_planning: 'זוג לא פעיל - ללא חיישנים - בתכנון',
  inactive_couple_no_sensors: 'זוג לא פעיל - ללא חיישנים',
  inactive_couple: 'זוג לא פעיל',
  irrig_dist: 'מרחק השקיה',
  last: 'אחרון',
  length_length_units: 'אורך: {length} [{units}]',
  no_correlations_warning: 'אזהרה: "אין קורלציה"',
  slope_slope_std_std_r2_r2: 'שיפוע: {slope}, סטיית תקן: {std}, R²: {r²}',
  sum_included_thr: 'סכום כלול סף',
  update_from_aqs_mobile_details: 'עדכון מ-AQS Mobile. ({details})',
  name_spectrogram: '{name} ספקטרוגרם',
  low_rm_rm: 'RM נמוך: [{rm}]',
  noise_num_samples: 'מספר דגימות רעש',
  noise_tx_time: 'זמן שידור רעש',
  average_last: 'ממוצע אחרון',
  none: 'אין',
  not_found: 'לא נמצא',
  not_include_not_installed_sensors: 'אל תכלול חיישנים לא מותקנים',
  notices_and_electronic_communications: 'הודעות ותקשורת אלקטרונית',
  official_language: 'שפה רשמית',
  op_audio_table: '{op} טבלת אודיו',
  or: ' או ',
  original_audio: 'אודיו מקורי',
  other: 'אחר',
  overground: 'על פני השטח',
  override_length: 'עקוף אורך',
  p: 'P',
  payload: 'מטען',
  payment: 'תשלום',
  pca: 'PCA',

  sample_images: 'דימויים לדוגמה',
  user_sample_classify: 'מיין',
  invalid_email_address: 'כתובת דואל לא חוקית',
  phone_number: 'מספר טלפון',
  pictures: 'תמונות',
  pipe: 'צינור: ',
  pipe_coating: 'ציפוי צינור',
  pipe_condition_assessment_details: 'פרטי הערכת מצב הצינור',
  pipe_repair_details: 'פרטי תיקון הצינור',
  pipe_condition_details: 'פרטי מצב הצינור',
  pipe_diameter: 'קוטר צינור',
  pipe_id: 'מזהה צינור',
  pipe_install_year: 'שנת התקנת הצינור',
  pipe_length: 'אורך הצינור',
  pipe_length2: 'אורך צינור: ',
  filter_by_customer: 'סנן לפי לקוח',
  check_devices: 'בדוק מכשירים',
  copy_device_id_from_excel: 'העתק מזהה מכשירים מ-Excel',
  count_devices: '{count} מכשירים',
  selected_devices: '{selected} / {count} מכשירים',
  pipe_material: 'חומר הצינור',
  pipe_section_details: 'פרטי קטע הצינור',
  pipe_thickness: 'עובי צינור',
  pipe_thickness_at_break: 'עובי צינור (במקום השבירה)',
  pipe_type: 'סוג צינור',
  pipes_materials: 'חומרי הצינורות: ',
  please_uninstall_device: 'אנא הסר את המכשיר',
  point_1: 'נקודה 1',
  point_2: 'נקודה 2',
  poly_ethelen: 'פוליאתילן',
  position: 'מיקום: ',
  preparing_to_download_if_download_does_not_commence_please_allow_popups_for_this_site: 'מכין להורדה. אם ההורדה לא תתחיל, אנא אפשר פופ-אפים לאתר זה.',
  previous_break_operating_press: 'לחץ הפעלה בשבירה קודמת',
  prior_2_weeks_weather: 'מזג אוויר 2 השבועות הקודמים',
  priority: 'עדיפות',
  intensity_opr: 'עוצמת OPR',
  probability_opr: 'הסתברות OPR',
  probability_leak_opr: 'הסתברות נזילה OPR',
  work_area: 'אזור עבודה',
  repair_code: 'קוד תיקון',
  contractor_id: 'מזהה קבלן',
  detected_at: 'זוהה ב',
  priority_default: 'עדיפות (ברירת מחדל)',
  privacy_statement: 'הצהרת פרטיות',
  probability: 'הסתברות',
  processing_of_personal_data: 'עיבוד נתונים אישיים',
  products: 'מוצרים',
  project: 'פרויקט',
  project2: 'פרויקט: ',
  proximity_to_other_utilities: 'קרבה לשירותים אחרים',
  prs_calib_multiply: 'כפל כיול PRS',
  prs_calib_shift: 'הזזת כיול PRS',
  pvc: 'PVC',
  quality: 'איכות: ',
  radio: 'רדיו',
  radio_frequency: 'תדר רדיו',
  ref_time: 'זמן התחלה',
  registration_payment: 'רישום; תשלום',
  remove_filters_to_view_device: 'הסר מסננים כדי לראות את המכשיר',
  item_is_hidden_by_filters: '{item} מוסתר על ידי מסננים',
  remove_sensor: 'הסר חיישן',
  repair_date: 'תאריך תיקון',
  repair_details: 'פרטי תיקון',
  add_new_alert: 'הוסף התראה חדשה',
  add_new_mobile_alert: 'הוסף התראה ניידת חדשה',
  repair_type: 'סוג תיקון',
  detection_date: 'תאריך זיהוי',
  report: 'דוח',
  reported_by: 'דיווח על ידי',
  reset: 'איפוס',
  resistivity: 'התנגדות',
  result: 'תוצאה',
  s: 'ש',
  sample_id: 'מזהה דגימה: ',
  sample_time: 'זמן דגימה: ',
  sample_time_1: 'זמן דגימה 1',
  sample_time_2: 'זמן דגימה 2',
  sample_time_3: 'זמן דגימה 3',
  samples: 'דגימות',
  sap_number: 'מספר SAP: ',
  save: 'שמור',
  save_and_apply: 'שמור והחלק',
  save_as_excel: 'שמור כ-Excel',
  save_changes: 'שמור שינויים',
  scheduler: 'מתזמן',
  search: 'חפש',
  search_box: 'תיבת חיפוש',
  sec: 'שנייה',
  select: 'בחר...',
  select2: 'בחר',
  select_draw_mode_and_draw_area_of_interest: 'בחר מצב שרטוט ושרטט אזור מעניין',
  select_sensor: 'בחר חיישן',
  selected_param: 'נבחר: {selectedItems}',
  selected_sensor_id: 'חיישן נבחר {id}',
  sensor_id_param2: 'חיישן: {id}',
  send: 'שלח',
  sensor1_address: 'כתובת חיישן 1: ',
  bands: 'בנדים',
  days: 'ימים',
  avg_max_leak_val_ndx:
    'ממוצע מקסימום נזילה [ndx: {maxProbIndex}, leak ndx: {maxProbLeakIndex}, val: {maxProbVal}]',
  sensor2_address: 'כתובת חיישן 2: ',
  sensor_activity_threshold: 'סף פעילות חיישן',
  sensor_comment: 'הערת חיישן',
  wake_up_before_radio: 'התעורר לפני רדיו',
  random_delay: 'השהיה אקראית',
  sampling_freq: 'תדר דגימה',
  sensor_id: 'מזהה חיישן',
  sensor_id2: 'מזהה חיישן: ',
  sensor_id_param: 'מזהה חיישן: {id}',
  sensor_install: 'התקנת חיישן',
  sensor_is_not_found_check_filters_and_try_again: 'החיישן לא נמצא. בדוק את המסננים ונסה שוב',
  sensors: 'חיישנים',
  sensors_nc_table: 'טבלת חיישנים NC',
  service: 'שירות',
  service_provider: 'ספק שירות',
  service_provider2: 'ספק שירות',
  service_subscription: 'מנוי שירות',
  session_max_value: 'ערך מקסימום מושב',
  session_min_value: 'ערך מינימום מושב',
  session_num_events: 'מספר אירועים במושב',
  set_actual_leak_position: 'הגדר מיקום נזילה אמיתי',
  set_actual_leak_position_on_map: 'הגדר מיקום נזילה על המפה: ',
  set_actual_installation_point_position: 'Set Actual Installation Point Position',
  set_actual_installation_point_position_on_map: 'Set actual installation point position on Map:',
  set_apn: 'הגדר APN',
  set_leak_valve_monitor: 'הגדר בקרה על שסתום נזילה',
  event_magnitude_constant: 'קבוע עוצמת האירוע',
  magnitude_threshold: 'סף מגבלה',
  leak_monitor_interval_sec: 'מרווח בקרה לנזילה בשניות',
  leak_monitor_alert_suspend_interval_sec: 'מרווח השהיית התראה לנזילה בשניות',
  sample_frequency_hz: 'תדר דגימה HZ',
  leak_monitor_long_sample_sec: 'דגימה לאורך זמן',
  sample_duration: 'משך דגימה',
  notch_filter: 'פילטר חיתוך',
  compress_enabled: 'הפחתה מופעלת',
  channel_10k: 'ערוץ 10k',
  radio_band_mask_1: 'מסכת רדיו 1',
  radio_band_mask_2: 'מסכת רדיו 2',
  radio_band_mask_3: 'מסכת רדיו 3',
  radio_band_mask_4: 'מסכת רדיו 4',
  audio_duration: 'משך אודיו',
  mode: 'מצב',
  cat_m_preferred: 'CAT-M מועדף',
  nb_iot_preferred: 'NB-IOT מועדף',
  cat_m_only: 'רק CAT-M',
  zone: 'אזור',
  threshold_3: 'סף 3',
  threshold_2: 'סף 2',
  threshold_1: 'סף 1',
  auto_gain: 'הגברת אוטומטית',
  gain_1: 'הגברה 1',
  gain_2: 'הגברה 2',
  gain_3: 'הגברה 3',
  gain_4: 'הגברה 4',
  nb_iot_only: 'רק NB-IOT',
  channel_num: 'ערוץ {num}',
  radio_duration: 'משך רדיו',
  audio_fs: 'FS אודיו',
  sampling_window: 'חלון דגימה',
  audio_num_bands: 'מספר פסי אודיו',
  audio_num_coff: 'מספר Coff אודיו',
  audio_band_mask_1: 'מסכת פס אודיו 1',
  audio_band_mask_2: 'מסכת פס אודיו 2',
  audio_band_mask_3: 'מסכת פס אודיו 3',
  audio_band_mask_4: 'מסכת פס קול 4',
  radio_num_bands: 'מספר פסי רדיו',
  radio_num_coff: 'מספר קופים רדיו',
  audio_enable: 'הפעלת קול',
  conf: 'קונפ',
  fm2_conf: 'קונפ fm2',
  fm1: 'fm1',
  fm2: 'fm2',
  corr_sample_freq: 'תדר דגימה מתואם',
  audio_report_enabled: 'דיווח קול מופעל',
  corr_radio_duration: 'משך רדיו מתואם',
  corr_audio_duration: 'משך קול מתואם',
  noise_audio_duration: 'משך רעש קול',
  corr_audio_fs: 'קול fs מתואם',
  wakeup_befor: 'הדלקה לפני',
  random_backoff_connect: 'חיבור הימור אקראי',
  dfu_fw: 'גרסת DFU',
  dfu_type: 'סוג DFU',
  dfu_block: 'חסום DFU',
  modem_fw: 'גרסת מודם',
  snr1: 'snr1',
  snr2: 'snr2',
  last_updated: 'עודכן לאחרונה',
  tlv_server_time: 'זמן שרת TLV',
  cellular_time: 'זמן סלולרי',
  apn: 'APN',
  domain: 'דומיין',
  modem_version: 'גרסת מודם',
  search_users: 'חיפוש משתמשים',
  search_alert: 'חיפוש התראה',
  search_sensor: 'חיפוש חיישן',
  search_alert_id: 'חיפוש מזהה התראה',
  model: 'מודל',
  iemi: 'IMEI',
  iccid: 'ICCID',
  search_installation_point: 'חיפוש נקודת התקנה',
  search_interference: 'חיפוש הפרעה',
  interference_id: 'מזהה הפרעה',
  set_default: 'הגדר ברירת מחדל',
  prev: 'קודם',
  next: 'הבא',
  required: 'נדרש',
  search_couple: 'חיפוש זוג',
  continue: 'המשך',
  you_are_about_to_take_a_significant_action_on_num_devices: 'אתה עומד לנקוט בפעולה משמעותית ב-{num} מכשירים: ',
  confirm_updating_devices_apn_to: 'אשר עדכון APN למכשירים ל- {apn}',
  search_task: 'חיפוש משימה',
  lte_mode: 'מצב LTE',	
  confirm_updating_lte_mode_to: 'אשר עדכון מצב LTE ל-{mode}',
  search_user: 'חיפוש משתמש',
  search_project: 'חיפוש פרויקט',
  search_customer: 'חיפוש לקוח',
  filter_by: 'סנן לפי',
  filter_by_project: 'סנן לפי פרויקט',
  filter_by_customer: 'סנן לפי לקוח',
  set_capability: 'הגדר יכולת',
  set_fm_scan: 'הגדר סריקת FM',
  set_lte_configuration: 'הגדר קונפיגורציית LTE',
  set_lte_mode: 'הגדר מצב LTE',
  set_secondary_server: 'הגדר שרת משני',
  low_frequency: 'תדר נמוך',
  high_frequency: 'תדר גבוה',
  medium_frequency: 'תדר בינוני',
  secondary: 'משני',
  boundaries: 'גבולות',
  server: 'שרת',
  set_server: 'הגדר שרת',
  set_time_source: 'הגדר מקור זמן',
  set_tpcip_config: 'הגדר קונפיגורציית TPC-IP',
  severity: 'חומרה: ',
  show_av: 'הצג AV',
  site_access_password_security: 'הגדרת גישה לאתר וסיסמה; אבטחה',
  slope: 'שיפוע: {slope}',
  slope_std_r2: 'שיפוע: {slope}, סטיית תקן: {std}, r2: {r2}',
  snow_depth: 'עומק שלג',
  sop_id: 'מזהה SOP: ',
  sops: 'SOPs',
  sort_by: 'מיין לפי',
  source: 'מקור',
  status: 'סטטוס',
  stolen: 'נגנב',
  street_surface: 'משטח רחוב',
  submit: 'שלח',
  subscriptions_and_licenses_restrictions: 'הגבלות על מנויים ורישיונות',
  success: 'הצלחה',
  sudden_water_temp_change: 'שינוי חפוז בטמפרטורת מים',
  supervisor_user: 'משתמש מפקח',
  support_services: 'שירותי תמיכה',
  switch_to_projectName: 'העבר ל-{projectName}',
  switch_to_project: 'העבר ל-{project}',
  switch: 'העבר',
  sync: 'סנכרן',
  system: 'מערכת',
  tabs_container: 'מיכל טאבים',
  task_name: 'שם משימה',
  alert_name: 'שם התראה',
  temp_avg: 'ממוצע טמפרטורה',
  temp_fail: 'כישלון טמפרטורה',
  temp_rise: 'עליית טמפרטורה',
  term_and_termination: 'תנאים וסיום',
  terms: 'תנאים',
  text_alignment: 'יישור טקסט',
  this_is_a_demo_404_page: 'זהו דף דוגמה 404!',
  this_website_uses_cookies_to_enhance_the_user_expe:
    'האתר הזה עושה שימוש בעוגיות לשיפור חווית המשתמש.',
  time: 'זמן',
  time_limited_service: 'שירות מוגבל בזמן',
  time_limited_service_subscription: 'מנוי לשירות מוגבל בזמן',
  time_zone: 'אזור זמן',
  to: 'אל',
  to2: 'אל: ',
  to_businesses_and_professionals: ') לעסקים ומקצוענים.',
  total: 'סך הכל',
  total2: 'סך הכל: ',
  traffic: 'תנועה',
  transient: 'חולף',
  trend: 'מגמה',
  type: 'סוג',
  u: 'U',
  under_over: 'מתחת / מעל',
  underground: 'תת-קרקעי',
  uninstall_device: 'הסר התקן',
  update: 'עדכון',
  update_from_aws_mobile_details: 'עדכון מ-AQS Mobile. ({details})',
  update_modem_version: 'עדכון גרסת מודם',
  update_status_to: 'עדכון סטטוס ל',
  update_version: 'עדכון גרסה',
  update_version_ultra: 'עדכון גרסה אולטרה',
  updated: 'מעודכן',
  upload_files: 'העלה קבצים',
  upload_screenshot: 'העלה צילום מסך',
  us_government_restricted_rights: 'זכויות מוגבלות של ממשלת ארהב',
  user: 'משתמש',
  utc_time: 'זמן UTC',
  v: 'V',
  v_x: 'V: {totalSampledDays}, X: {totalUnSampledDays},',
  v_x2: 'V: {totalSampledDaysWithoutEmpty}, X: {totalUnSampledDaysWithoutEmpty},',
  value: 'ערך',
  valve: 'שסתום',
  valve_monitoring: 'ניטור שסתום',
  velocity: 'מהירות',
  version: 'גרסה',
  wall: 'קיר',
  g3e_fid: 'G3E FID',
  water_loss_unit_h: 'אובדן מים ({unit}/שעה)',
  water_type: 'סוג מים',
  pin_location: 'מיקום סיכה',
  weather_conditions: 'תנאי מזג אוויר',
  week: 'שבוע',
  wood: 'עץ',
  work_area: 'אזור עבודה',
  wover: 'wover',
  wt: 'wt',
  x: 'X',
  x_y: 'X , Y:',
  y: 'Y',
  ver: 'גרסה',
};
// // Get the keys and sort them
// const sortedKeys = Object.keys(en).sort();

// // Create a new object and assign the sorted keys with their respective values
// let sortedObject = {};
// for (let key of sortedKeys) {
//   sortedObject[key] = en[key];
// }

// console.log(sortedObject);
