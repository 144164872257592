import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Select from 'react-select';
import { Table, Column, Cell } from 'fixed-data-table-2';
import SortableHeaderCell from '../../SortableHeaderCell/SortableHeaderCell';
import TextCell from '../../TextCell/TextCell';
import { DateType } from '../../../containers/UIhelper/UIDateFormater';
import CheckBoxCell from '../../CheckBoxCell/CheckBoxCell';
import CSelectCell from '../../../containers/CSelectCell/CSelectCell';

import { useDispatch, useSelector } from 'react-redux';
import { convertIntensityToDb, updateSampleState } from '../../../actions/MobileSamplesActions';

import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import ExcelJS from 'exceljs';
import useGeocoder from '../../../hooks/useGeocoder';

// import mapLimit from 'async/mapLimit';
import { getDateForExcelExports } from '../../../actions/Utilities/dateUtils';
import CAddMobileAlert from '../../../containers/CMobileForms/CAddMobileAlert';
import { setMapDrawMode } from "../../../actions/setters";
import { DRAW_TYPES } from "../../../constants/Misc";
import * as actionsAlerts from "../../../actions/AlertsActions";
import { saveDrawnFeature } from "../../../actions/MapActionsHandler";

require('fixed-data-table-2/dist/fixed-data-table.css');
require('../../AlertsTabs/SamplesTab/SamplesTab.scss');
require('./../mobile.scss');

// Helper function to create a row object for ExcelJS
const getExcelRow = (item, options, accessPointsOptions, mobileAlgParams) => {
  let status = '--';
  let sopClassify = '--';
  let audioClassify = '--';
  let mobileIntensity = '--';
  let mobileQuality = '--';

  if (item.sample_state !== undefined) {
    const stateOption = options.SampleState.find(
      (opt) => opt.value === item.sample_state
    );
    if (stateOption) {
      status = stateOption.label;
    }
  }
  if (item.user_sop_classify !== undefined) {
    const sopClassifyOption = accessPointsOptions.find(
      (opt) => opt.value === item.user_sop_classify
    );
    if (sopClassifyOption) {
      sopClassify = sopClassifyOption.label;
    }
  }
  if (item.user_sample_classify !== undefined) {
    const audioClassifyOption = options.AudioClassificationEnum.find(
      (opt) => opt.value === item.user_sample_classify
    );
    if (audioClassifyOption) {
      audioClassify = audioClassifyOption.label;
    }
  }
  if (item.mobile_intensity) {
    mobileIntensity = convertIntensityToDb(
      item.mobile_intensity,
      mobileAlgParams.noise_ref_delta_db,
      mobileAlgParams.engine_calibration
    );
  }
  if (item.mobile_quality) {
    mobileQuality = Math.max(item.mobile_quality, 0.2) * 100;
  }

  return {
    sampleTime: new Date(item.sample_time).toLocaleString(),
    user: item.user_name || '--',
    deviceId: item.device_id_hex || '',
    sampleState: status,
    intensity: mobileIntensity,
    quality: mobileQuality,
    clarity: item.engine_quality || '',
    address: item.address || '',
    comment: item.user_comment || '',
    pointClassify: sopClassify,
    sampleClassify: audioClassify,
    duration: item.audio_duration,
    frequency: item.audio_frequency / 8,
    longitude: item.location.points[0].y,
    latitude: item.location.points[0].x,
  };
};

// Custom download function (avoids using FileSaver)
const downloadBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

const MobileSamplesTable = (props, context) => {
  const language = useSelector((state) => state.i18nState.lang);
  const { fetchAddress } = useGeocoder(language);
  const {
    displayItems,
    setDisplayItems,
    setCheckedSamples,
    checkedSamples,
    samples,
    options,
    uiInfo: { pageHeight, pageWidth },
  } = props;

  const mobileAlgParams = useSelector((state) => state.mobile.samples.algParams);
  const mobileContext = useSelector((state) => state.mobile.modeState.mode);
  const tasksObj = useSelector((state) => state.mobile.tasks);
  const selectedTask = tasksObj.selectedTask
    .map((taskId) => tasksObj.items[tasksObj.indexMap[taskId]]['task_name'])
    .join('_');

  const selectedAlertId = useSelector((state) => state.mobile.alerts.selectedAlert);
  const dispatch = useDispatch();

  const pWidth = 0.385 * pageWidth;
  const pHeight = 0.375 * pageHeight;

  const [statusFilter, setStatusFilter] = React.useState();
  const [accessPointFilter, setAccessPointFilter] = React.useState();
  const [scrollToIndex, setScrollToIndex] = React.useState(null);
  const [sortField, setSortField] = React.useState('sample_time');
  const [sortDir, setSortDir] = React.useState('desc');

  const [accessPointsOptions, setAccessPointsOptions] = React.useState([]);

  // Set access points options
  React.useEffect(() => {
    if (options.AqsCode) {
      const accessPointOptions = options.AqsCode.filter(
        (opt) => opt.isMobile === 1
      );
      setAccessPointsOptions(accessPointOptions);
    }
  }, [options.AqsCode]);

  // Set scroll to selected sample
  React.useEffect(() => {
    if (props.selectedSample) {
      const selectedRow = displayItems.findIndex(
        (item) => item['sample_uid'] === props.selectedSample
      );

      if (selectedRow !== -1) {
        setScrollToIndex(selectedRow);
      }
    }
  }, [props.selectedSample, displayItems]);

  // Handle filters and sort changes
  React.useEffect(() => {
    const filteredStatus = !statusFilter
      ? samples
      : samples.filter((item) => item['sample_state'] === statusFilter.value);
    const filteredAccessPoint = !accessPointFilter
      ? filteredStatus
      : filteredStatus.filter(
        (item) => item['user_sop_classify'] === accessPointFilter.value
      );
    const filteredAndSorted = filteredAccessPoint.sort((a, b) => {
      let sortResult = 0;
      const fieldA = a[sortField] || '';
      const fieldB = b[sortField] || '';
      if (fieldA > fieldB) {
        sortResult = sortDir === 'asc' ? 1 : -1;
      } else if (fieldA < fieldB) {
        sortResult = sortDir === 'asc' ? -1 : 1;
      }
      return sortResult;
    });

    setDisplayItems([...filteredAndSorted]);
  }, [
    samples,
    statusFilter,
    accessPointFilter,
    sortField,
    sortDir,
    props.selectedSample,
  ]);

  const sortSamples = (field, dir) => {
    setSortField(field);
    setSortDir(dir);
  };

  const samplesSortProps = () => ({
    sort: sortSamples,
    sortBy: sortField,
    sortDir: sortDir,
  });

  const handleRowClick = (_event, rowIndex) => {
    const selectedSampleItem = displayItems[rowIndex];
    props.onSelectMobileSample(selectedSampleItem);
  };

  const handleRowClassNameGetter = (index) => {
    return displayItems[index]?.sample_uid === props.selectedSample
      ? 'active-row'
      : '';
  };
  const isChecked = (value) => checkedSamples.includes(value?.sample_uid);

  const toggleSampleChecked = (value, checked) => {
    if (!checked) {
      setCheckedSamples([...checkedSamples, value.sample_uid]);
    } else {
      setCheckedSamples(checkedSamples.filter((it) => it !== value.sample_uid));
    }
  };

  const myHandleRowClick = (event, rowIndex) => {
    if (event.target.type === undefined || event.target.type !== 'checkbox') {
      handleRowClick(event, rowIndex);
    }
  };
  const filterItemsWithEmptyAddress = (displayItems) => {
    return displayItems.filter(
      (item) => !item.address && item.latitude && item.longitude
    );
  };
  const fillEmptyAddresses = async () => {
    const filteredItems = filterItemsWithEmptyAddress(displayItems);
    if (filteredItems.length === 0) {
      props.notify('No empty addresses to fill', 'info');
      return;
    }
    const handleAddress = async (item, callback) => {
      const address = await fetchAddress(item.latitude, item.longitude);
      if (address?.FormattedAddress) {
        dispatch(
          updateSampleState(
            item.sample_uid,
            {
              address: address.FormattedAddress,
              user_comment: item.user_comment,
            },
            (isSuccess) => {
              _.delay(callback, 100, null, isSuccess);
            }
          )
        );
      }
    };

    const results = await Promise.all(
      filteredItems.map((item) => handleAddress(item))
    );
    const successCount = results.filter((result) => result).length;
    const totalCount = filteredItems.length;
    props.notify(
      `Filled ${successCount} addresses out of ${totalCount}`,
      'info'
    );
  };

  const [showAddMobileAlertsForm, setShowAddMobileAlertsForm] =
    React.useState(false);

  const handleShowAddMobileAlertForm = (isShown) => {
    if (showAddMobileAlertsForm !== isShown) {
      setShowAddMobileAlertsForm(isShown);
      dispatch(setMapDrawMode(true, DRAW_TYPES.POINT));
      dispatch(actionsAlerts.setAddingNewManualAlertMode(true));
    }
  };

  // Function to export XLSX using ExcelJS
  const handleExportExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Mobile Samples');

    // Define worksheet columns
    worksheet.columns = [
      { header: 'Sample Time', key: 'sampleTime', width: 22 },
      { header: 'User', key: 'user', width: 14 },
      { header: 'Device ID', key: 'deviceId', width: 11 },
      { header: 'Sample State', key: 'sampleState', width: 14 },
      { header: 'Intensity', key: 'intensity', width: 11 },
      { header: 'Quality', key: 'quality', width: 9 },
      { header: 'Clarity', key: 'clarity', width: 10 },
      { header: 'Address', key: 'address', width: 16 },
      { header: 'Comment', key: 'comment', width: 16 },
      { header: 'Point Classify', key: 'pointClassify', width: 16 },
      { header: 'Sample Classify', key: 'sampleClassify', width: 16 },
      { header: 'Duration', key: 'duration', width: 10 },
      { header: 'Frequency', key: 'frequency', width: 10 },
      { header: 'Longitude', key: 'longitude', width: 16 },
      { header: 'Latitude', key: 'latitude', width: 16 },
    ];

    // Add each sample as a row to the worksheet
    samples.forEach((item) => {
      const row = getExcelRow(item, options, accessPointsOptions, mobileAlgParams);
      worksheet.addRow(row);
    });

    // Generate buffer and trigger file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const filename = `${getDateForExcelExports()}_${selectedTask}.xlsx`;
    downloadBlob(blob, filename);
  };

  return (
    <div className='samples-table'>
      <CAddMobileAlert
        isOpen={showAddMobileAlertsForm}
        showAddMobileAlertsForm={handleShowAddMobileAlertForm}
      />
      <Table
        rowsCount={displayItems.length}
        width={pWidth}
        height={pHeight}
        headerHeight={30}
        onRowClick={myHandleRowClick}
        rowClassNameGetter={handleRowClassNameGetter}
        scrollToRow={scrollToIndex}
        rowHeight={30}
      >
        <Column
          columnKey='Selected'
          header={<Cell>{}</Cell>}
          cell={
            <CheckBoxCell
              data={displayItems}
              onChange={toggleSampleChecked}
              isChecked={isChecked}
            />
          }
          width={30}
          flexGrow={1}
        />
        <Column
          columnKey='sample_time'
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={
            <TextCell
              data={displayItems}
              field='sample_time'
              additional={props.timeZone}
              dateType={DateType.DATE_AND_TIME}
            />
          }
          width={150}
          flexGrow={5}
        />
        <Column
          columnKey='device_id'
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={<TextCell data={displayItems} field='device_id_hex' />}
          width={100}
          flexGrow={1}
        />
        <Column
          columnKey='sample_state'
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={
            <CSelectCell
              data={displayItems}
              options={options.SampleState}
              field='sample_state'
              notify={props.notify}
              sortField={sortField}
              sortDir={sortDir}
            />
          }
        />
        <Column
          columnKey='mobile_intensity'
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={
            <TextCell
              data={displayItems}
              field='mobile_intensity'
              additional={mobileAlgParams}
            />
          }
          width={100}
          flexGrow={1}
        />
        <Column
          columnKey='mobile_quality'
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={<TextCell data={displayItems} field='mobile_quality' />}
          width={100}
          flexGrow={1}
        />
        <Column
          columnKey='user_comment'
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={<TextCell data={displayItems} field='user_comment' />}
          width={100}
          flexGrow={1}
        />
        <Column
          columnKey='user_sop_classify'
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={
            <CSelectCell
              data={displayItems}
              options={accessPointsOptions}
              field='user_sop_classify'
              notify={props.notify}
              sortField={sortField}
              sortDir={sortDir}
            />
          }
        />
        <Column
          columnKey='user_sample_classify'
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={
            <CSelectCell
              data={displayItems}
              options={options.AudioClassificationEnum}
              field='user_sample_classify'
              notify={props.notify}
              sortField={sortField}
              sortDir={sortDir}
            />
          }
        />
        <Column
          columnKey='user_name'
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={<TextCell data={displayItems} field='user_name' />}
          width={100}
          flexGrow={1}
        />
      </Table>

      <div className='mobile-samples-bottom'>
        {checkedSamples.length > 0 && mobileContext === 'Tasks' && !showAddMobileAlertsForm && (
          <button
            type='button'
            className='btn btn-success btn-assessment create-item'
            onClick={() => {
              handleShowAddMobileAlertForm(true);
            }}
          >
            <i title={context.t('create_alert')} />
          </button>
        )}

        <Button
          variant='contained'
          color='primary'
          size='small'
          startIcon={<SaveIcon />}
          onClick={handleExportExcel}
        >
          {context.t('save_as_excel')}
        </Button>

        <div style={{ display: 'flex', alignItems: 'center', columnGap: '2px' }}>
          <label>{context.t('status')}</label>
          <Select
            isClearable
            placeholder={context.t('status')}
            options={options.SampleState}
            onChange={(newValue) => setStatusFilter(newValue)}
            menuPosition='fixed'
            menuPlacement='auto'
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: '2px' }}>
          <label>{context.t('access_point')}:</label>
          <Select
            isClearable
            placeholder={context.t('access_point')}
            options={accessPointsOptions}
            onChange={(newValue) => setAccessPointFilter(newValue)}
            menuPosition='fixed'
            menuPlacement='auto'
          />
          <Button
            type='button'
            variant='contained'
            color='primary'
            onClick={fillEmptyAddresses}
          >
            {context.t('fill_addresses')}
          </Button>
        </div>
      </div>
    </div>
  );
};

MobileSamplesTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MobileSamplesTable;
