import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import { Field, reduxForm } from 'redux-form';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import CanvasDraw from "react-canvas-draw";
import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater';
import useProjectTimezone from '../../hooks/useProjectTimeZone';

import './CoupleAssessmentReportModal.scss';

function CoupleAssessmentReportModal(props, context) {
  // Destructure t from the legacy context
  const { t } = context;
  const { isAqsAdmin, timeZone, coupleId, assessments, getMapCapture } = props;
  const projectTimezone = useProjectTimezone();
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [imgPng, setImgPng] = useState(null);

  const processingInterval = useRef(null);
  const ellipsisRef = useRef(null);

  useEffect(() => {
    return () => {
      if (processingInterval.current) {
        clearInterval(processingInterval.current);
      }
    };
  }, []);

  const openModal = () => {
    setOpen(true);
    getMapCapture().then((url) => {
      const image = new Image();
      image.crossOrigin = 'Anonymous';
      image.src = url;
      setImgPng(url);
    });
  };

  const closeModal = () => {
    setOpen(false);
  };

  const animateEllipsis = () => {
    setTimeout(() => {
      const ellipsis = ellipsisRef.current;
      processingInterval.current = setInterval(() => {
        if (ellipsis) {
          if (ellipsis.innerHTML.length > 3) {
            ellipsis.innerHTML = "";
          } else {
            ellipsis.innerHTML += ".";
          }
        }
      }, 500);
    });
  };

  const coupleAssessmentReportPDF = (output) => {
    setProcessing(true);
    animateEllipsis();

    html2canvas(document.getElementById('couple-assessment-report-body'), {
      useCORS: true,
      allowTaint: false,
      logging: true
    })
      .then((canvas) => {
        let pdf = new jsPDF({ orientation: 'landscape' });
        pdf.addImage(canvas, 'JPEG', 8, 30);

        switch (output) {
          case 'export':
            pdf.save('couple-assessment-report.pdf');
            break;
          case 'print':
            pdf.autoPrint();
            pdf.output('dataurlnewwindow');
            break;
          case 'save':
            // ToDo: implement saving logic
            break;
          default:
            pdf.output('dataurlnewwindow');
            setOpen(false);
            break;
        }
        setProcessing(false);
        if (processingInterval.current) {
          clearInterval(processingInterval.current);
          processingInterval.current = null;
        }
      })
      .catch(e => {
        setProcessing(false);
        if (processingInterval.current) {
          clearInterval(processingInterval.current);
          processingInterval.current = null;
        }
      });
  };

  const saveAndClose = () => {
    coupleAssessmentReportPDF('save');
  };

  const printCoupleAssessmentReport = () => {
    coupleAssessmentReportPDF('print');
  };

  const exportToPDF = () => {
    coupleAssessmentReportPDF('export');
  };

  const handleSubmit = (event) => {
    debugger;
  };

  if (!isAqsAdmin) {
    return null;
  }

  return (
    <div className="table-actions assessments" style={{ float: 'right' }}>
      <button
        className="btn btn-link couple-assessment-report"
        onClick={openModal}
        type="button"
      >
        {t('report')}
      </button>

      {open && (
        <AppModal
          closeIcon
          open={open}
          onClose={closeModal}
          title={t('couple_assessment_report')}
          content={
            <div id="couple-assessment-report-body">
              <h2>{t('couple_id2', { coupleId })}</h2>
              <form className="form-horizontal form-couple-assessment-report" onSubmit={handleSubmit}>
                <div className="helfWidth">
                  <img
                    src={imgPng}
                    className="coupleAssessmentReportImage"
                    id="coupleAssessmentReportMap"
                    alt="Couple Assessment Report Map"
                  />
                  {false && (
                    <div className="coupleAssessmentReportCanvas">
                      <CanvasDraw style={{ width: '400px', height: '300px' }} />
                    </div>
                  )}
                  <div className="inline-group" style={{ display: 'inline-block', width: '97%' }}>
                    <label className="col-xs-2 control-label">{t('guidelines')}:</label>
                    <div className="col-xs-8 control-input">
                      <Field name="guidelines" component="textarea" className="form-control" />
                    </div>
                  </div>
                </div>

                <div className="rightSideGuidlinesInCoupleAssessmentReport">
                  <table>
                    <thead>
                    <tr>
                      <th>{t('id')}</th>
                      <th>{t('date')}</th>
                      <th>{t('intensity')}</th>
                      <th>{t('priority')}</th>
                      <th>{t('address')}</th>
                      <th>{t('status')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {assessments.map((sample) => (
                      <tr key={sample.DetectedAt}>
                        <td>{sample.id}</td>
                        <td>{UIDateFormater(sample.DetectedAt, DateType.DATE, timeZone, projectTimezone)}</td>
                        <td>{sample.Intensity}</td>
                        <td>{sample.Priority}</td>
                        <td>{sample.StreetAddress}</td>
                        <td>{sample.AlertStateMeaning}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </form>
              <span ref={ellipsisRef} style={{ display: 'none' }} />
            </div>
          }
          actions={
            <button
              className="btn btn-info"
              onClick={exportToPDF}
              title={t('export_the_view_to_PDF')}
            >
              {t('export')}
            </button>
          }
        />
      )}
    </div>
  );
}

CoupleAssessmentReportModal.propTypes = {
  isAqsAdmin: PropTypes.bool,
  timeZone: PropTypes.string,
  coupleId: PropTypes.any,
  assessments: PropTypes.array,
  getMapCapture: PropTypes.func,
};

CoupleAssessmentReportModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const coupleAssessmentReportModalForm = reduxForm({
  form: 'CoupleAssessmentReportModal',
  enableReinitialize: true,
})(CoupleAssessmentReportModal);

export default coupleAssessmentReportModalForm;
