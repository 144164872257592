/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Plot from 'react-plotly.js';
import ReactSelect from 'react-select';
import { PuffLoader } from 'react-spinners';

import groupBy from 'json-groupby';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import AppModal from './AppModal';

import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';

import 'jspdf-autotable';

import ExcelJS from 'exceljs';

const graphMargin = 200;

function getMonthDays(year, month) {
  const now = new Date();
  const date = new Date(year, month, 0);
  const daysOnMonth =
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth()
      ? now.getDate()
      : date.getDate();
  const days = [];
  for (let i = 0; i < daysOnMonth; i++) {
    const day = new Date(year, month - 1, i + 1, 0, 0, 0, 0);
    days.push(day);
  }
  return days;
}

const plotRenderContainerId = 'plot-render-container';

const reportTypes = [
  { value: 'emi', label: 'Emi' },
  { value: 'whiteNoise', label: 'White Noise' },
  { value: 'saturation', label: 'Saturation' },
  { value: 'delayedWakeup', label: 'Delayed Wake Up' },
  // { value: 'sin', label: 'Sin' },
  { value: 'lowNoise', label: 'Low Noise' },
  { value: 'sensorFmIssues', label: 'Sensor FM Issues' },
];

export default function SensorsTechnicianModal(props, context) {
  const classes = useStyles();
  const [selectedMonth, setSelectedMonth] = React.useState(
    new Date().toISOString().substring(0, 'YYYY-MM'.length)
  );
  const [selectedReport, setSelectedReport] = React.useState(reportTypes[0].value);
  const [selectedReportLabel, setSelectedReportLabel] = React.useState('Select Report Type'); // Default placeholder value
  const [xlsData, setXlsData] = React.useState({});
  const [tableReport, setTableReport] = React.useState(null);
  const [daysActivityReport, setDaysActivityReport] = React.useState([]);
  // const [selectedBoundary, setSelectedBoundary] = React.useState([]);
  // const [isIncludeArchived, setIsIncludeArchived] = React.useState(false);

  // Memoize xlsColumns so they only change when selectedReport changes
  const xlsColumns = React.useMemo(() => [
    {
      title: 'Installation Date',
      width: { wch: 20 },
      style: { alignment: { horizontal: 'center', vertical: 'center' } },
    },
    {
      title: 'Device ID',
      width: { wch: 20 },
      style: { alignment: { horizontal: 'center', vertical: 'center' } },
    },
    {
      title: selectedReport === 'sensorFmIssues' ? 'Couple ID' : 'Sensor ID',
      width: { wch: 20 },
      style: { alignment: { horizontal: 'center', vertical: 'center' } },
    },
    {
      title: 'Days Worked',
      width: { wpx: 100 },
      style: { alignment: { horizontal: 'center', vertical: 'center' } },
    },
    {
      title: 'Days not Worked',
      width: { wpx: 100 },
      style: { alignment: { horizontal: 'center', vertical: 'center' } },
    },
    {
      title: 'Average value',
      width: { wpx: 100 },
      style: { alignment: { horizontal: 'center', vertical: 'center' } },
    },
    {
      title: 'Potential Active Days',
      width: { wch: 23 },
      style: { alignment: { horizontal: 'center', vertical: 'center' } },
    },
    {
      title: '%',
      width: { wpx: 70 },
      style: { alignment: { horizontal: 'center', vertical: 'center' } },
    },
  ], [selectedReport]);

  useEffect(() => {
    // Set the export data and report type based on props.data:
    if (props.data) {
      setDaysActivityReport(props.data);
      setTableReport(selectedReport);

      const data = [
        {
          columns: xlsColumns,
          data: props.data.map((item) => {
            const totalActiveDays = item.activity.filter((d) => d === 1).length;
            const unWorkedDaysInMonth = item.activity.filter((d) => d !== 1).length;
            const activePercentage =
              totalActiveDays + unWorkedDaysInMonth === 0
                ? ''
                : ((totalActiveDays / (totalActiveDays + unWorkedDaysInMonth)) * 100).toFixed();
            return [
              {
                value: new Date(item.DeviceInstallationDate).toLocaleDateString(),
                style: { font: { sz: '12' } },
              },
              {
                value: Number(item.DeviceID),
                style: { font: { sz: '12' }, numFmt: '0' },
              },
              {
                value: Number(item.SensorID),
                style: { font: { sz: '12' }, numFmt: '0' },
              },
              { value: Number(totalActiveDays), style: { font: { sz: '12' } } },
              {
                value: Number(unWorkedDaysInMonth),
                style: { font: { sz: '12' } },
              },
              {
                value: Number(activePercentage),
                style: { font: { sz: '12' }, numFmt: '0%' },
              },
            ];
          }),
        },
      ];
      setXlsData(data);
    }
  }, [props.data, selectedReport, xlsColumns]);

  const { month, year } = props;
  let pieData = [];
  let activityData = [];
  let daysForPercent = [];

  if (props.data) {
    activityData = props.data.map((sensor) => ({
      DaysCount: sensor.activity.filter((d) => d === 1).length,
    }));
    daysForPercent = props.data.filter((sensor) => sensor.SensorStatus !== 'Not Installed');
  }

  if (activityData.length > 0) {
    const attrGroup = groupBy(activityData, ['DaysCount']);
    const keys = Object.keys(attrGroup).map(Number);
    const texts = keys.map((numVal) => (numVal > 5 ? `${numVal}` : ''));
    const values = Object.values(attrGroup).map((arr) => arr.length);
    pieData.push({
      type: 'pie',
      labels: keys,
      values: values,
      text: texts,
      texttemplate: '%{label} days, %{percent}',
      hovertemplate: '%{label} days - %{value} sensors',
      textposition: 'intside',
      textinfo: 'text',
    });
  }

  const pieLayout = {
    showlegend: false,
    height: 500,
    width: 600,
    margin: {
      t: graphMargin - graphMargin * 0.8,
      b: graphMargin,
      l: graphMargin,
      r: graphMargin,
    },
  };

  const selectReport = (selectedOption) => {
    if (selectedOption) {
      setSelectedReport(selectedOption.value);
      setSelectedReportLabel(selectedOption.label);
    }
  };

  function selectMonth(e) {
    setSelectedMonth(e.target.value);
  }

  function loadData() {
    const [sYear, sMonth] = selectedMonth.split('-').map(Number);
    props.loadData(sYear, sMonth, selectedReport);
  }

  // -----------------------------------------------------------------------
  // ExcelJS export function replacing react-data-export
  const exportExcel = async () => {
    if (!xlsData || !Array.isArray(xlsData) || xlsData.length === 0) {
      alert(context.t('no_data_available_for_export'));
      return;
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sensors Activity');

    const sheetData = xlsData[0];
    const worksheetColumns = sheetData.columns.map((col) => ({
      header: col.title,
      key: col.title,
      width: col.width.wch || col.width.wpx || col.title.length + 2,
    }));
    worksheet.columns = worksheetColumns;

    sheetData.data.forEach((row) => {
      const rowValues = row.map((cell) => cell.value);
      worksheet.addRow(rowValues);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const filename = `Sensors Activity - ${props.selectedProject?.Name} ${month}-${year}.xlsx`;
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const loadingView = (
    <div style={{ margin: 'auto', width: 'fit-content' }}>
      <PuffLoader size={100} loading />
    </div>
  );

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: 200,
    }),
  };

  return (
    <AppModal
      closeIcon
      fullWidth
      maxWidth="xl"
      open={props.isVisible}
      title={context.t('sensors_technician_report')}
      onClose={props.close}
      content={
        <div style={{ height: '80vh' }}>
          {props.isFetching && loadingView}
          {!props.isFetching && props.selectedProject && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  padding: '5px',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-around',
                  width: '45%',
                  marginBottom: '2em',
                }}
              >
                <div style={{ float: 'left', marginRight: '10px' }}>
                  <Typography component="p" variant="p">
                    {context.t('select_month')}
                  </Typography>
                  <input
                    type="month"
                    min="2016-01"
                    onChange={selectMonth}
                    value={selectedMonth}
                    style={{ padding: '8px' }}
                  />
                </div>
                <div style={{ float: 'left', marginRight: '10px' }}>
                  <Typography component="p" variant="p">
                    {context.t('report_type')}
                  </Typography>
                  <ReactSelect
                    options={reportTypes}
                    onChange={selectReport}
                    placeholder={selectedReportLabel}
                    styles={customStyles}
                  />
                </div>
                <Button style={{ margin: 'auto' }} variant="contained" color="primary" onClick={loadData}>
                  {context.t('generate_report')}
                </Button>
              </div>

              {daysActivityReport.length > 0 && (
                <div style={{ display: 'inline-flex' }}>
                  <div id={plotRenderContainerId} style={{ display: 'none' }}>
                    HIDDEN DIV FOR GENERATE PLOTLY IMAGE
                  </div>
                  <Plot data={pieData} layout={pieLayout} />
                  <SensorsTechnicianReportTable
                    data={daysActivityReport}
                    activityData={daysActivityReport}
                    date={new Date(year, month, 0)}
                    year={year}
                    month={month}
                    tableReport={tableReport}
                  />
                </div>
              )}

              {daysActivityReport.length === 0 && (
                <div className={classes.errorBox}>
                  <div className={classes.error}>{context.t('there_is_no_data_available')}</div>
                </div>
              )}

              <div>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  onClick={exportExcel}
                >
                  {context.t('save_as_excel')}
                </Button>
              </div>
            </div>
          )}
        </div>
      }
    />
  );
}

SensorsTechnicianModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

SensorsTechnicianModal.defaultProps = {
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
};

/**
 *
 * @param {*} props
 * @returns {React.FC}
 */
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '600px',
  },
  cell: {
    borderLeft: '1px solid #eee',
    '&.$nth-of-type(odd)': {
      background: '#aaa',
    },
  },
  select: {
    width: '130px',
  },
  errorBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  error: {
    color: '#d32f2f',
  },
});

function SensorsTechnicianReportTable(props, context) {
  const classes = useStyles();
  const { year, month, tableReport } = props;
  const days = getMonthDays(year, month);

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>#</TableCell>
            <TableCell className={classes.cell}>
              {tableReport === 'sensorFmIssues' ? context.t('couple_id') : context.t('sensor_id')}
            </TableCell>
            {tableReport !== 'sensorFmIssues' && (
              <TableCell className={classes.cell}>Current Device ID</TableCell>
            )}
            {tableReport !== 'sensorFmIssues' && (
              <TableCell className={classes.cell}>Current Status</TableCell>
            )}
            <TableCell className={classes.cell}>Total</TableCell>
            <TableCell className={classes.cell}>AVG</TableCell>
            {days.map((day) => (
              <TableCell className={classes.cell} padding="none" key={day.getDate()}>
                {day.getDate()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((sensorData, index) => {
            const activeDays = sensorData.activity;
            const totalActive = activeDays.filter((x) => x === 1).length;
            const sumValue = sensorData.sumValue; // Ensure this field exists in your data
            const avgValue = totalActive ? (sumValue / totalActive).toFixed(2) : 0;
            return (
              <TableRow hover key={index}>
                <TableCell className={classes.cell}>{index + 1}</TableCell>
                {tableReport === 'sensorFmIssues' ? (
                  <TableCell className={classes.cell}>{sensorData.CoupleID}</TableCell>
                ) : (
                  <TableCell className={classes.cell}>{sensorData.SensorID}</TableCell>
                )}
                {tableReport !== 'sensorFmIssues' && (
                  <TableCell className={classes.cell}>{sensorData.DeviceID}</TableCell>
                )}
                {tableReport !== 'sensorFmIssues' && (
                  <TableCell className={classes.cell}>{sensorData.SensorStatus}</TableCell>
                )}
                <TableCell className={classes.cell}>
                  {sensorData.activity.filter((x) => x === 1).length}
                </TableCell>
                <TableCell className={classes.cell}>{avgValue}</TableCell>
                {days.map((day, index) => {
                  let char;
                  let style;
                  switch (activeDays[index]) {
                    case 1:
                      char = <span>&#x2714;</span>;
                      style = { color: 'green' };
                      break;
                    case 0:
                      char = <span>&#x2718;</span>;
                      style = { color: 'red' };
                      break;
                    case null:
                      char = <span>-</span>;
                      style = { color: 'gray' };
                      break;
                    default:
                      char = <span>-</span>;
                      style = { color: 'gray' };
                      break;
                  }
                  return (
                    <TableCell className={classes.cell} style={style} padding="none" key={day.getTime()}>
                      {char}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

SensorsTechnicianReportTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

SensorsTechnicianReportTable.propTypes = {
  activityData: PropTypes.array,
  selectedReport: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  date: PropTypes.object,
  month: PropTypes.number,
  year: PropTypes.number,
};
