import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import Select from './../Select/Select';
import Input from './../Input/Input';

require('./style.scss');

export default class CoupleFields extends React.Component {

  static get propTypes() {
    return {
      buttonTitle: PropTypes.string,
      initialValues: PropTypes.object,
      isGenerateMode: PropTypes.bool,
      editMode: PropTypes.bool,
      resetSensors: PropTypes.func,
      materials: PropTypes.array,
      user: PropTypes.object,
      distanceFactor: PropTypes.number
    };
  }

  render() {
    const {distanceFactor} = this.props;
    return (
      <div className="couple-fields">
            <div className="path-details">
                <div>
                    {/* #INPUT VALIDATED WITH NUMBERS ONLY */}
                    {/* Attenuation - NUMBER FLOAT */}
                    {/*<div className="inline-group">*/}
                    {/*    <label className="col-xs-6 control-label">{this.context.t('attenuation')}:</label>*/}
                    {/*    <div className="col-xs-6 control-input">*/}
                    {/*        <Field*/}
                    {/*            className="form-control"*/}
                    {/*            name="Attenuation"*/}
                    {/*            component={Input}*/}
                    {/*            type="number"*/}
                    {/*            disabled={false}*/}
                    {/*            distanceFactor={distanceFactor} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/* NoiseLevel - NUMBER INT */}
                    {/*<div className="inline-group">*/}
                    {/*    <label className="col-xs-6 control-label">{this.context.t('noise_level')}:</label>*/}
                    {/*    <div className="col-xs-6 control-input">*/}
                    {/*        <Field*/}
                    {/*            className="form-control"*/}
                    {/*            name="NoiseLevel"*/}
                    {/*            component={Input}*/}
                    {/*            type="number"*/}
                    {/*            disabled={false}*/}
                    {/*            distanceFactor={distanceFactor} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/* Fmin - NUMBER INT  */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('fmin')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field
                                className="form-control"
                                name="Fmin"
                                component={Input}
                                type="number"
                                disabled={false}
                                distanceFactor={distanceFactor} />
                        </div>
                    </div>

                    {/* Fmax -NUMBER INT   */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('fmax')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field
                                className="form-control"
                                name="Fmax"
                                component={Input}
                                type="number"
                                disabled={false}
                                distanceFactor={distanceFactor} />
                        </div>
                    </div>

                    {/*/!* SensitivityLevel - NUMBER INT  *!/*/}
                    {/*<div className="inline-group">*/}
                    {/*    <label className="col-xs-6 control-label">{this.context.t('sensitivity_level')}:</label>*/}
                    {/*    <div className="col-xs-6 control-input">*/}
                    {/*        <Field*/}
                    {/*            className="form-control"*/}
                    {/*            name="SensitivityLevel"*/}
                    {/*            component={Input}*/}
                    {/*            type="number"*/}
                    {/*            disabled={false}*/}
                    {/*            distanceFactor={distanceFactor} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*/!* AllowFalsePositive - NUMBER INT  *!/*/}
                    {/*<div className="inline-group">*/}
                    {/*    <label className="col-xs-6 control-label">{this.context.t('allow_false_positive')}:</label>*/}
                    {/*    <div className="col-xs-6 control-input">*/}
                    {/*        <Field*/}
                    {/*            className="form-control"*/}
                    {/*            name="AllowFalsePositive"*/}
                    {/*            component={Input}*/}
                    {/*            type="number"*/}
                    {/*            disabled={false}*/}
                    {/*            distanceFactor={distanceFactor} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*/!* Prob4Burst - NUMBER INT  *!/*/}
                    {/*<div className="inline-group">*/}
                    {/*    <label className="col-xs-6 control-label">{this.context.t('prob4_burst')}:</label>*/}
                    {/*    <div className="col-xs-6 control-input">*/}
                    {/*        <Field*/}
                    {/*            className="form-control"*/}
                    {/*            name="Prob4Burst"*/}
                    {/*            component={Input}*/}
                    {/*            type="number"*/}
                    {/*            disabled={false}*/}
                    {/*            distanceFactor={distanceFactor} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*/!* Prob4Irrigation - NUMBER INT  *!/*/}
                    {/*<div className="inline-group">*/}
                    {/*    <label className="col-xs-6 control-label">{this.context.t('prob4_consumption')}:</label>*/}
                    {/*    <div className="col-xs-6 control-input">*/}
                    {/*        <Field*/}
                    {/*            className="form-control"*/}
                    {/*            name="Prob4Irrigation"*/}
                    {/*            component={Input}*/}
                    {/*            type="number"*/}
                    {/*            disabled={false}*/}
                    {/*            distanceFactor={distanceFactor} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*/!* PipeAccuracy - NUMBER INT  *!/*/}
                    {/*<div className="inline-group">*/}
                    {/*    <label className="col-xs-6 control-label">{this.context.t('pipe_accuracy')}:</label>*/}
                    {/*    <div className="col-xs-6 control-input">*/}
                    {/*        <Field*/}
                    {/*            className="form-control"*/}
                    {/*            name="PipeAccuracy"*/}
                    {/*            component={Input}*/}
                    {/*            type="number"*/}
                    {/*            disabled={false}*/}
                    {/*            distanceFactor={distanceFactor} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*/!* MultiDiameter - NUMBER INT  *!/*/}
                    {/*<div className="inline-group">*/}
                    {/*    <label className="col-xs-6 control-label">{this.context.t('multi_diameter')}:</label>*/}
                    {/*    <div className="col-xs-6 control-input">*/}
                    {/*        <Field*/}
                    {/*            className="form-control"*/}
                    {/*            name="MultiDiameter"*/}
                    {/*            component={Input}*/}
                    {/*            type="number"*/}
                    {/*            disabled={false}*/}
                    {/*            distanceFactor={distanceFactor} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*/!* MultiMaterial - NUMBER INT  *!/*/}
                    {/*<div className="inline-group">*/}
                    {/*    <label className="col-xs-6 control-label">{this.context.t('multi_material')}:</label>*/}
                    {/*    <div className="col-xs-6 control-input">*/}
                    {/*        <Field*/}
                    {/*            className="form-control"*/}
                    {/*            name="MultiMaterial"*/}
                    {/*            component={Input}*/}
                    {/*            type="number"*/}
                    {/*            disabled={false}*/}
                    {/*            distanceFactor={distanceFactor} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/* #SELECT WITH 0\1 ONLY (TRUE\FALSE) */}
                    {/* PossibleUFR - BOOLEAN 0\1 */}
                    {/*<div className="inline-group">*/}
                    {/*    <label className="col-xs-6 control-label">{this.context.t('possible_ufr')}:</label>*/}
                    {/*    <div className="col-xs-6 control-input">*/}
                    {/*        <Field name="PossibleUFR"*/}
                    {/*            disabled={false}*/}
                    {/*            component={props =>*/}
                    {/*                <Select {...props}*/}
                    {/*                    key={"PossibleUFR"}*/}
                    {/*                    placeholderTitle={this.context.t('select')}*/}
                    {/*                    clearable={false}*/}
                    {/*                    options={[*/}
                    {/*                        { value: false, label: this.context.t('false') },*/}
                    {/*                        { value: true, label: this.context.t('true') }]}*/}
                    {/*                />*/}
                    {/*            }*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*/!* PossiblePumps - BOOLEAN 0\1 *!/*/}
                    {/*<div className="inline-group">*/}
                    {/*    <label className="col-xs-6 control-label">{this.context.t('possible_pumps')}:</label>*/}
                    {/*    <div className="col-xs-6 control-input">*/}
                    {/*        <Field name="PossiblePumps"*/}
                    {/*            disabled={false}*/}
                    {/*            component={props =>*/}
                    {/*                <Select {...props}*/}
                    {/*                    key={"PossiblePumps"}*/}
                    {/*                    placeholderTitle={this.context.t('select')}*/}
                    {/*                    clearable={false}*/}
                    {/*                    options={[*/}
                    {/*                        { value: false, label: this.context.t('false') },*/}
                    {/*                        { value: true, label: this.context.t('true') }]}*/}
                    {/*                />*/}
                    {/*            }*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
  }
}

CoupleFields.contextTypes = {
  t: PropTypes.func.isRequired
};
